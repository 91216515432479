// src/components/ProductList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiFillDelete, AiOutlineUndo } from "react-icons/ai";
import { API_BASE_URL } from "../config";

const ProductList = ({ products, fetchProducts, product, setProduct }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleDelete = async (id) => {
    setLoading(true);
    setError("");
    setSuccess("");
    try {
      if (window.confirm("Are you sure to delete this Reading?")) {
        //console.log('id',id);
        const staticData = {
          token: localStorage.getItem("authToken"),
          id: id,
        };
        const response = await axios.post(
          `${API_BASE_URL}/api/readingremove`,
          staticData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setSuccess("Item remove successfully!");
        //fetchProducts();
        window.location.reload();
      }
    } catch (err) {
      //console.log(err);
    }
  };

  // --------------------reuse code-------------------

  const handleReuse = (entry, product, setProduct) => {
    setProduct({
      ...product, // Keep the existing product data
      MS_opening: entry.MS_closing,
      //MS_closing: entry.MS_opening,
      //MS_testing: entry.MS_testing,
      //MS_subtotal: entry.MS_subtotal,
      // MS_price: entry.MS_price,
      // MS_total: entry.MS_total,
      HSD_opening: entry.HSD_closing,
      //HSD_closing: entry.HSD_opening,
      // HSD_testing: entry.HSD_testing,
      //HSD_subtotal: entry.HSD_subtotal,
      //HSD_price: entry.HSD_price,
      // HSD_total: entry.HSD_total,
      //credit1: entry.credit1,
      //card: entry.card,
      //scan: entry.scan,
      //cash: entry.cash,
      //grandTotal: entry.grandTotal,
    });
  };

  return (
    <div className="show-detils">
      {products.length > 0 ? (
        <table className="table table-dark table-striped">
          <thead>
            <tr>
              <th>Actions</th>
              <th>Time</th>
              <th>MS_opening</th>
              <th>MS_closing</th>
              <th>MS_testing</th>
              <th>MS_subtotal</th>
              <th>MS_price</th>
              <th>MS_total</th>
              <th>HSD_opening</th>
              <th>HSD_closing</th>
              <th>HSD_testing</th>
              <th>HSD_subtotal</th>
              <th>HSD_price</th>
              <th>HSD_total</th>
              <th>credit1</th>
              <th>card</th>
              <th>scan</th>
              <th>cash</th>
              <th>grandTotal</th>
            </tr>
          </thead>
          <tbody>
            {products.map((entry, i) => (
              <tr key={i}>
                <td>
                  <button
                    onClick={() => handleReuse(entry, product, setProduct)}
                    className="btn btn-secondary"
                  >
                    <AiOutlineUndo />
                  </button>

                  <button
                    onClick={() => handleDelete(entry.id)}
                    className="btn btn-danger ms-2"
                  >
                    {" "}
                    <AiFillDelete />
                  </button>
                </td>
                <td>{entry.created_at}</td>
                <td>{entry.MS_opening}</td>
                <td>{entry.MS_closing}</td>
                <td>{entry.MS_testing}</td>
                <td>{entry.MS_subtotal}</td>
                <td>{entry.MS_price}</td>
                <td>{entry.MS_total}</td>
                <td>{entry.HSD_opening}</td>
                <td>{entry.HSD_closing}</td>
                <td>{entry.HSD_testing}</td>
                <td>{entry.HSD_subtotal}</td>
                <td>{entry.HSD_price}</td>
                <td>{entry.HSD_total}</td>
                <td>{entry.credit1}</td>
                <td>{entry.card}</td>
                <td>{entry.scan}</td>
                <td>{entry.cash}</td>
                <td>{entry.grandTotal}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductList;
