import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";
import TankerunloadingList from "./TankerunloadingList";
import AddTankerUnloading from "./AddTankerUnloading";
import { API_BASE_URL } from "../config";

import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import Header from "./Header";

export default function TankerUnloading() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { logout } = useAuth();
  const [tankerunloadings, setTankerunloadings] = useState([]);
  const [tankerunloading, setTankerunloading] = useState({
    token: localStorage.getItem("authToken"),
  });

  const [savedData, setSavedData] = useState([]); // Array to store the saved data
  const [filterDate, setFilterDate] = useState(""); // Filter by date
  const [filterInvoiceMS, setFilterInvoiceMS] = useState(""); // Filter by Invoice MS
  const [filterInvoiceHSD, setFilterInvoiceHSD] = useState(""); // Filter by Invoice HSD

  const navigate = useNavigate(); // Call useNavigate to get navigate function
  const [credentials] = useState({
    token: localStorage.getItem("authToken"),
  });

  // Load saved data from local storage on component mount
  useEffect(() => {
    //const storedData = JSON.parse(localStorage.getItem("tankerData")) || [];
    const authState = localStorage.getItem("authToken");
    if (authState) {
      fetchTankerunloading();
      setIsLoggedIn(true);
    }
  }, []);

  const fetchTankerunloading = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/gettankerunloading`,
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        console.log(response.data.data);
        setTankerunloadings(response.data.data);
      } else {
      }
    } catch (err) {
      logout();
      //setError(response.data.message);
      navigate("/"); // Use navigate to redirect
    }
  };
 

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "filterDate") {
      setFilterDate(value);
    } else if (name === "filterInvoiceMS") {
      setFilterInvoiceMS(value);
    } else if (name === "filterInvoiceHSD") {
      setFilterInvoiceHSD(value);
    }
  };

  // Calculate total invoiceMS and invoiceHSD
  const totalInvoiceMS = savedData.reduce(
    (acc, row) => acc + parseFloat(row.invoiceMS || 0),
    0
  );
  const totalInvoiceHSD = savedData.reduce(
    (acc, row) => acc + parseFloat(row.invoiceHSD || 0),
    0
  );

  return (
    <>
      <Header />
      <div className="tanker-unloading">
        <div className="container">
          <AddTankerUnloading
             fetchTankerunloading={fetchTankerunloading}
             tankerunloading={tankerunloading}
             setTankerunloading={setTankerunloading}
             isLoggedIn={isLoggedIn}
          />
          <div className="form">
            <div className="filters">
              <h3>All Data</h3>
              <div className="row">
                <div className="col-2">
                  <input
                    type="date"
                    name="filterDate"
                    value={filterDate}
                    onChange={handleFilterChange}
                    placeholder="Filter by date"
                  />
                </div>
                <div className="col-2">
                  <input
                    type="text"
                    name="filterInvoiceMS"
                    value={filterInvoiceMS}
                    onChange={handleFilterChange}
                    placeholder="Filter by Invoice MS"
                  />
                </div>
                <div className="col-2">
                  <input
                    type="text"
                    name="filterInvoiceHSD"
                    value={filterInvoiceHSD}
                    onChange={handleFilterChange}
                    placeholder="Filter by Invoice HSD"
                  />
                </div>
                <div className="col-2">
                  <label>Total MS</label> {totalInvoiceMS}
                </div>
                <div className="col-2">
                  <label> Total HSD</label> {totalInvoiceHSD}
                </div>
              </div>
            </div>

            <TankerunloadingList
              tankerunloadings={tankerunloadings}
            />
            
          </div>
        </div>
      </div>
      <Header />
    </>
  );
}
