// src/components/DensityList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from "../config";

const DensityList = ({densitys,fetchDensitys,density,setDensity}) => {  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleDelete = async (id) => {
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      if (window.confirm("Are you sure to delete this Reading?")) {
        //console.log('id',id);
        const staticData = {
          token : localStorage.getItem('authToken'),
          id: id,
        };
        const response = await axios.post(`${API_BASE_URL}/api/densityremove`, staticData, {
          headers: {
            'Content-Type': 'application/json',
          },
        }); 
        setSuccess('Item remove successfully!');
        //fetchDensitys(); 
        window.location.reload();      
      }
    } catch (err) {
     // console.log(err);
    }
  };

  return (  
    <div className="show-detils">
      {densitys.length > 0 && (
        <table className="table table-dark table-striped">
          <thead>
              <tr>
                <th>Actions</th>
                <th>Time</th>
                <th>Density</th>
                <th>Temperature</th>
                <th>Result</th>
              </tr>
          </thead>
          <tbody>
              {densitys.map((entry,i) => (
              <tr key={i}>
                  <td>

                  <button
                    onClick={() => handleDelete(entry.id)}
                    className="btn btn-danger ms-2"
                  >
                  </button>
                  </td>
                  <td>{entry.created_at}</td>
                  <td>{entry.density}</td>
                  <td>{entry.temperature}</td>
                  <td>{entry.density_result}</td>
              </tr>
              ))}
          </tbody>
        </table>
       )}
    </div>
  );
};

export default DensityList;