// src/components/Dashboard.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import ProductList from "./ProductList";
import AddProductForm from "./AddProductForm";
import Header from "./Header";
import { API_BASE_URL } from "../config";

const Dashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { logout } = useAuth();
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    token: localStorage.getItem("authToken"),
    MS_opening: "",
    MS_closing: "",
    MS_testing: "5",
    MS_subtotal: "",
    MS_price: "107.51",
    MS_total: "",
    HSD_opening: "",
    HSD_closing: "",
    HSD_testing: "5",
    HSD_subtotal: "",
    HSD_price: "92.82",
    HSD_total: "",
    credit1: "",
    card: "",
    scan: "",
    cash: "",
    grandTotal: "",
  });

  const navigate = useNavigate(); // Call useNavigate to get navigate function

  const [credentials] = useState({
    token: localStorage.getItem("authToken"),
  });

  // Fetch products from the API
  const fetchProducts = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/getreading`,
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        //console.log(response.data.data);
        setProducts(response.data.data);
      } else {
      }
    } catch (err) {
      logout();
      //setError(response.data.message);
      navigate("/"); // Use navigate to redirect
    }
  };

  useEffect(() => {
    const authState = localStorage.getItem("authToken");
    if (authState) {
      fetchProducts();
      setIsLoggedIn(true);
    }
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
      <Header />
      <div className="du-detils">
        <div className="container">
          <AddProductForm
            fetchProducts={fetchProducts}
            product={product}
            setProduct={setProduct}
            isLoggedIn={isLoggedIn}
          />
          {isLoggedIn ? (
            <ProductList
              products={products}
              fetchProducts={fetchProducts}
              product={product}
              setProduct={setProduct}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <Header />
    </>
  );
};

export default Dashboard;
