// AddTankerUnloading.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { API_BASE_URL } from "../config";

const AddTankerUnloading = ({ fetchTankerunloading, tankerunloading, setTankerunloading,isLoggedIn }) => {
  const navigate = useNavigate(); // Call useNavigate to get navigate function
  const { logout } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = { ...tankerunloading, [name]: value };
    //console.log('name',name);
    if (name === 'invoice_MS' || name === 'scropning_MS' || name === 'scrclosing_MS') {
      if(updatedValue.invoice_MS && updatedValue.scropning_MS && updatedValue.scrclosing_MS){
        var scrtotal_MS = parseFloat(updatedValue.scrclosing_MS) - (parseFloat(updatedValue.invoice_MS)+parseFloat(updatedValue.scropning_MS));
        updatedValue.scrtotal_MS = scrtotal_MS;
      }else{
        updatedValue.scrtotal_MS = '';
      }
    }
    if (name === 'invoice_HSD' || name === 'scropning_HSD' || name === 'scrclosing_HSD') {
      if(updatedValue.invoice_HSD && updatedValue.scropning_HSD && updatedValue.scrclosing_HSD){
        var scrtotal_HSD = parseFloat(updatedValue.scrclosing_HSD) - (parseFloat(updatedValue.invoice_HSD)+parseFloat(updatedValue.scropning_HSD));
        updatedValue.scrtotal_HSD = scrtotal_HSD;
      }else{
        updatedValue.scrtotal_HSD = '';
      }
    }
    if (name === 'invoice_MS' || name === 'depopning_MS' || name === 'depclosing_MS') {
      if(updatedValue.invoice_MS && updatedValue.depopning_MS && updatedValue.depclosing_MS){
        var deptotal_MS = parseFloat(updatedValue.depclosing_MS) - (parseFloat(updatedValue.invoice_MS)+parseFloat(updatedValue.depopning_MS));
        updatedValue.deptotal_MS = deptotal_MS;
      }else{
        updatedValue.deptotal_MS = '';
      }
    }
    if (name === 'invoice_HSD' || name === 'depopning_HSD' || name === 'depclosing_HSD') {
      if(updatedValue.invoice_HSD && updatedValue.depopning_HSD && updatedValue.depclosing_HSD){
        var deptotal_HSD = parseFloat(updatedValue.depclosing_HSD) - (parseFloat(updatedValue.invoice_HSD)+parseFloat(updatedValue.depopning_HSD));
        updatedValue.deptotal_HSD = deptotal_HSD;
      }else{
        updatedValue.deptotal_HSD = '';
      }
    }
    
    setTankerunloading(updatedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    const pressure = parseInt(tankerunloading.tankerunloading, 0);
    const temp = parseInt(tankerunloading.temperature, 0);
    let tankerunloading_result = 0;
    setSuccess("tankerunloading",tankerunloading);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/tankerunloadingsave`,
        { ...tankerunloading, tankerunloading_result },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      //console.log('tankerunloading added:', response.data);
      setSuccess("Item added successfully!");
      fetchTankerunloading();
      //window.location.reload();
      setLoading(false);

      // Clear form after successful submission
      setTankerunloading({ tankerunloading: "" });
    } catch (error) {
      //setError('Failed to add tankerunloading: ' + (error.response?.data?.message || error.message));
      //setLoading(false);
      logout();
      navigate("/"); // Use navigate to redirect
    }
  };


  

  return (
    <div className="form">
      <form onSubmit={isLoggedIn ? handleSubmit : undefined}>
        <span>Tanker Unloading</span>
        <div className="row">
          <div className="col-2 col-md-2">
            <label>Date:</label>
            <input
              type="date"
              name="date"
              className="form-control"
              placeholder="Date"
              value={tankerunloading.date}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Invoice MS:</label>
            <input
              type="number"
              name="invoice_MS"
              className="form-control"
              placeholder="Invoice MS"
              value={tankerunloading.invoice_MS}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Invoice HSD:</label>
            <input
              type="number"
              name="invoice_HSD"
              className="form-control"
              placeholder="Invoice HSD"
              value={tankerunloading.invoice_HSD}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Scr Opning MS:</label>
            <input
              type="number"
              name="scropning_MS"
              className="form-control"
              placeholder="Scr Opning MS"
              value={tankerunloading.scropning_MS}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Scr Closing MS:</label>
            <input
              type="number"
              name="scrclosing_MS"
              className="form-control"
              placeholder="Scr Closing MS"
              value={tankerunloading.scrclosing_MS}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Scr MS Total:</label>
            <input
              type="number"
              name="scrtotal_MS"
              className="form-control"
              placeholder="Scr MS Total"
              value={tankerunloading.scrtotal_MS}
              onChange={handleChange}
              required
              readOnly="readOnly"
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Scr Opning HSD:</label>
            <input
              type="number"
              name="scropning_HSD"
              className="form-control"
              placeholder="Scr Opning HSD"
              value={tankerunloading.scropning_HSD}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Scr Closing HSD:</label>
            <input
              type="number"
              name="scrclosing_HSD"
              className="form-control"
              placeholder="Scr Closing HSD"
              value={tankerunloading.scrclosing_HSD}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Scr HSD Total:</label>
            <input
              type="number"
              name="scrtotal_HSD"
              className="form-control"
              placeholder="Scr HSD Total"
              value={tankerunloading.scrtotal_HSD}
              onChange={handleChange}
              required
              readOnly="readOnly"
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Dep Opning MS:</label>
            <input
              type="number"
              name="depopning_MS"
              className="form-control"
              placeholder="Dep Opning MS"
              value={tankerunloading.depopning_MS}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Dep Closing MS:</label>
            <input
              type="number"
              name="depclosing_MS"
              className="form-control"
              placeholder="Dep Closing MS"
              value={tankerunloading.depclosing_MS}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Dep MS Total:</label>
            <input
              type="number"
              name="deptotal_MS"
              className="form-control"
              placeholder="Dep MS Total"
              value={tankerunloading.deptotal_MS}
              onChange={handleChange}
              required
              readOnly="readOnly"
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Dep Opning HSD:</label>
            <input
              type="number"
              name="depopning_HSD"
              className="form-control"
              placeholder="Dep Opning HSD"
              value={tankerunloading.depopning_HSD}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Dep Closing HSD:</label>
            <input
              type="number"
              name="depclosing_HSD"
              className="form-control"
              placeholder="Dep Closing HSD"
              value={tankerunloading.depclosing_HSD}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-2 col-md-2">
            <label>Dep HSD Total:</label>
            <input
              type="number"
              name="deptotal_HSD"
              className="form-control"
              placeholder="Dep HSD Total"
              value={tankerunloading.deptotal_HSD}
              onChange={handleChange}
              required
              readOnly="readOnly"
            />
          </div>
          <div className="col-2 col-md-2">
            <button
              className="btn btn-primary bt-form"
              type="submit"
              disabled={loading}
            >
              {loading ? "Calculating & Save..." : "Calculate & Save"}
            </button>
          </div>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && <p style={{ color: "green" }}>{success}</p>}
      </form>
    </div>
  );
};

export default AddTankerUnloading;
