import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { Modal, Button, Table } from "react-bootstrap";
import calculatorimages from "../Images/calculator.png";
import CalculatorList from "./CalculatorList";
import { API_BASE_URL } from "../config";

export default function MoneyCounter() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { logout } = useAuth();
  const [calculators, setCalculators] = useState([]);
  const [calculator, setCalculator] = useState({
    token: localStorage.getItem("authToken"),
    count_500: "",
    count_200: "",
    count_100: "",
    count_50: "",
    count_20: "",
    count_10: "",
    count_5: "",
    count_2: "",
    count_1: "",
  });

  const [showMoneyCounter, setShowMoneyCounter] = useState(false);
  const [denominations, setDenominations] = useState({
    500: "",
    200: "",
    100: "",
    50: "",
    20: "",
    10: "",
    5: "",
    2: "",
    1: "",
  });
  
  const navigate = useNavigate(); // Call useNavigate to get navigate function
  const [credentials] = useState({
    token: localStorage.getItem("authToken"),
  });

  const fetchCalculators = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/getcalculator`,
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        //console.log(response.data.data);
        setCalculators(response.data.data);
      } else {
      }
    } catch (err) {
      logout();
      //setError(response.data.message);
      navigate("/"); // Use navigate to redirect
    }
  };
  // Load data from localStorage on component mount
  useEffect(() => {
    const authState = localStorage.getItem("authToken");
    if (authState) {
      fetchCalculators();
      setIsLoggedIn(true);
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    let total_count = calculateTotal();

    if (isNaN(total_count) || total_count < 0 || total_count.trim() === '') {
      alert("Please enter calculation values.");
      setLoading(false);
      return;
    }

       


    try {
      const response = await axios.post(`${API_BASE_URL}/api/calculatorsave`,
        { ...calculator, total_count },
        {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      //console.log('Calculator added:', response.data);
      setSuccess('Item added successfully!');
      //fetchCalculators();
      window.location.reload();
      setLoading(false);

      // Clear form after successful submission
      setCalculator({ count_500: '', count_200: '', count_100: '', count_50: '', count_20: '', count_10: '', count_5: '', count_2: '', count_1: '' });
    } catch (error) {
      //setError('Failed to add Calculator: ' + (error.response?.data?.message || error.message));
      //setLoading(false);
      logout();
      navigate('/'); // Use navigate to redirect
    }
  };

  const handleOpenMoneyCounter = () => setShowMoneyCounter(true);
  const handleCloseMoneyCounter = () => setShowMoneyCounter(false);
  // Function to calculate the total
  const calculateTotal = (data) => {
    const total = Object.entries(data || denominations).reduce(
      (sum, [denom, count]) => {
        return sum + parseFloat(denom) * (parseInt(count) || 0);
      },
      0
    );
    return total === 0 ? "" : total.toFixed(2);
  };

  const handleDenominationChange = (e) => {
    const { name, value } = e.target;
    setDenominations((prev) => ({
      ...prev,
      [name]: value,
    }));
    setCalculator({ ...calculator, [`count_`+name]: value });
  };

  // Define the order of denominations
  const orderedDenominations = [
    "500",
    "200",
    "100",
    "50",
    "20",
    "10",
    "5",
    "2",
    "1",
  ];

  return (
    <div className="money_counter">
      <span className="bt-calculeter" onClick={handleOpenMoneyCounter}>
        <img
          src={calculatorimages}
          alt="calculator"
          variant="primary"
        ></img>
        Calculator
      </span>

      <Modal show={showMoneyCounter} onHide={handleCloseMoneyCounter}>
        <Modal.Header closeButton>
          <Modal.Title>Money Counter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row top-aj">
            {orderedDenominations.map((denom, index) => (
              <div key={index} className="form-group col-12">
                <div className="input-group mb-2">
                  <span className="input-group-text" id="basic-addon1">
                    {denom} ×
                  </span>
                  <input
                    type="number"
                    name={denom}
                    value={denominations[denom]}
                    onChange={handleDenominationChange}
                    placeholder="0"
                    className="form-control"
                  />
                  <span className="input-group-text">
                    ={" "}
                    {(
                      parseFloat(denom) * (parseInt(denominations[denom]) || 0)
                    ).toFixed(2)}
                  </span>
                </div>
              </div>
            ))}
            <h4>Total: {calculateTotal()}</h4>
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMoneyCounter}>
            Close
          </Button>
          {isLoggedIn ? ( 
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
          ) : (
            ''
          )}
        </Modal.Footer>
        {/* Display saved data in a table */}
        <CalculatorList
            calculators={calculators}
            fetchCalculators={fetchCalculators}
            calculator={calculator}
            setCalculator={setCalculator}
        />
      </Modal>
    </div>
  );
}
