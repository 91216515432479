// src/components/Density.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import DensityList from "./DensityList";
import AddDensityForm from "./AddDensityForm";
import Header from "./Header";
import { API_BASE_URL } from "../config";


const Density = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { logout } = useAuth();
  const [densitys, setDensitys] = useState([]);
  const [density, setDensity] = useState({
    token: localStorage.getItem("authToken"),
    density: "",
    temperature: "",
  });
  

  const navigate = useNavigate(); // Call useNavigate to get navigate function

  const [credentials] = useState({
    token: localStorage.getItem("authToken"),
  });

  // Fetch Densitys from the API
  const fetchDensitys = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/getdensity`,
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        //console.log(response.data.data);
        setDensitys(response.data.data);
      } else {
      }
    } catch (err) {
      logout();
      //setError(response.data.message);
      navigate("/"); // Use navigate to redirect
    }
  };

  useEffect(() => {
    const authState = localStorage.getItem("authToken");
    if (authState) {
      fetchDensitys();
      setIsLoggedIn(true);
    }
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
      <Header />
      <div className="densitycalculator">
        <div className="container">
          <AddDensityForm
            fetchDensitys={fetchDensitys}
            density={density}
            setDensity={setDensity}
            isLoggedIn={isLoggedIn}
          />
          {isLoggedIn && ( 
              <DensityList
                densitys={densitys}
                fetchDensitys={fetchDensitys}
                density={density}
                setDensity={setDensity}
              />
            )}
        </div>
      </div>

      <Header />
    </>
  );
};
export default Density;
