// src/components/TankerunloadingList.js
import React, { useEffect, useState } from 'react';

const TankerunloadingList = ({tankerunloadings}) => {  
  return (  
    <div className="tankerunloading-data">
        <table>
        <thead>
            <tr>
            <th>Date</th>
            <th>Invoice MS</th>
            <th>Invoice HSD</th>
            <th>scr Opning MS</th>
            <th>Scr Closing MS</th>
            <th>Scr Opning HSD</th>
            <th>Scr Closing HSD</th>
            <th>Dep Opning MS</th>
            <th>Dep Closing MS</th>
            <th>Dep Opning HSD</th>
            <th>Dep Closing HSD</th>
            <th>Scr Net MS</th>
            <th>Scr Net HSD</th>
            <th>Dep Net MS</th>
            <th>Dep Net HSD</th>
            </tr>
        </thead>
        <tbody>
            {tankerunloadings.map((row, index) => (
            <tr key={index}>
                <td>{row.date}</td>
                <td>{row.invoice_MS}</td>
                <td>{row.invoice_HSD}</td>
                <td>{row.scropning_MS}</td>
                <td>{row.scrclosing_MS}</td>
                <td>{row.scrtotal_MS}</td>
                <td>{row.scropning_HSD}</td>
                <td>{row.scrclosing_HSD}</td>
                <td>{row.scrtotal_HSD}</td>
                <td>{row.depopning_MS}</td>
                <td>{row.depclosing_MS}</td>
                <td>{row.deptotal_MS}</td>
                <td>{row.depopning_HSD}</td>
                <td>{row.depclosing_HSD}</td>
                <td>{row.deptotal_HSD}</td>
            </tr>
            ))}
        </tbody>
        </table>
    </div>
  );
};

export default TankerunloadingList;