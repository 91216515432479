// AddDensityForm.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { API_BASE_URL } from "../config";

const densityData = [
  {
    temp: 11.0,
    values: [
      696.3, 697.3, 698.3, 699.3, 700.3, 701.3, 702.3, 703.3, 704.3, 705.4,
      706.4, 707.4, 708.4, 709.4, 710.4, 711.4, 712.4, 713.4, 714.4, 715.4,
      716.4, 717.4, 718.4, 719.4, 720.4, 721.4, 722.4, 723.4, 724.4, 725.4,
      726.4, 727.4, 728.4, 729.4, 730.4, 731.4, 732.4, 733.4, 734.4, 735.4,
      736.4, 737.4, 738.4, 739.4, 740.4, 741.5, 742.5, 743.5, 744.5, 745.5,
      746.5, 747.5, 748.5, 749.5, 750.5, 751.5, 752.5, 753.5, 754.5, 755.5,
      756.5, 757.5, 758.5, 759.5, 760.5, 761.5, 762.5, 763.5, 764.5, 765.5,
      766.5, 767.5, 768.5, 769.5, 770.5, 771.6, 772.6, 773.6, 774.7, 775.7,
      776.7, 777.8, 778.8, 779.8, 780.8, 781.9, 782.9, 783.9, 785.0, 786.0,
      787.0, 788.1, 789.1, 790.1, 791.1, 792.1, 793.1, 794.1, 795.1, 796.1,
      797.1, 798.1, 799.1, 800.1, 801.1, 802.1, 803.1, 804.1, 805.1, 806.1,
      807.1, 808.1, 809.1, 810.1, 811.1, 812.1, 813.1, 814.2, 815.2, 816.2,
      817.2, 818.2, 819.2, 820.2, 821.2, 822.2, 823.2, 824.2, 825.2, 826.2,
      827.2, 828.2, 829.2, 830.2, 831.2, 832.2, 833.2, 834.2, 835.2, 836.2,
      837.2, 838.2, 839.2, 840.2, 841.2, 842.3, 843.3, 844.3, 845.3, 846.3,
      847.3, 848.3, 849.3, 850.3, 851.3, 852.3, 853.3, 854.3, 855.3, 856.3,
      857.3, 858.3, 859.3, 860.3, 861.3, 862.3, 863.3, 864.3, 865.3, 866.3,
      869.3, 870.3, 871.3, 872.3, 873.3, 874.3, 875.3, 876.3, 877.3, 878.3,
      879.3, 880.3, 881.3, 882.3, 883.3, 884.3, 885.3, 886.3, 887.3, 888.3,
      889.3, 890.3, 891.3, 892.3, 893.3, 894.3, 895.3, 896.3,
    ],
  },
  {
    temp: 11.5,
    values: [
      696.8, 697.8, 698.8, 699.8, 700.8, 701.8, 702.8, 703.8, 704.8, 705.8,
      706.8, 707.8, 708.8, 709.8, 710.8, 711.8, 712.8, 713.8, 714.8, 715.8,
      716.8, 717.8, 718.8, 719.8, 720.8, 721.8, 722.9, 723.9, 724.9, 725.9,
      726.9, 727.9, 728.9, 729.9, 730.9, 731.9, 732.9, 733.9, 734.9, 735.9,
      736.9, 737.9, 738.9, 739.9, 740.9, 741.9, 742.9, 743.9, 744.9, 745.9,
      746.9, 747.9, 748.9, 749.9, 750.9, 751.9, 752.9, 753.9, 754.9, 755.9,
      756.9, 757.9, 758.9, 759.9, 760.9, 761.9, 762.9, 763.9, 764.9, 765.9,
      766.9, 767.9, 769.0, 770.0, 771.0, 772.0, 773.0, 774.1, 775.1, 776.1,
      777.1, 778.2, 779.2, 780.2, 781.3, 782.3, 783.3, 784.3, 785.4, 786.4,
      787.4, 788.4, 789.4, 790.4, 791.4, 792.4, 793.4, 794.4, 795.4, 796.5,
      797.5, 798.5, 799.5, 800.5, 801.5, 802.5, 803.5, 804.5, 805.5, 806.5,
      807.5, 808.5, 809.5, 810.5, 811.5, 812.5, 813.5, 814.5, 815.5, 816.5,
      817.5, 818.5, 819.5, 820.5, 821.5, 822.5, 823.5, 824.5, 825.6, 826.6,
      827.6, 828.6, 829.6, 830.6, 831.6, 832.6, 833.6, 834.6, 835.6, 836.6,
      837.6, 838.6, 839.6, 840.6, 841.6, 842.6, 843.6, 844.6, 845.6, 846.6,
      847.6, 848.6, 849.6, 850.6, 851.6, 852.6, 853.6, 854.6, 855.6, 856.6,
      857.6, 858.6, 859.6, 860.6, 861.6, 862.6, 863.6, 864.6, 865.6, 866.6,
      869.6, 870.6, 871.6, 872.6, 873.6, 874.6, 875.6, 876.6, 877.6, 878.6,
      879.6, 880.6, 881.6, 882.6, 883.6, 884.6, 885.6, 886.6, 887.6, 888.6,
      889.6, 890.6, 891.6, 892.6, 893.6, 894.6, 895.6, 896.6,
    ],
  },
  {
    temp: 12.0,
    values: [
      697.2, 698.2, 699.3, 700.3, 701.3, 702.3, 703.3, 704.3, 705.3, 706.3,
      707.3, 708.3, 709.3, 710.3, 711.3, 712.3, 713.3, 714.3, 715.3, 716.3,
      717.3, 718.3, 719.3, 720.3, 721.3, 722.3, 723.3, 724.3, 725.3, 726.3,
      727.3, 728.3, 729.3, 730.3, 731.3, 732.3, 733.3, 734.3, 735.3, 736.3,
      737.3, 738.3, 739.3, 740.3, 741.3, 742.3, 743.3, 744.3, 745.3, 746.3,
      747.3, 748.3, 749.3, 750.4, 751.4, 752.4, 753.4, 754.4, 755.4, 756.4,
      757.4, 758.4, 759.4, 760.4, 761.4, 762.4, 763.4, 764.4, 765.4, 766.4,
      767.4, 768.4, 769.4, 770.4, 771.4, 772.4, 773.5, 774.5, 775.5, 776.5,
      777.6, 778.6, 779.6, 780.6, 781.7, 782.7, 783.7, 784.7, 785.7, 786.8,
      787.8, 788.8, 789.8, 790.8, 791.8, 792.8, 793.8, 794.8, 795.8, 796.8,
      797.8, 798.8, 799.8, 800.8, 801.8, 802.8, 803.8, 804.8, 805.8, 806.9,
      807.9, 808.9, 809.9, 810.9, 811.9, 812.9, 813.9, 814.9, 815.9, 816.9,
      817.9, 818.9, 819.9, 820.9, 821.9, 822.9, 823.9, 824.9, 825.9, 826.9,
      827.9, 828.9, 829.9, 830.9, 831.9, 832.9, 833.9, 834.9, 835.9, 836.9,
      837.9, 838.9, 839.9, 840.9, 841.9, 842.9, 843.9, 844.9, 845.9, 846.9,
      847.9, 848.9, 849.9, 850.9, 851.9, 852.9, 853.9, 854.9, 855.9, 856.9,
      857.9, 858.9, 860.0, 861.0, 862.0, 863.0, 864.0, 865.0, 866.0, 867.0,
      870.0, 871.0, 872.0, 873.0, 874.0, 875.0, 876.0, 877.0, 878.0, 879.0,
      880.0, 881.0, 882.0, 883.0, 884.0, 885.0, 886.0, 887.0, 888.0, 889.0,
      890.0, 891.0, 892.0, 893.0, 894.0, 895.0, 896.0, 897.0,
    ],
  },
  {
    temp: 12.5,
    values: [
      697.7, 698.7, 699.7, 700.7, 701.7, 702.7, 703.7, 704.7, 705.7, 706.7,
      707.7, 708.7, 709.7, 710.7, 711.7, 712.7, 713.7, 714.7, 715.7, 716.7,
      717.7, 718.7, 719.7, 720.7, 721.7, 722.7, 723.7, 724.8, 725.8, 726.8,
      727.8, 728.8, 729.8, 730.8, 731.8, 732.8, 733.8, 734.8, 735.8, 736.8,
      737.8, 738.8, 739.8, 740.8, 741.8, 742.8, 743.8, 744.8, 745.8, 746.8,
      747.8, 748.8, 749.8, 750.8, 751.8, 752.8, 753.8, 754.8, 755.8, 756.8,
      757.8, 758.8, 759.8, 760.8, 761.8, 762.8, 763.8, 764.8, 765.8, 766.8,
      767.8, 768.8, 769.8, 770.8, 771.9, 772.9, 773.9, 774.9, 775.9, 777.0,
      778.0, 779.0, 780.0, 781.0, 782.1, 783.1, 784.1, 785.1, 786.1, 787.2,
      788.2, 789.2, 790.2, 791.2, 792.2, 793.2, 794.2, 795.2, 796.2, 797.2,
      798.2, 799.2, 800.2, 801.2, 802.2, 803.2, 804.2, 805.2, 806.2, 807.2,
      808.2, 809.2, 810.2, 811.2, 812.2, 813.2, 814.2, 815.2, 816.2, 817.2,
      818.2, 819.2, 820.2, 821.2, 822.2, 823.2, 824.2, 825.3, 826.3, 827.3,
      828.3, 829.3, 830.3, 831.3, 832.3, 833.3, 834.3, 835.3, 836.3, 837.3,
      838.3, 839.3, 840.3, 841.3, 842.3, 843.3, 844.3, 845.3, 846.3, 847.3,
      848.3, 849.3, 850.3, 851.3, 852.3, 853.3, 854.3, 855.3, 856.3, 857.3,
      858.3, 859.3, 860.3, 861.3, 862.3, 863.3, 864.3, 865.3, 866.3, 867.3,
      870.3, 871.3, 872.3, 873.3, 874.3, 875.3, 876.3, 877.3, 878.3, 879.3,
      880.3, 881.3, 882.3, 883.3, 884.3, 885.3, 886.3, 887.3, 888.3, 889.3,
      890.3, 891.3, 892.3, 893.3, 894.3, 895.3, 896.3, 897.3,
    ],
  },
  {
    temp: 13.0,
    values: [
      698.2, 699.2, 700.2, 701.2, 702.2, 703.2, 704.2, 705.2, 706.2, 707.2,
      708.2, 709.2, 710.2, 711.2, 712.2, 713.2, 714.2, 715.2, 716.2, 717.2,
      718.2, 719.2, 720.2, 721.2, 722.2, 723.2, 724.2, 725.2, 726.2, 727.2,
      728.2, 729.2, 730.2, 731.2, 732.2, 733.2, 734.2, 735.2, 736.2, 737.2,
      738.2, 739.2, 740.2, 741.2, 742.2, 743.2, 744.2, 745.2, 746.2, 747.2,
      748.2, 749.2, 750.2, 751.2, 752.2, 753.2, 754.2, 755.2, 756.2, 757.2,
      758.2, 759.2, 760.3, 761.3, 762.3, 763.3, 764.3, 765.3, 766.3, 767.3,
      768.3, 769.3, 770.3, 771.3, 772.3, 773.3, 774.3, 775.3, 776.4, 777.4,
      778.4, 779.4, 780.4, 781.4, 782.5, 783.5, 784.5, 785.5, 786.5, 787.5,
      788.5, 789.5, 790.5, 791.5, 792.5, 793.5, 794.5, 795.5, 796.5, 797.6,
      798.6, 799.6, 800.6, 801.6, 802.6, 803.6, 804.6, 805.6, 806.6, 807.6,
      808.6, 809.6, 810.6, 811.6, 812.6, 813.6, 814.6, 815.6, 816.6, 817.6,
      818.6, 819.6, 820.6, 821.6, 822.6, 823.6, 824.6, 825.6, 826.6, 827.6,
      828.6, 829.6, 830.6, 831.6, 832.6, 833.6, 834.6, 835.6, 836.6, 837.6,
      838.6, 839.6, 840.6, 841.6, 842.6, 843.6, 844.6, 845.6, 846.6, 847.6,
      848.6, 849.6, 850.6, 851.6, 852.6, 853.6, 854.6, 855.6, 856.6, 857.6,
      858.6, 859.6, 860.6, 861.6, 862.6, 863.6, 864.6, 865.6, 866.6, 867.6,
      870.6, 871.6, 872.6, 873.6, 874.6, 875.6, 876.6, 877.6, 878.6, 879.6,
      880.6, 881.6, 882.6, 883.6, 884.6, 885.6, 886.6, 887.6, 888.6, 889.6,
      890.6, 891.6, 892.6, 893.6, 894.7, 895.7, 896.7, 897.7,
    ],
  },
  {
    temp: 13.5,
    values: [
      698.6, 699.6, 700.6, 701.6, 702.6, 703.6, 704.6, 705.6, 706.6, 707.6,
      708.6, 709.6, 710.6, 711.6, 712.6, 713.6, 714.6, 715.6, 716.6, 717.6,
      718.6, 719.6, 720.6, 721.6, 722.6, 723.7, 724.7, 725.7, 726.7, 727.7,
      728.7, 729.7, 730.7, 731.7, 732.7, 733.7, 734.7, 735.7, 736.7, 737.7,
      738.7, 739.7, 740.7, 741.7, 742.7, 743.7, 744.7, 745.7, 746.7, 747.7,
      748.7, 749.7, 750.7, 751.7, 752.7, 753.7, 754.7, 755.7, 756.7, 757.7,
      758.7, 759.7, 760.7, 761.7, 762.7, 763.7, 764.7, 765.7, 766.7, 767.7,
      768.7, 769.7, 770.7, 771.7, 772.7, 773.7, 774.8, 775.8, 776.8, 777.8,
      778.8, 779.8, 780.8, 781.8, 782.8, 783.9, 784.9, 785.9, 786.9, 787.9,
      788.9, 789.9, 790.9, 791.9, 792.9, 793.9, 794.9, 795.9, 796.9, 797.9,
      798.9, 799.9, 800.9, 801.9, 802.9, 803.9, 804.9, 805.9, 806.9, 807.9,
      808.9, 809.9, 810.9, 811.9, 812.9, 813.9, 814.9, 815.9, 816.9, 817.9,
      818.9, 819.9, 820.9, 821.9, 822.9, 823.9, 824.9, 826.0, 827.0, 828.0,
      829.0, 830.0, 831.0, 832.0, 833.0, 834.0, 835.0, 836.0, 837.0, 838.0,
      839.0, 840.0, 841.0, 842.0, 843.0, 844.0, 845.0, 846.0, 847.0, 848.0,
      849.0, 850.0, 851.0, 852.0, 853.0, 854.0, 855.0, 856.0, 857.0, 858.0,
      859.0, 860.0, 861.0, 862.0, 863.0, 864.0, 865.0, 866.0, 867.0, 868.0,
      871.0, 872.0, 873.0, 874.0, 875.0, 876.0, 877.0, 878.0, 879.0, 880.0,
      881.0, 882.0, 883.0, 884.0, 885.0, 886.0, 887.0, 888.0, 889.0, 890.0,
      891.0, 892.0, 893.0, 894.0, 895.0, 896.0, 897.0, 898.0,
    ],
  },
  {
    temp: 14.0,
    values: [
      699.1, 700.1, 701.1, 702.1, 703.1, 704.1, 705.1, 706.1, 707.1, 708.1,
      709.1, 710.1, 711.1, 712.1, 713.1, 714.1, 715.1, 716.1, 717.1, 718.1,
      719.1, 720.1, 721.1, 722.1, 723.1, 724.1, 725.1, 726.1, 727.1, 728.1,
      729.1, 730.1, 731.1, 732.1, 733.1, 734.1, 735.1, 736.1, 737.1, 738.1,
      739.1, 740.1, 741.1, 742.1, 743.1, 744.1, 745.1, 746.1, 747.1, 748.1,
      749.1, 750.1, 751.1, 752.1, 753.1, 754.1, 755.1, 756.1, 757.1, 758.1,
      759.1, 760.1, 761.1, 762.1, 763.1, 764.1, 765.1, 766.1, 767.1, 768.1,
      769.1, 770.1, 771.1, 772.1, 773.2, 774.2, 775.2, 776.2, 777.2, 778.2,
      779.2, 780.2, 781.2, 782.2, 783.2, 784.2, 785.2, 786.3, 787.3, 788.3,
      789.3, 790.3, 791.3, 792.3, 793.3, 794.3, 795.3, 796.3, 797.3, 798.3,
      799.3, 800.3, 801.3, 802.3, 803.3, 804.3, 805.3, 806.3, 807.3, 808.3,
      809.3, 810.3, 811.3, 812.3, 813.3, 814.3, 815.3, 816.3, 817.3, 818.3,
      819.3, 820.3, 821.3, 822.3, 823.3, 824.3, 825.3, 826.3, 827.3, 828.3,
      829.3, 830.3, 831.3, 832.3, 833.3, 834.3, 835.3, 836.3, 837.3, 838.3,
      839.3, 840.3, 841.3, 842.3, 843.3, 844.3, 845.3, 846.3, 847.3, 848.3,
      849.3, 850.3, 851.3, 852.3, 853.3, 854.3, 855.3, 856.3, 857.3, 858.3,
      859.3, 860.3, 861.3, 862.3, 863.3, 864.3, 865.3, 866.3, 867.3, 868.3,
      871.3, 872.3, 873.3, 874.3, 875.3, 876.3, 877.3, 878.3, 879.3, 880.3,
      881.3, 882.3, 883.3, 884.3, 885.3, 886.3, 887.3, 888.3, 889.3, 890.3,
      891.3, 892.3, 893.3, 894.3, 895.3, 896.3, 897.3, 898.3,
    ],
  },
  {
    temp: 14.5,
    values: [
      699.5, 700.5, 701.5, 702.5, 703.5, 704.5, 705.5, 706.5, 707.5, 708.5,
      709.5, 710.5, 711.5, 712.5, 713.5, 714.5, 715.5, 716.5, 717.5, 718.5,
      719.6, 720.6, 721.6, 722.6, 723.6, 724.6, 725.6, 726.6, 727.6, 728.6,
      729.6, 730.6, 731.6, 732.6, 733.6, 734.6, 735.6, 736.6, 737.6, 738.6,
      739.6, 740.6, 741.6, 742.6, 743.6, 744.6, 745.6, 746.6, 747.6, 748.6,
      749.6, 750.6, 751.6, 752.6, 753.6, 754.6, 755.6, 756.6, 757.6, 758.6,
      759.6, 760.6, 761.6, 762.6, 763.6, 764.6, 765.6, 766.6, 767.6, 768.6,
      769.6, 770.6, 771.6, 772.6, 773.6, 774.6, 775.6, 776.6, 777.6, 778.6,
      779.6, 780.6, 781.6, 782.6, 783.6, 784.6, 785.6, 786.6, 787.6, 788.6,
      789.6, 790.6, 791.6, 792.6, 793.6, 794.6, 795.6, 796.6, 797.6, 798.6,
      799.6, 800.6, 801.6, 802.6, 803.6, 804.6, 805.6, 806.6, 807.6, 808.6,
      809.6, 810.6, 811.6, 812.6, 813.6, 814.6, 815.6, 816.6, 817.6, 818.6,
      819.6, 820.6, 821.6, 822.6, 823.6, 824.6, 825.7, 826.7, 827.7, 828.7,
      829.7, 830.7, 831.7, 832.7, 833.7, 834.7, 835.7, 836.7, 837.7, 838.7,
      839.7, 840.7, 841.7, 842.7, 843.7, 844.7, 845.7, 846.7, 847.7, 848.7,
      849.7, 850.7, 851.7, 852.7, 853.7, 854.7, 855.7, 856.7, 857.7, 858.7,
      859.7, 860.7, 861.7, 862.7, 863.7, 864.7, 865.7, 866.7, 867.7, 868.7,
      871.7, 872.7, 873.7, 874.7, 875.7, 876.7, 877.7, 878.7, 879.7, 880.7,
      881.7, 882.7, 883.7, 884.7, 885.7, 886.7, 887.7, 888.7, 889.7, 890.7,
      891.7, 892.7, 893.7, 894.7, 895.7, 896.7, 897.7, 898.7,
    ],
  },
  {
    temp: 15.0,
    values: [
      700.0, 701.0, 702.0, 703.0, 704.0, 705.0, 706.0, 707.0, 708.0, 709.0,
      710.0, 711.0, 712.0, 713.0, 714.0, 715.0, 716.0, 717.0, 718.0, 719.0,
      720.0, 721.0, 722.0, 723.0, 724.0, 725.0, 726.0, 727.0, 728.0, 729.0,
      730.0, 731.0, 732.0, 733.0, 734.0, 735.0, 736.0, 737.0, 738.0, 739.0,
      740.0, 741.0, 742.0, 743.0, 744.0, 745.0, 746.0, 747.0, 748.0, 749.0,
      750.0, 751.0, 752.0, 753.0, 754.0, 755.0, 756.0, 757.0, 758.0, 759.0,
      760.0, 761.0, 762.0, 763.0, 764.0, 765.0, 766.0, 767.0, 768.0, 769.0,
      770.0, 771.0, 772.0, 773.0, 774.0, 775.0, 776.0, 777.0, 778.0, 779.0,
      780.0, 781.0, 782.0, 783.0, 784.0, 785.0, 786.0, 787.0, 788.0, 789.0,
      790.0, 791.0, 792.0, 793.0, 794.0, 795.0, 796.0, 797.0, 798.0, 799.0,
      800.0, 801.0, 802.0, 803.0, 804.0, 805.0, 806.0, 807.0, 808.0, 809.0,
      810.0, 811.0, 812.0, 813.0, 814.0, 815.0, 816.0, 817.0, 818.0, 819.0,
      820.0, 821.0, 822.0, 823.0, 824.0, 825.0, 826.0, 827.0, 828.0, 829.0,
      830.0, 831.0, 832.0, 833.0, 834.0, 835.0, 836.0, 837.0, 838.0, 839.0,
      840.0, 841.0, 842.0, 843.0, 844.0, 845.0, 846.0, 847.0, 848.0, 849.0,
      850.0, 851.0, 852.0, 853.0, 854.0, 855.0, 856.0, 857.0, 858.0, 859.0,
      860.0, 861.0, 862.0, 863.0, 864.0, 865.0, 866.0, 867.0, 868.0, 869.0,
      872.0, 873.0, 874.0, 875.0, 876.0, 877.0, 878.0, 879.0, 880.0, 881.0,
      882.0, 883.0, 884.0, 885.0, 886.0, 887.0, 888.0, 889.0, 890.0, 891.0,
      892.0, 893.0, 894.0, 895.0, 896.0, 897.0, 898.0, 899.0,
    ],
  },
  {
    temp: 15.5,
    values: [
      700.5, 701.5, 702.5, 703.5, 704.5, 705.5, 706.5, 707.5, 708.5, 709.5,
      710.5, 711.5, 712.5, 713.5, 714.5, 715.5, 716.5, 717.5, 718.5, 719.5,
      720.4, 721.4, 722.4, 723.4, 724.4, 725.4, 726.4, 727.4, 728.4, 729.4,
      730.4, 731.4, 732.4, 733.4, 734.4, 735.4, 736.4, 737.4, 738.4, 739.4,
      740.4, 741.4, 742.4, 743.4, 744.4, 745.4, 746.4, 747.4, 748.4, 749.4,
      750.4, 751.4, 752.4, 753.4, 754.4, 755.4, 756.4, 757.4, 758.4, 759.4,
      760.4, 761.4, 762.4, 763.4, 764.4, 765.4, 766.4, 767.4, 768.4, 769.4,
      770.4, 771.4, 772.4, 773.4, 774.4, 775.4, 776.4, 777.4, 778.4, 779.4,
      780.4, 781.4, 782.4, 783.4, 784.4, 785.4, 786.4, 787.4, 788.4, 789.4,
      790.4, 791.4, 792.4, 793.4, 794.4, 795.4, 796.4, 797.4, 798.4, 799.4,
      800.4, 801.4, 802.4, 803.4, 804.4, 805.4, 806.4, 807.4, 808.4, 809.4,
      810.4, 811.4, 812.4, 813.4, 814.4, 815.4, 816.4, 817.4, 818.4, 819.4,
      820.4, 821.4, 822.4, 823.4, 824.4, 825.3, 826.3, 827.3, 828.3, 829.3,
      830.3, 831.3, 832.3, 833.3, 834.3, 835.3, 836.3, 837.3, 838.3, 839.3,
      840.3, 841.3, 842.3, 843.3, 844.3, 845.3, 846.3, 847.3, 848.3, 849.3,
      850.3, 851.3, 852.3, 853.3, 854.3, 855.3, 856.3, 857.3, 858.3, 859.3,
      860.3, 861.3, 862.3, 863.3, 864.3, 865.3, 866.3, 867.3, 868.3, 869.3,
      872.3, 873.3, 874.3, 875.3, 876.3, 877.3, 878.3, 879.3, 880.3, 881.3,
      882.3, 883.3, 884.3, 885.3, 886.3, 887.3, 888.3, 889.3, 890.3, 891.3,
      892.3, 893.3, 894.3, 895.3, 896.3, 897.3, 898.3, 899.3,
    ],
  },
  {
    temp: 16.0,
    values: [
      700.9, 701.9, 702.9, 703.9, 704.9, 705.9, 706.9, 707.9, 708.9, 709.9,
      710.9, 711.9, 712.9, 713.9, 714.9, 715.9, 716.9, 717.9, 718.9, 719.9,
      720.9, 721.9, 722.9, 723.9, 724.9, 725.9, 726.9, 727.9, 728.9, 729.9,
      730.9, 731.9, 732.9, 733.9, 734.9, 735.9, 736.9, 737.9, 738.9, 739.9,
      740.9, 741.9, 742.9, 743.9, 744.9, 745.9, 746.9, 747.9, 748.9, 749.9,
      750.9, 751.9, 752.9, 753.9, 754.9, 755.9, 756.9, 757.9, 758.9, 759.9,
      760.9, 761.9, 762.9, 763.9, 764.9, 765.9, 766.9, 767.9, 768.9, 769.9,
      770.9, 771.9, 772.8, 773.8, 774.8, 775.8, 776.8, 777.8, 778.8, 779.8,
      780.8, 781.8, 782.8, 783.8, 784.8, 785.7, 786.7, 787.7, 788.7, 789.7,
      790.7, 791.7, 792.7, 793.7, 794.7, 795.7, 796.7, 797.7, 798.7, 799.7,
      800.7, 801.7, 802.7, 803.7, 804.7, 805.7, 806.7, 807.7, 808.7, 809.7,
      810.7, 811.7, 812.7, 813.7, 814.7, 815.7, 816.7, 817.7, 818.7, 819.7,
      820.7, 821.7, 822.7, 823.7, 824.7, 825.7, 826.7, 827.7, 828.7, 829.7,
      830.7, 831.7, 832.7, 833.7, 834.7, 835.7, 836.7, 837.7, 838.7, 839.7,
      840.7, 841.7, 842.7, 843.7, 844.7, 845.7, 846.7, 847.7, 848.7, 849.7,
      850.7, 851.7, 852.7, 853.7, 854.7, 855.7, 856.7, 857.7, 858.7, 859.7,
      860.7, 861.7, 862.7, 863.7, 864.7, 865.7, 866.7, 867.7, 868.7, 869.7,
      872.7, 873.7, 874.7, 875.7, 876.7, 877.7, 878.7, 879.7, 880.7, 881.7,
      882.7, 883.7, 884.7, 885.7, 886.7, 887.7, 888.7, 889.7, 890.7, 891.7,
      892.7, 893.7, 894.7, 895.7, 896.7, 897.7, 898.7, 899.7,
    ],
  },
  {
    temp: 16.5,
    values: [
      701.4, 702.4, 703.4, 704.4, 705.4, 706.4, 707.4, 708.4, 709.4, 710.4,
      711.4, 712.4, 713.4, 714.4, 715.4, 716.4, 717.4, 718.4, 719.4, 720.4,
      721.4, 722.4, 723.4, 724.4, 725.4, 726.4, 727.3, 728.3, 729.3, 730.3,
      731.3, 732.3, 733.3, 734.3, 735.3, 736.3, 737.3, 738.3, 739.3, 740.3,
      741.3, 742.3, 743.3, 744.3, 745.3, 746.3, 747.3, 748.3, 749.3, 750.3,
      751.3, 752.3, 753.3, 754.3, 755.3, 756.3, 757.3, 758.3, 759.3, 760.3,
      761.3, 762.3, 763.3, 764.3, 765.3, 766.3, 767.3, 768.3, 769.3, 770.3,
      771.3, 772.3, 773.3, 774.3, 775.2, 776.2, 777.2, 778.2, 779.2, 780.2,
      781.2, 782.2, 783.2, 784.1, 785.1, 786.1, 787.1, 788.1, 789.1, 790.1,
      791.1, 792.1, 793.1, 794.1, 795.1, 796.1, 797.1, 798.1, 799.1, 800.1,
      801.1, 802.1, 803.1, 804.1, 805.1, 806.1, 807.1, 808.1, 809.1, 810.1,
      811.1, 812.1, 813.1, 814.1, 815.1, 816.1, 817.1, 818.1, 819.1, 820.1,
      821.1, 822.1, 823.1, 824.1, 825.1, 826.0, 827.0, 828.0, 829.0, 830.0,
      831.0, 832.0, 833.0, 834.0, 835.0, 836.0, 837.0, 838.0, 839.0, 840.0,
      841.0, 842.0, 843.0, 844.0, 845.0, 846.0, 847.0, 848.0, 849.0, 850.0,
      851.0, 852.0, 853.0, 854.0, 855.0, 856.0, 857.0, 858.0, 859.0, 860.0,
      861.0, 862.0, 863.0, 864.0, 865.0, 866.0, 867.0, 868.0, 869.0, 870.0,
      873.0, 874.0, 875.0, 876.0, 877.0, 878.0, 879.0, 880.0, 881.0, 882.0,
      883.0, 884.0, 885.0, 886.0, 887.0, 888.0, 889.0, 890.0, 891.0, 892.0,
      893.0, 894.0, 895.0, 896.0, 897.0, 898.0, 899.0, 900.0,
    ],
  },
  {
    temp: 17.0,
    values: [
      701.8, 702.8, 703.8, 704.8, 705.8, 706.8, 707.8, 708.8, 709.8, 710.8,
      711.8, 712.8, 713.8, 714.8, 715.8, 716.8, 717.8, 718.8, 719.8, 720.8,
      721.8, 722.8, 723.8, 724.8, 725.8, 726.8, 727.8, 728.8, 729.8, 730.8,
      731.8, 732.8, 733.8, 734.8, 735.8, 736.8, 737.8, 738.8, 739.8, 740.8,
      741.8, 742.8, 743.8, 744.8, 745.8, 746.8, 747.8, 748.8, 749.8, 750.8,
      751.8, 752.8, 753.8, 754.8, 755.8, 756.8, 757.8, 758.8, 759.8, 760.8,
      761.8, 762.7, 763.7, 764.7, 765.7, 766.7, 767.7, 768.7, 769.7, 770.7,
      771.7, 772.7, 773.7, 774.7, 775.7, 776.6, 777.6, 778.6, 779.6, 780.6,
      781.6, 782.5, 783.5, 784.5, 785.5, 786.5, 787.5, 788.5, 789.5, 790.5,
      791.5, 792.5, 793.5, 794.5, 795.5, 796.5, 797.5, 798.4, 799.4, 800.4,
      801.4, 802.4, 803.4, 804.4, 805.4, 806.4, 807.4, 808.4, 809.4, 810.4,
      811.4, 812.4, 813.4, 814.4, 815.4, 816.4, 817.4, 818.4, 819.4, 820.4,
      821.4, 822.4, 823.4, 824.4, 825.4, 826.4, 827.4, 828.4, 829.4, 830.4,
      831.4, 832.4, 833.4, 834.4, 835.4, 836.4, 837.4, 838.4, 839.4, 840.4,
      841.4, 842.4, 843.4, 844.4, 845.4, 846.4, 847.4, 848.4, 849.4, 850.4,
      851.4, 852.4, 853.4, 854.4, 855.4, 856.4, 857.4, 858.4, 859.4, 860.4,
      861.4, 862.4, 863.4, 864.4, 865.4, 866.4, 867.4, 868.4, 869.4, 870.4,
      873.4, 874.4, 875.4, 876.4, 877.4, 878.4, 879.4, 880.4, 881.4, 882.4,
      883.4, 884.4, 885.4, 886.4, 887.4, 888.4, 889.4, 890.4, 891.4, 892.4,
      893.4, 894.4, 895.4, 896.4, 897.3, 898.3, 899.3, 900.3,
    ],
  },
  {
    temp: 17.5,
    values: [
      702.3, 703.3, 704.3, 705.3, 706.3, 707.3, 708.3, 709.3, 710.3, 711.3,
      712.3, 713.3, 714.3, 715.3, 716.3, 717.3, 718.3, 719.3, 720.3, 721.3,
      722.3, 723.3, 724.3, 725.3, 726.3, 727.3, 728.2, 729.2, 730.2, 731.2,
      732.2, 733.2, 734.2, 735.2, 736.2, 737.2, 738.2, 739.2, 740.2, 741.2,
      742.2, 743.2, 744.2, 745.2, 746.2, 747.2, 748.2, 749.2, 750.2, 751.2,
      752.2, 753.2, 754.2, 755.2, 756.2, 757.2, 758.2, 759.2, 760.2, 761.2,
      762.2, 763.2, 764.2, 765.2, 766.2, 767.2, 768.2, 769.2, 770.2, 771.2,
      772.1, 773.1, 774.1, 775.1, 776.1, 777.1, 778.0, 779.0, 780.0, 781.0,
      782.0, 782.9, 783.9, 784.9, 785.9, 786.9, 787.8, 788.8, 789.8, 790.8,
      791.8, 792.8, 793.8, 794.8, 795.8, 796.8, 797.8, 798.8, 799.8, 800.8,
      801.8, 802.8, 803.8, 804.8, 805.8, 806.8, 807.8, 808.8, 809.8, 810.8,
      811.8, 812.8, 813.8, 814.8, 815.8, 816.8, 817.8, 818.8, 819.8, 820.8,
      821.8, 822.8, 823.8, 824.8, 825.8, 826.7, 827.7, 828.7, 829.7, 830.7,
      831.7, 832.7, 833.7, 834.7, 835.7, 836.7, 837.7, 838.7, 839.7, 840.7,
      841.7, 842.7, 843.7, 844.7, 845.7, 846.7, 847.7, 848.7, 849.7, 850.7,
      851.7, 852.7, 853.7, 854.7, 855.7, 856.7, 857.7, 858.7, 859.7, 860.7,
      861.7, 862.7, 863.7, 864.7, 865.7, 866.7, 867.7, 868.7, 869.7, 870.7,
      873.7, 874.7, 875.7, 876.7, 877.7, 878.7, 879.7, 880.7, 881.7, 882.7,
      883.7, 884.7, 885.7, 886.7, 887.7, 888.7, 889.7, 890.7, 891.7, 892.7,
      893.7, 894.7, 895.7, 896.7, 897.7, 898.7, 899.7, 900.7,
    ],
  },
  {
    temp: 18.0,
    values: [
      702.7, 703.7, 704.7, 705.7, 706.7, 707.7, 708.7, 709.7, 710.7, 711.7,
      712.7, 713.7, 714.7, 715.7, 716.7, 717.7, 718.7, 719.7, 720.7, 721.7,
      722.7, 723.7, 724.7, 725.7, 726.7, 727.7, 728.7, 729.7, 730.7, 731.7,
      732.7, 733.7, 734.7, 735.7, 736.7, 737.7, 738.7, 739.7, 740.7, 741.7,
      742.7, 743.7, 744.7, 745.7, 746.7, 747.7, 748.7, 749.7, 750.7, 751.7,
      752.6, 753.6, 754.6, 755.6, 756.6, 757.6, 758.6, 759.6, 760.6, 761.6,
      762.6, 763.6, 764.6, 765.6, 766.6, 767.6, 768.6, 769.6, 770.6, 771.6,
      772.6, 773.5, 774.5, 775.5, 776.5, 777.5, 778.4, 779.4, 780.4, 781.4,
      782.3, 783.3, 784.3, 785.3, 786.2, 787.2, 788.2, 789.2, 790.2, 791.2,
      792.2, 793.2, 794.2, 795.2, 796.2, 797.2, 798.2, 799.2, 800.2, 801.2,
      802.2, 803.2, 804.2, 805.2, 806.2, 807.2, 808.1, 809.1, 810.1, 811.1,
      812.1, 813.1, 814.1, 815.1, 816.1, 817.1, 818.1, 819.1, 820.1, 821.1,
      822.1, 823.1, 824.1, 825.1, 826.1, 827.1, 828.1, 829.1, 830.1, 831.1,
      832.1, 833.1, 834.1, 835.1, 836.1, 837.1, 838.1, 839.1, 840.1, 841.1,
      842.1, 843.1, 844.1, 845.1, 846.1, 847.1, 848.1, 849.1, 850.1, 851.1,
      852.1, 853.1, 854.1, 855.1, 856.1, 857.1, 858.1, 859.1, 860.1, 861.1,
      862.1, 863.1, 864.0, 865.0, 866.0, 867.0, 868.0, 869.0, 870.0, 871.0,
      874.0, 875.0, 876.0, 877.0, 878.0, 879.0, 880.0, 881.0, 882.0, 883.0,
      884.0, 885.0, 886.0, 887.0, 888.0, 889.0, 890.0, 891.0, 892.0, 893.0,
      894.0, 895.0, 896.0, 897.0, 898.0, 899.0, 900.0, 901.0,
    ],
  },
  {
    temp: 18.5,
    values: [
      703.2, 704.2, 705.2, 706.2, 707.2, 708.2, 709.2, 710.2, 711.2, 712.2,
      713.2, 714.2, 715.2, 716.2, 717.2, 718.2, 719.2, 720.2, 721.2, 722.2,
      723.2, 724.2, 725.2, 726.1, 727.1, 728.1, 729.1, 730.1, 731.1, 732.1,
      733.1, 734.1, 735.1, 736.1, 737.1, 738.1, 739.1, 740.1, 741.1, 742.1,
      743.1, 744.1, 745.1, 746.1, 747.1, 748.1, 749.1, 750.1, 751.1, 752.1,
      753.1, 754.1, 755.1, 756.1, 757.1, 758.1, 759.1, 760.1, 761.1, 762.1,
      763.1, 764.1, 765.1, 766.1, 767.1, 768.1, 769.1, 770.1, 771.0, 772.0,
      773.0, 774.0, 774.9, 775.9, 776.9, 777.8, 778.8, 779.8, 780.8, 781.7,
      782.7, 783.7, 784.7, 785.6, 786.6, 787.6, 788.6, 789.6, 790.6, 791.6,
      792.6, 793.6, 794.6, 795.6, 796.6, 797.6, 798.5, 799.5, 800.5, 801.5,
      802.5, 803.5, 804.5, 805.5, 806.5, 807.5, 808.5, 809.5, 810.5, 811.5,
      812.5, 813.5, 814.5, 815.5, 816.5, 817.5, 818.5, 819.5, 820.5, 821.5,
      822.5, 823.5, 824.5, 825.5, 826.5, 827.4, 828.4, 829.4, 830.4, 831.4,
      832.4, 833.4, 834.4, 835.4, 836.4, 837.4, 838.4, 839.4, 840.4, 841.4,
      842.4, 843.4, 844.4, 845.4, 846.4, 847.4, 848.4, 849.4, 850.4, 851.4,
      852.4, 853.4, 854.4, 855.4, 856.4, 857.4, 858.4, 859.4, 860.4, 861.4,
      862.4, 863.4, 864.4, 865.4, 866.4, 867.4, 868.4, 869.4, 870.4, 871.4,
      874.4, 875.4, 876.4, 877.4, 878.4, 879.4, 880.4, 881.4, 882.4, 883.4,
      884.4, 885.4, 886.4, 887.4, 888.4, 889.4, 890.4, 891.4, 892.4, 893.4,
      894.4, 895.4, 896.4, 897.4, 898.4, 899.4, 900.4, 901.4,
    ],
  },
  {
    temp: 19.0,
    values: [
      703.7, 704.7, 705.7, 706.7, 707.7, 708.6, 709.6, 710.6, 711.6, 712.6,
      713.6, 714.6, 715.6, 716.6, 717.6, 718.6, 719.6, 720.6, 721.6, 722.6,
      723.6, 724.6, 725.6, 726.6, 727.6, 728.6, 729.6, 730.6, 731.6, 732.6,
      733.6, 734.6, 735.6, 736.6, 737.6, 738.6, 739.6, 740.6, 741.6, 742.6,
      743.6, 744.6, 745.5, 746.5, 747.5, 748.5, 749.5, 750.5, 751.5, 752.5,
      753.5, 754.5, 755.5, 756.5, 757.5, 758.5, 759.5, 760.5, 761.5, 762.5,
      763.5, 764.5, 765.5, 766.5, 767.5, 768.5, 769.5, 770.5, 771.5, 772.4,
      773.4, 774.4, 775.3, 776.3, 777.3, 778.2, 779.2, 780.2, 781.1, 782.1,
      783.1, 784.1, 785.0, 786.0, 787.0, 788.0, 788.9, 789.9, 790.9, 791.9,
      792.9, 793.9, 794.9, 795.9, 796.9, 797.9, 798.9, 799.9, 800.9, 801.9,
      802.9, 803.9, 804.9, 805.9, 806.9, 807.9, 808.9, 809.9, 810.9, 811.9,
      812.9, 813.9, 814.9, 815.8, 816.8, 817.8, 818.8, 819.8, 820.8, 821.8,
      822.8, 823.8, 824.8, 825.8, 826.8, 827.8, 828.8, 829.8, 830.8, 831.8,
      832.8, 833.8, 834.8, 835.8, 836.8, 837.8, 838.8, 839.8, 840.8, 841.8,
      842.8, 843.8, 844.8, 845.8, 846.8, 847.7, 848.7, 849.7, 850.7, 851.7,
      852.7, 853.7, 854.7, 855.7, 856.7, 857.7, 858.7, 859.7, 860.7, 861.7,
      862.7, 863.7, 864.7, 865.7, 866.7, 867.7, 868.7, 869.7, 870.7, 871.7,
      874.7, 875.7, 876.7, 877.7, 878.7, 879.7, 880.7, 881.7, 882.7, 883.7,
      884.7, 885.7, 886.7, 887.7, 888.7, 889.7, 890.7, 891.7, 892.7, 893.7,
      894.7, 895.7, 896.7, 897.7, 898.7, 899.7, 900.7, 901.7,
    ],
  },
  {
    temp: 19.5,
    values: [
      704.1, 705.1, 706.1, 707.1, 708.1, 709.1, 710.1, 711.1, 712.1, 713.1,
      714.1, 715.1, 716.1, 717.1, 718.1, 719.1, 720.1, 721.1, 722.1, 723.1,
      724.1, 725.1, 726.1, 727.0, 728.0, 729.0, 730.0, 731.0, 732.0, 733.0,
      734.0, 735.0, 736.0, 737.0, 738.0, 739.0, 740.0, 741.0, 742.0, 743.0,
      744.0, 745.0, 746.0, 747.0, 748.0, 749.0, 750.0, 751.0, 752.0, 753.0,
      754.0, 755.0, 756.0, 757.0, 758.0, 759.0, 760.0, 760.9, 761.9, 762.9,
      763.9, 764.9, 765.9, 766.9, 767.9, 768.9, 769.9, 770.9, 771.9, 772.8,
      773.8, 774.8, 775.7, 776.7, 777.7, 778.6, 779.6, 780.6, 781.5, 782.5,
      783.5, 784.4, 785.4, 786.4, 787.3, 788.3, 789.3, 790.3, 791.3, 792.3,
      793.3, 794.3, 795.3, 796.3, 797.3, 798.3, 799.3, 800.3, 801.3, 802.3,
      803.3, 804.3, 805.2, 806.2, 807.2, 808.2, 809.2, 810.2, 811.2, 812.2,
      813.2, 814.2, 815.2, 816.2, 817.2, 818.2, 819.2, 820.2, 821.2, 822.2,
      823.2, 824.2, 825.2, 826.2, 827.1, 828.1, 829.1, 830.1, 831.1, 832.1,
      833.1, 834.1, 835.1, 836.1, 837.1, 838.1, 839.1, 840.1, 841.1, 842.1,
      843.1, 844.1, 845.1, 846.1, 847.1, 848.1, 849.1, 850.1, 851.1, 852.1,
      853.1, 854.1, 855.1, 856.1, 857.1, 858.1, 859.1, 860.1, 861.1, 862.1,
      863.1, 864.1, 865.1, 866.1, 867.1, 868.1, 869.1, 870.1, 871.1, 872.1,
      875.1, 876.1, 877.1, 878.1, 879.1, 880.1, 881.1, 882.1, 883.1, 884.1,
      885.1, 886.1, 887.0, 888.0, 889.0, 890.0, 891.0, 892.0, 893.0, 894.0,
      895.0, 896.0, 897.0, 898.0, 899.0, 900.0, 901.0, 902.0,
    ],
  },
  {
    temp: 20.0,
    values: [
      704.6, 705.6, 706.6, 707.6, 708.6, 709.6, 710.6, 711.6, 712.6, 713.6,
      714.5, 715.5, 716.5, 717.5, 718.5, 719.5, 720.5, 721.5, 722.5, 723.5,
      724.5, 725.5, 726.5, 727.5, 728.5, 729.5, 730.5, 731.5, 732.5, 733.5,
      734.5, 735.5, 736.5, 737.5, 738.5, 739.5, 740.5, 741.4, 742.4, 743.4,
      744.4, 745.4, 746.4, 747.4, 748.4, 749.4, 750.4, 751.4, 752.4, 753.4,
      754.4, 755.4, 756.4, 757.4, 758.4, 759.4, 760.4, 761.4, 762.4, 763.4,
      764.4, 765.4, 766.4, 767.4, 768.4, 769.4, 770.4, 771.3, 772.3, 773.2,
      774.2, 775.2, 776.1, 777.1, 778.1, 779.0, 780.0, 780.9, 781.9, 782.9,
      783.8, 784.8, 785.8, 786.7, 787.7, 788.7, 789.7, 790.7, 791.7, 792.7,
      793.7, 794.7, 795.7, 796.6, 797.6, 798.6, 799.6, 800.6, 801.6, 802.6,
      803.6, 804.6, 805.6, 806.6, 807.6, 808.6, 809.6, 810.6, 811.6, 812.6,
      813.6, 814.6, 815.6, 816.6, 817.6, 818.5, 819.5, 820.5, 821.5, 822.5,
      823.5, 824.5, 825.5, 826.5, 827.5, 828.5, 829.5, 830.5, 831.5, 832.5,
      833.5, 834.5, 835.5, 836.5, 837.5, 838.4, 839.4, 840.4, 841.4, 842.4,
      843.4, 844.4, 845.4, 846.4, 847.4, 848.4, 849.4, 850.4, 851.4, 852.4,
      853.4, 854.4, 855.4, 856.4, 857.4, 858.4, 859.4, 860.4, 861.4, 862.4,
      863.4, 864.4, 865.4, 866.4, 867.4, 868.4, 869.4, 870.4, 871.4, 872.4,
      875.4, 876.4, 877.4, 878.4, 879.4, 880.4, 881.4, 882.4, 883.4, 884.4,
      885.4, 886.4, 887.4, 888.4, 889.4, 890.4, 891.4, 892.4, 893.4, 894.4,
      895.4, 896.4, 897.4, 898.4, 899.4, 900.4, 901.4, 902.4,
    ],
  },
  {
    temp: 20.5,
    values: [
      705.0, 706.0, 707.0, 708.0, 709.0, 710.0, 711.0, 712.0, 713.0, 714.0,
      715.0, 716.0, 717.0, 718.0, 719.0, 720.0, 721.0, 722.0, 723.0, 724.0,
      725.0, 726.0, 727.0, 728.0, 728.9, 729.9, 730.9, 731.9, 732.9, 733.9,
      734.9, 735.9, 736.9, 737.9, 738.9, 739.9, 740.9, 741.9, 742.9, 743.9,
      744.9, 745.9, 746.9, 747.9, 748.9, 749.9, 750.9, 751.9, 752.9, 753.8,
      754.8, 755.8, 756.8, 757.8, 758.8, 759.8, 760.8, 761.8, 762.8, 763.8,
      764.8, 765.8, 766.8, 767.8, 768.8, 769.8, 770.8, 771.7, 772.7, 773.7,
      774.6, 775.6, 776.5, 777.5, 778.4, 779.4, 780.4, 781.3, 782.3, 783.2,
      784.2, 785.2, 786.1, 787.1, 788.1, 789.1, 790.0, 791.0, 792.0, 793.0,
      794.0, 795.0, 796.0, 797.0, 798.0, 799.0, 800.0, 801.0, 802.0, 803.0,
      804.0, 805.0, 806.0, 807.0, 808.0, 808.9, 809.9, 810.9, 811.9, 812.9,
      813.9, 814.9, 815.9, 816.9, 817.9, 818.9, 819.9, 820.9, 821.9, 822.9,
      823.9, 824.9, 825.9, 826.9, 827.9, 828.9, 829.8, 830.8, 831.8, 832.8,
      833.8, 834.8, 835.8, 836.8, 837.8, 838.8, 839.8, 840.8, 841.8, 842.8,
      843.8, 844.8, 845.8, 846.8, 847.8, 848.8, 849.8, 850.8, 851.8, 852.8,
      853.8, 854.8, 855.8, 856.8, 857.8, 858.8, 859.8, 860.8, 861.8, 862.8,
      863.8, 864.8, 865.8, 866.8, 867.8, 868.8, 869.8, 870.8, 871.7, 872.7,
      875.7, 876.7, 877.7, 878.7, 879.7, 880.7, 881.7, 882.7, 883.7, 884.7,
      885.7, 886.7, 887.7, 888.7, 889.7, 890.7, 891.7, 892.7, 893.7, 894.7,
      895.7, 896.7, 897.7, 898.7, 899.7, 900.7, 901.7, 902.7,
    ],
  },
  {
    temp: 21.0,
    values: [
      705.5, 706.5, 707.5, 708.5, 709.5, 710.5, 711.5, 712.5, 713.5, 714.5,
      715.5, 716.4, 717.4, 718.4, 719.4, 720.4, 721.4, 722.4, 723.4, 724.4,
      725.4, 726.4, 727.4, 728.4, 729.4, 730.4, 731.4, 732.4, 733.4, 734.4,
      735.4, 736.4, 737.4, 738.4, 739.4, 740.4, 741.3, 742.3, 743.3, 744.3,
      745.3, 746.3, 747.3, 748.3, 749.3, 750.3, 751.3, 752.3, 753.3, 754.3,
      755.3, 756.3, 757.3, 758.3, 759.3, 760.3, 761.3, 762.3, 763.3, 764.3,
      765.2, 766.2, 767.2, 768.2, 769.2, 770.2, 771.2, 772.1, 773.1, 774.1,
      775.0, 776.0, 776.9, 777.9, 778.8, 779.8, 780.7, 781.7, 782.7, 783.6,
      784.6, 785.5, 786.5, 787.4, 788.4, 789.4, 790.4, 791.4, 792.4, 793.4,
      794.4, 795.4, 796.4, 797.4, 798.4, 799.4, 800.4, 801.3, 802.3, 803.3,
      804.3, 805.3, 806.3, 807.3, 808.3, 809.3, 810.3, 811.3, 812.3, 813.3,
      814.3, 815.3, 816.3, 817.3, 818.3, 819.3, 820.2, 821.2, 822.2, 823.2,
      824.2, 825.2, 826.2, 827.2, 828.2, 829.2, 830.2, 831.2, 832.2, 833.2,
      834.2, 835.2, 836.2, 837.1, 838.1, 839.1, 840.1, 841.1, 842.1, 843.1,
      844.1, 845.1, 846.1, 847.1, 848.1, 849.1, 850.1, 851.1, 852.1, 853.1,
      854.1, 855.1, 856.1, 857.1, 858.1, 859.1, 860.1, 861.1, 862.1, 863.1,
      864.1, 865.1, 866.1, 867.1, 868.1, 869.1, 870.1, 871.1, 872.1, 873.1,
      876.1, 877.1, 878.1, 879.1, 880.1, 881.1, 882.1, 883.1, 884.1, 885.1,
      886.1, 887.1, 888.1, 889.1, 890.1, 891.1, 892.1, 893.1, 894.1, 895.1,
      896.1, 897.1, 898.1, 899.1, 900.0, 901.0, 902.0, 903.0,
    ],
  },
  {
    temp: 21.5,
    values: [
      705.9, 706.9, 707.9, 708.9, 709.9, 710.9, 711.9, 712.9, 713.9, 714.9,
      715.9, 716.9, 717.9, 718.9, 719.9, 720.9, 721.9, 722.9, 723.9, 724.9,
      725.9, 726.9, 727.9, 728.8, 729.8, 730.8, 731.8, 732.8, 733.8, 734.8,
      735.8, 736.8, 737.8, 738.8, 739.8, 740.8, 741.8, 742.8, 743.8, 744.8,
      745.8, 746.8, 747.8, 748.8, 749.8, 750.8, 751.8, 752.7, 753.7, 754.7,
      755.7, 756.7, 757.7, 758.7, 759.7, 760.7, 761.7, 762.7, 763.7, 764.7,
      765.7, 766.7, 767.7, 768.7, 769.7, 770.7, 771.6, 772.6, 773.5, 774.5,
      775.4, 776.4, 777.3, 778.3, 779.2, 780.2, 781.1, 782.1, 783.0, 784.0,
      784.9, 785.9, 786.8, 787.8, 788.8, 789.8, 790.8, 791.8, 792.8, 793.8,
      794.8, 795.7, 796.7, 797.7, 798.7, 799.7, 800.7, 801.7, 802.7, 803.7,
      804.7, 805.7, 806.7, 807.7, 808.7, 809.7, 810.7, 811.6, 812.6, 813.6,
      814.6, 815.6, 816.6, 817.6, 818.6, 819.6, 820.6, 821.6, 822.6, 823.6,
      824.6, 825.6, 826.6, 827.6, 828.6, 829.5, 830.5, 831.5, 832.5, 833.5,
      834.5, 835.5, 836.5, 837.5, 838.5, 839.5, 840.5, 841.5, 842.5, 843.5,
      844.5, 845.5, 846.5, 847.5, 848.5, 849.5, 850.5, 851.5, 852.5, 853.5,
      854.5, 855.5, 856.5, 857.5, 858.5, 859.5, 860.5, 861.4, 862.4, 863.4,
      864.4, 865.4, 866.4, 867.4, 868.4, 869.4, 870.4, 871.4, 872.4, 873.4,
      876.4, 877.4, 878.4, 879.4, 880.4, 881.4, 882.4, 883.4, 884.4, 885.4,
      886.4, 887.4, 888.4, 889.4, 890.4, 891.4, 892.4, 893.4, 894.4, 895.4,
      896.4, 897.4, 898.4, 899.4, 900.4, 901.4, 902.4, 903.4,
    ],
  },
  {
    temp: 22.0,
    values: [
      706.4, 707.4, 708.4, 709.4, 710.4, 711.4, 712.4, 713.4, 714.4, 715.4,
      716.4, 717.3, 718.3, 719.3, 720.3, 721.3, 722.3, 723.3, 724.3, 725.3,
      726.3, 727.3, 728.3, 729.3, 730.3, 731.3, 732.3, 733.3, 734.3, 735.3,
      736.3, 737.3, 738.3, 739.2, 740.2, 741.2, 742.2, 743.2, 744.2, 745.2,
      746.2, 747.2, 748.2, 749.2, 750.2, 751.2, 752.2, 753.2, 754.2, 755.2,
      756.2, 757.2, 758.2, 759.2, 760.2, 761.2, 762.1, 763.1, 764.1, 765.1,
      766.1, 767.1, 768.1, 769.1, 770.1, 771.1, 772.0, 773.0, 773.9, 774.9,
      775.8, 776.8, 777.7, 778.6, 779.6, 780.5, 781.5, 782.4, 783.4, 784.3,
      785.3, 786.2, 787.2, 788.2, 789.2, 790.1, 791.1, 792.1, 793.1, 794.1,
      795.1, 796.1, 797.1, 798.1, 799.1, 800.1, 801.1, 802.1, 803.1, 804.1,
      805.0, 806.0, 807.0, 808.0, 809.0, 810.0, 811.0, 812.0, 813.0, 814.0,
      815.0, 816.0, 817.0, 818.0, 819.0, 820.0, 820.9, 821.9, 822.9, 823.9,
      824.9, 825.9, 826.9, 827.9, 828.9, 829.9, 830.9, 831.9, 832.9, 833.9,
      834.9, 835.9, 836.9, 837.8, 838.8, 839.8, 840.8, 841.8, 842.8, 843.8,
      844.8, 845.8, 846.8, 847.8, 848.8, 849.8, 850.8, 851.8, 852.8, 853.8,
      854.8, 855.8, 856.8, 857.8, 858.8, 859.8, 860.8, 861.8, 862.8, 863.8,
      864.8, 865.8, 866.8, 867.8, 868.8, 869.8, 870.8, 871.8, 872.8, 873.8,
      876.8, 877.8, 878.8, 879.8, 880.8, 881.8, 882.8, 883.8, 884.8, 885.7,
      886.7, 887.7, 888.7, 889.7, 890.7, 891.7, 892.7, 893.7, 894.7, 895.7,
      896.7, 897.7, 898.7, 899.7, 900.7, 901.7, 902.7, 903.7,
    ],
  },
  {
    temp: 22.5,
    values: [
      706.9, 707.9, 708.9, 709.9, 710.8, 711.8, 712.8, 713.8, 714.8, 715.8,
      716.8, 717.8, 718.8, 719.8, 720.8, 721.8, 722.8, 723.8, 724.8, 725.8,
      726.8, 727.8, 728.7, 729.7, 730.7, 731.7, 732.7, 733.7, 734.7, 735.7,
      736.7, 737.7, 738.7, 739.7, 740.7, 741.7, 742.7, 743.7, 744.7, 745.7,
      746.7, 747.7, 748.6, 749.6, 750.6, 751.6, 752.6, 753.6, 754.6, 755.6,
      756.6, 757.6, 758.6, 759.6, 760.6, 761.6, 762.6, 763.6, 764.6, 765.6,
      766.6, 767.6, 768.6, 769.6, 770.5, 771.5, 772.4, 773.4, 774.3, 775.3,
      776.2, 777.1, 778.1, 779.0, 780.0, 780.9, 781.9, 782.8, 783.8, 784.7,
      785.6, 786.6, 787.5, 788.5, 789.5, 790.5, 791.5, 792.5, 793.5, 794.5,
      795.5, 796.5, 797.5, 798.5, 799.4, 800.4, 801.4, 802.4, 803.4, 804.4,
      805.4, 806.4, 807.4, 808.4, 809.4, 810.4, 811.4, 812.4, 813.4, 814.3,
      815.3, 816.3, 817.3, 818.3, 819.3, 820.3, 821.3, 822.3, 823.3, 824.3,
      825.3, 826.3, 827.3, 828.3, 829.2, 830.2, 831.2, 832.2, 833.2, 834.2,
      835.2, 836.2, 837.2, 838.2, 839.2, 840.2, 841.2, 842.2, 843.2, 844.2,
      845.2, 846.2, 847.2, 848.2, 849.2, 850.2, 851.2, 852.2, 853.1, 854.1,
      855.1, 856.1, 857.1, 858.1, 859.1, 860.1, 861.1, 862.1, 863.1, 864.1,
      865.1, 866.1, 867.1, 868.1, 869.1, 870.1, 871.1, 872.1, 873.1, 874.1,
      877.1, 878.1, 879.1, 880.1, 881.1, 882.1, 883.1, 884.1, 885.1, 886.1,
      887.1, 888.1, 889.1, 890.1, 891.1, 892.1, 893.1, 894.1, 895.1, 896.1,
      897.1, 898.1, 899.1, 900.1, 901.1, 902.1, 903.0, 904.0,
    ],
  },
  {
    temp: 23.0,
    values: [
      707.3, 708.3, 709.3, 710.3, 711.3, 712.3, 713.3, 714.3, 715.3, 716.3,
      717.3, 718.3, 719.3, 720.3, 721.2, 722.2, 723.2, 724.2, 725.2, 726.2,
      727.2, 728.2, 729.2, 730.2, 731.2, 732.2, 733.2, 734.2, 735.2, 736.2,
      737.2, 738.1, 739.1, 740.1, 741.1, 742.1, 743.1, 744.1, 745.1, 746.1,
      747.1, 748.1, 749.1, 750.1, 751.1, 752.1, 753.1, 754.1, 755.1, 756.1,
      757.1, 758.0, 759.0, 760.0, 761.0, 762.0, 763.0, 764.0, 765.0, 766.0,
      767.0, 768.0, 769.0, 770.0, 770.9, 771.9, 772.8, 773.8, 774.7, 775.7,
      776.6, 777.5, 778.5, 779.4, 780.4, 781.3, 782.2, 783.2, 784.1, 785.1,
      786.0, 786.9, 787.9, 788.9, 789.9, 790.9, 791.9, 792.9, 793.9, 794.8,
      795.8, 796.8, 797.8, 798.8, 799.8, 800.8, 801.8, 802.8, 803.8, 804.8,
      805.8, 806.8, 807.8, 808.7, 809.7, 810.7, 811.7, 812.7, 813.7, 814.7,
      815.7, 816.7, 817.7, 818.7, 819.7, 820.7, 821.7, 822.6, 823.6, 824.6,
      825.6, 826.6, 827.6, 828.6, 829.6, 830.6, 831.6, 832.6, 833.6, 834.6,
      835.6, 836.5, 837.5, 838.5, 839.5, 840.5, 841.5, 842.5, 843.5, 844.5,
      845.5, 846.5, 847.5, 848.5, 849.5, 850.5, 851.5, 852.5, 853.5, 854.5,
      855.5, 856.5, 857.5, 858.5, 859.5, 860.5, 861.5, 862.5, 863.5, 864.5,
      865.5, 866.5, 867.5, 868.5, 869.5, 870.5, 871.5, 872.5, 873.5, 874.5,
      877.4, 878.4, 879.4, 880.4, 881.4, 882.4, 883.4, 884.4, 885.4, 886.4,
      887.4, 888.4, 889.4, 890.4, 891.4, 892.4, 893.4, 894.4, 895.4, 896.4,
      897.4, 898.4, 899.4, 900.4, 901.4, 902.4, 903.4, 904.4,
    ],
  },
  {
    temp: 23.5,
    values: [
      707.8, 708.8, 709.8, 710.8, 711.8, 712.7, 713.7, 714.7, 715.7, 716.7,
      717.7, 718.7, 719.7, 720.7, 721.7, 722.7, 723.7, 724.7, 725.7, 726.7,
      727.7, 728.7, 729.6, 730.6, 731.6, 732.6, 733.6, 734.6, 735.6, 736.6,
      737.6, 738.6, 739.6, 740.6, 741.6, 742.6, 743.6, 744.6, 745.6, 746.5,
      747.5, 748.5, 749.5, 750.5, 751.5, 752.5, 753.5, 754.5, 755.5, 756.5,
      757.5, 758.5, 759.5, 760.5, 761.5, 762.5, 763.5, 764.5, 765.4, 766.4,
      767.4, 768.4, 769.4, 770.4, 771.4, 772.3, 773.2, 774.2, 775.1, 776.0,
      777.0, 777.9, 778.9, 779.8, 780.7, 781.7, 782.6, 783.5, 784.5, 785.4,
      786.4, 787.3, 788.3, 789.3, 790.3, 791.3, 792.2, 793.2, 794.2, 795.2,
      796.2, 797.2, 798.2, 799.2, 800.2, 801.2, 802.2, 803.1, 804.1, 805.1,
      806.1, 807.1, 808.1, 809.1, 810.1, 811.1, 812.1, 813.1, 814.1, 815.1,
      816.0, 817.0, 818.0, 819.0, 820.0, 821.0, 822.0, 823.0, 824.0, 825.0,
      826.0, 827.0, 828.0, 828.9, 829.9, 830.9, 831.9, 832.9, 833.9, 834.9,
      835.9, 836.9, 837.9, 838.9, 839.9, 840.9, 841.9, 842.9, 843.9, 844.9,
      845.9, 846.9, 847.8, 848.8, 849.8, 850.8, 851.8, 852.8, 853.8, 854.8,
      855.8, 856.8, 857.8, 858.8, 859.8, 860.8, 861.8, 862.8, 863.8, 864.8,
      865.8, 866.8, 867.8, 868.8, 869.8, 870.8, 871.8, 872.8, 873.8, 874.8,
      877.8, 878.8, 879.8, 880.8, 881.8, 882.8, 883.8, 884.8, 885.8, 886.8,
      887.8, 888.8, 889.8, 890.8, 891.8, 892.8, 893.8, 894.7, 895.7, 896.7,
      897.7, 898.7, 899.7, 900.7, 901.7, 902.7, 903.7, 904.7,
    ],
  },
  {
    temp: 24.0,
    values: [
      708.2, 709.2, 710.2, 711.2, 712.2, 713.2, 714.2, 715.2, 716.2, 717.2,
      718.2, 719.2, 720.2, 721.2, 722.1, 723.1, 724.1, 725.1, 726.1, 727.1,
      728.1, 729.1, 730.1, 731.1, 732.1, 733.1, 734.1, 735.1, 736.1, 737.1,
      738.0, 739.0, 740.0, 741.0, 742.0, 743.0, 744.0, 745.0, 746.0, 747.0,
      748.0, 749.0, 750.0, 751.0, 752.0, 753.0, 753.9, 754.9, 755.9, 756.9,
      757.9, 758.9, 759.9, 760.9, 761.9, 762.9, 763.9, 764.9, 765.9, 766.9,
      767.9, 768.9, 769.9, 770.8, 771.8, 772.7, 773.6, 774.6, 775.5, 776.4,
      777.4, 778.3, 779.2, 780.2, 781.1, 782.0, 783.0, 783.9, 784.8, 785.8,
      786.7, 787.7, 788.6, 789.6, 790.6, 791.6, 792.6, 793.6, 794.6, 795.6,
      796.6, 797.6, 798.5, 799.5, 800.5, 801.5, 802.5, 803.5, 804.5, 805.5,
      806.5, 807.5, 808.5, 809.5, 810.4, 811.4, 812.4, 813.4, 814.4, 815.4,
      816.4, 817.4, 818.4, 819.4, 820.4, 821.4, 822.4, 823.3, 824.3, 825.3,
      826.3, 827.3, 828.3, 829.3, 830.3, 831.3, 832.3, 833.3, 834.3, 835.3,
      836.2, 837.2, 838.2, 839.2, 840.2, 841.2, 842.2, 843.2, 844.2, 845.2,
      846.2, 847.2, 848.2, 849.2, 850.2, 851.2, 852.2, 853.2, 854.2, 855.2,
      856.2, 857.2, 858.2, 859.2, 860.2, 861.2, 862.2, 863.2, 864.2, 865.2,
      866.2, 867.1, 868.1, 869.1, 870.1, 871.1, 872.1, 873.1, 874.1, 875.1,
      878.1, 879.1, 880.1, 881.1, 882.1, 883.1, 884.1, 885.1, 886.1, 887.1,
      888.1, 889.1, 890.1, 891.1, 892.1, 893.1, 894.1, 895.1, 896.1, 897.1,
      898.1, 899.1, 900.1, 901.1, 902.1, 903.1, 904.1, 905.1,
    ],
  },
  {
    temp: 24.5,
    values: [
      708.7, 709.7, 710.7, 711.7, 712.7, 713.7, 714.6, 715.6, 716.6, 717.6,
      718.6, 719.6, 720.6, 721.6, 722.6, 723.6, 724.6, 725.6, 726.6, 727.6,
      728.6, 729.5, 730.5, 731.5, 732.5, 733.5, 734.5, 735.5, 736.5, 737.5,
      738.5, 739.5, 740.5, 741.5, 742.5, 743.5, 744.5, 745.5, 746.4, 747.4,
      748.4, 749.4, 750.4, 751.4, 752.4, 753.4, 754.4, 755.4, 756.4, 757.4,
      758.4, 759.4, 760.4, 761.3, 762.3, 763.3, 764.3, 765.3, 766.3, 767.3,
      768.3, 769.3, 770.3, 771.2, 772.2, 773.1, 774.0, 775.0, 775.9, 776.8,
      777.7, 778.7, 779.6, 780.5, 781.5, 782.4, 783.3, 784.3, 785.2, 786.1,
      787.1, 788.0, 789.0, 790.0, 791.0, 792.0, 793.0, 794.0, 795.0, 795.9,
      796.9, 797.9, 798.9, 799.9, 800.9, 801.9, 802.9, 803.9, 804.9, 805.8,
      806.8, 807.8, 808.8, 809.8, 810.8, 811.8, 812.8, 813.8, 814.8, 815.8,
      816.8, 817.7, 818.7, 819.7, 820.7, 821.7, 822.7, 823.7, 824.7, 825.7,
      826.7, 827.7, 828.7, 829.6, 830.6, 831.6, 832.6, 833.6, 834.6, 835.6,
      836.6, 837.6, 838.6, 839.6, 840.6, 841.6, 842.6, 843.6, 844.6, 845.6,
      846.5, 847.5, 848.5, 849.5, 850.5, 851.5, 852.5, 853.5, 854.5, 855.5,
      856.5, 857.5, 858.5, 859.5, 860.5, 861.5, 862.5, 863.5, 864.5, 865.5,
      866.5, 867.5, 868.5, 869.5, 870.5, 871.5, 872.5, 873.5, 874.5, 875.5,
      878.5, 879.5, 880.5, 881.5, 882.5, 883.5, 884.5, 885.4, 886.4, 887.4,
      888.4, 889.4, 890.4, 891.4, 892.4, 893.4, 894.4, 895.4, 896.4, 897.4,
      898.4, 899.4, 900.4, 901.4, 902.4, 903.4, 904.4, 905.4,
    ],
  },
  {
    temp: 25.0,
    values: [
      709.1, 710.1, 711.1, 712.1, 713.1, 714.1, 715.1, 716.1, 717.1, 718.1,
      719.1, 720.1, 721.1, 722.0, 723.0, 724.0, 725.0, 726.0, 727.0, 728.0,
      729.0, 730.0, 731.0, 732.0, 733.0, 734.0, 735.0, 736.0, 737.0, 737.9,
      738.9, 739.9, 740.9, 741.9, 742.9, 743.9, 744.9, 745.9, 746.9, 747.9,
      748.9, 749.9, 750.9, 751.9, 752.9, 753.8, 754.8, 755.8, 756.8, 757.8,
      758.8, 759.8, 760.8, 761.8, 762.8, 763.8, 764.8, 765.8, 766.8, 767.7,
      768.7, 769.7, 770.7, 771.6, 772.6, 773.5, 774.4, 775.3, 776.3, 777.2,
      778.1, 779.1, 780.0, 780.9, 781.8, 782.8, 783.7, 784.6, 785.5, 786.5,
      787.4, 788.4, 789.4, 790.4, 791.4, 792.3, 793.3, 794.3, 795.3, 796.3,
      797.3, 798.3, 799.3, 800.3, 801.3, 802.3, 803.2, 804.2, 805.2, 806.2,
      807.2, 808.2, 809.2, 810.2, 811.2, 812.1, 813.1, 814.1, 815.1, 816.1,
      817.1, 818.1, 819.1, 820.1, 821.1, 822.1, 823.1, 824.0, 825.0, 826.0,
      827.0, 828.0, 829.0, 830.0, 831.0, 832.0, 833.0, 834.0, 834.9, 835.9,
      836.9, 837.9, 838.9, 839.9, 840.9, 841.9, 842.9, 843.9, 844.9, 845.9,
      846.9, 847.9, 848.9, 849.9, 850.9, 851.9, 852.9, 853.9, 854.9, 855.9,
      856.9, 857.9, 858.9, 859.9, 860.9, 861.8, 862.8, 863.8, 864.8, 865.8,
      866.8, 867.8, 868.8, 869.8, 870.8, 871.8, 872.8, 873.8, 874.8, 875.8,
      878.8, 879.8, 880.8, 881.8, 882.8, 883.8, 884.8, 885.8, 886.8, 887.8,
      888.8, 889.8, 890.8, 891.8, 892.8, 893.8, 894.8, 895.8, 896.8, 897.8,
      898.7, 899.7, 900.7, 901.7, 902.7, 903.7, 904.7, 905.7,
    ],
  },
  {
    temp: 25.5,
    values: [
      709.6, 710.6, 711.6, 712.6, 713.6, 714.6, 715.6, 716.6, 717.5, 718.5,
      719.5, 720.5, 721.5, 722.5, 723.5, 724.5, 725.5, 726.5, 727.5, 728.5,
      729.5, 730.4, 731.4, 732.4, 733.4, 734.4, 735.4, 736.4, 737.4, 738.4,
      739.4, 740.4, 741.4, 742.4, 743.4, 744.3, 745.3, 746.3, 747.3, 748.3,
      749.3, 750.3, 751.3, 752.3, 753.3, 754.3, 755.3, 756.3, 757.3, 758.3,
      759.3, 760.2, 761.2, 762.2, 763.2, 764.2, 765.2, 766.2, 767.2, 768.2,
      769.2, 770.2, 771.1, 772.0, 773.0, 773.9, 774.8, 775.7, 776.7, 777.6,
      778.5, 779.4, 780.3, 781.3, 782.2, 783.1, 784.0, 785.0, 785.9, 786.8,
      787.8, 788.7, 789.7, 790.7, 791.7, 792.7, 793.7, 794.7, 795.7, 796.7,
      797.7, 798.6, 799.6, 800.6, 801.6, 802.6, 803.6, 804.6, 805.6, 806.6,
      807.6, 808.5, 809.5, 810.5, 811.5, 812.5, 813.5, 814.5, 815.5, 816.5,
      817.5, 818.4, 819.4, 820.4, 821.4, 822.4, 823.4, 824.4, 825.4, 826.4,
      827.4, 828.4, 829.3, 830.3, 831.3, 832.3, 833.3, 834.3, 835.3, 836.3,
      837.3, 838.3, 839.3, 840.3, 841.3, 842.3, 843.3, 844.2, 845.2, 846.2,
      847.2, 848.2, 849.2, 850.2, 851.2, 852.2, 853.2, 854.2, 855.2, 856.2,
      857.2, 858.2, 859.2, 860.2, 861.2, 862.2, 863.2, 864.2, 865.2, 866.2,
      867.2, 868.2, 869.2, 870.2, 871.2, 872.2, 873.2, 874.2, 875.2, 876.2,
      879.1, 880.1, 881.1, 882.1, 883.1, 884.1, 885.1, 886.1, 887.1, 888.1,
      889.1, 890.1, 891.1, 892.1, 893.1, 894.1, 895.1, 896.1, 897.1, 898.1,
      899.1, 900.1, 901.1, 902.1, 903.1, 904.1, 905.1, 906.1,
    ],
  },
  {
    temp: 26.0,
    values: [
      710.1, 711.0, 712.0, 713.0, 714.0, 715.0, 716.0, 717.0, 718.0, 719.0,
      720.0, 721.0, 722.0, 723.0, 724.0, 724.9, 725.9, 726.9, 727.9, 728.9,
      729.9, 730.9, 731.9, 732.9, 733.9, 734.9, 735.9, 736.8, 737.8, 738.8,
      739.8, 740.8, 741.8, 742.8, 743.8, 744.8, 745.8, 746.8, 747.8, 748.8,
      749.8, 750.8, 751.7, 752.7, 753.7, 754.7, 755.7, 756.7, 757.7, 758.7,
      759.7, 760.7, 761.7, 762.7, 763.7, 764.7, 765.7, 766.6, 767.6, 768.6,
      769.6, 770.6, 771.5, 772.4, 773.3, 774.3, 775.2, 776.1, 777.0, 778.0,
      778.9, 779.8, 780.7, 781.6, 782.6, 783.5, 784.4, 785.3, 786.2, 787.2,
      788.1, 789.1, 790.1, 791.1, 792.1, 793.1, 794.1, 795.1, 796.0, 797.0,
      798.0, 799.0, 800.0, 801.0, 802.0, 803.0, 804.0, 804.9, 805.9, 806.9,
      807.9, 808.9, 809.9, 810.9, 811.9, 812.9, 813.8, 814.8, 815.8, 816.8,
      817.8, 818.8, 819.8, 820.8, 821.8, 822.8, 823.8, 824.7, 825.7, 826.7,
      827.7, 828.7, 829.7, 830.7, 831.7, 832.7, 833.7, 834.6, 835.6, 836.6,
      837.6, 838.6, 839.6, 840.6, 841.6, 842.6, 843.6, 844.6, 845.6, 846.6,
      847.6, 848.6, 849.6, 850.6, 851.6, 852.6, 853.6, 854.6, 855.6, 856.6,
      857.5, 858.5, 859.5, 860.5, 861.5, 862.5, 863.5, 864.5, 865.5, 866.5,
      867.5, 868.5, 869.5, 870.5, 871.5, 872.5, 873.5, 874.5, 875.5, 876.5,
      879.5, 880.5, 881.5, 882.5, 883.5, 884.5, 885.5, 886.5, 887.5, 888.5,
      889.5, 890.4, 891.4, 892.4, 893.4, 894.4, 895.4, 896.4, 897.4, 898.4,
      899.4, 900.4, 901.4, 902.4, 903.4, 904.4, 905.4, 906.4,
    ],
  },
  {
    temp: 26.5,
    values: [
      710.5, 711.5, 712.5, 713.5, 714.5, 715.5, 716.5, 717.5, 718.4, 719.4,
      720.4, 721.4, 722.4, 723.4, 724.4, 725.4, 726.4, 727.4, 728.4, 729.4,
      730.4, 731.3, 732.3, 733.3, 734.3, 735.3, 736.3, 737.3, 738.3, 739.3,
      740.3, 741.3, 742.3, 743.2, 744.2, 745.2, 746.2, 747.2, 748.2, 749.2,
      750.2, 751.2, 752.2, 753.2, 754.2, 755.2, 756.2, 757.1, 758.1, 759.1,
      760.1, 761.1, 762.1, 763.1, 764.1, 765.1, 766.1, 767.1, 768.1, 769.1,
      770.1, 771.0, 771.9, 772.8, 773.7, 774.7, 775.6, 776.5, 777.4, 778.3,
      779.2, 780.2, 781.1, 782.0, 782.9, 783.8, 784.7, 785.7, 786.6, 787.5,
      788.5, 789.5, 790.5, 791.5, 792.4, 793.4, 794.4, 795.4, 796.4, 797.4,
      798.4, 799.4, 800.4, 801.3, 802.3, 803.3, 804.3, 805.3, 806.3, 807.3,
      808.3, 809.3, 810.3, 811.2, 812.2, 813.2, 814.2, 815.2, 816.2, 817.2,
      818.2, 819.2, 820.1, 821.1, 822.1, 823.1, 824.1, 825.1, 826.1, 827.1,
      828.1, 829.0, 830.0, 831.0, 832.0, 833.0, 834.0, 835.0, 836.0, 837.0,
      838.0, 839.0, 840.0, 841.0, 841.9, 842.9, 843.9, 844.9, 845.9, 846.9,
      847.9, 848.9, 849.9, 850.9, 851.9, 852.9, 853.9, 854.9, 855.9, 856.9,
      857.9, 858.9, 859.9, 860.9, 861.9, 862.9, 863.9, 864.9, 865.9, 866.9,
      867.9, 868.9, 869.9, 870.9, 871.8, 872.8, 873.8, 874.8, 875.8, 876.8,
      879.8, 880.8, 881.8, 882.8, 883.8, 884.8, 885.8, 886.8, 887.8, 888.8,
      889.8, 890.8, 891.8, 892.8, 893.8, 894.8, 895.8, 896.8, 897.8, 898.8,
      899.8, 900.8, 901.8, 902.8, 903.8, 904.7, 905.7, 906.7,
    ],
  },
  {
    temp: 27.0,
    values: [
      711.0, 712.0, 712.9, 713.9, 714.9, 715.9, 716.9, 717.9, 718.9, 719.9,
      720.9, 721.9, 722.9, 723.9, 724.8, 725.8, 726.8, 727.8, 728.8, 729.8,
      730.8, 731.8, 732.8, 733.8, 734.8, 735.8, 736.8, 737.7, 738.7, 739.7,
      740.7, 741.7, 742.7, 743.7, 744.7, 745.7, 746.7, 747.7, 748.7, 749.6,
      750.6, 751.6, 752.6, 753.6, 754.6, 755.6, 756.6, 757.6, 758.6, 759.6,
      760.6, 761.6, 762.6, 763.5, 764.5, 765.5, 766.5, 767.5, 768.5, 769.5,
      770.5, 771.4, 772.3, 773.2, 774.1, 775.0, 776.0, 776.9, 777.8, 778.7,
      779.6, 780.5, 781.4, 782.4, 783.3, 784.2, 785.1, 786.0, 786.9, 787.9,
      788.9, 789.8, 790.8, 791.8, 792.8, 793.8, 794.8, 795.8, 796.8, 797.8,
      798.7, 799.7, 800.7, 801.7, 802.7, 803.7, 804.7, 805.7, 806.7, 807.6,
      808.6, 809.6, 810.6, 811.6, 812.6, 813.6, 814.6, 815.5, 816.5, 817.5,
      818.5, 819.5, 820.5, 821.5, 822.5, 823.5, 824.5, 825.4, 826.4, 827.4,
      828.4, 829.4, 830.4, 831.4, 832.4, 833.4, 834.3, 835.3, 836.3, 837.3,
      838.3, 839.3, 840.3, 841.3, 842.3, 843.3, 844.3, 845.3, 846.3, 847.3,
      848.3, 849.3, 850.3, 851.3, 852.3, 853.2, 854.2, 855.2, 856.2, 857.2,
      858.2, 859.2, 860.2, 861.2, 862.2, 863.2, 864.2, 865.2, 866.2, 867.2,
      868.2, 869.2, 870.2, 871.2, 872.2, 873.2, 874.2, 875.2, 876.2, 877.2,
      880.2, 881.2, 882.2, 883.2, 884.2, 885.2, 886.2, 887.1, 888.1, 889.1,
      890.1, 891.1, 892.1, 893.1, 894.1, 895.1, 896.1, 897.1, 898.1, 899.1,
      900.1, 901.1, 902.1, 903.1, 904.1, 905.1, 906.1, 907.1,
    ],
  },
  {
    temp: 27.5,
    values: [
      711.4, 712.4, 713.4, 714.4, 715.4, 716.4, 717.4, 718.4, 719.3, 720.3,
      721.3, 722.3, 723.3, 724.3, 725.3, 726.3, 727.3, 728.3, 729.3, 730.3,
      731.2, 732.2, 733.2, 734.2, 735.2, 736.2, 737.2, 738.2, 739.2, 740.2,
      741.2, 742.2, 743.2, 744.1, 745.1, 746.1, 747.1, 748.1, 749.1, 750.1,
      751.1, 752.1, 753.1, 754.1, 755.0, 756.0, 757.0, 758.0, 759.0, 760.0,
      761.0, 762.0, 763.0, 764.0, 765.0, 766.0, 767.0, 768.0, 768.9, 769.9,
      770.9, 771.8, 772.7, 773.6, 774.5, 775.4, 776.3, 777.3, 778.2, 779.1,
      780.0, 780.9, 781.8, 782.7, 783.6, 784.5, 785.4, 786.4, 787.3, 788.2,
      789.2, 790.2, 791.2, 792.2, 793.2, 794.2, 795.1, 796.1, 797.1, 798.1,
      799.1, 800.1, 801.1, 802.1, 803.1, 804.0, 805.0, 806.0, 807.0, 808.0,
      809.0, 810.0, 811.0, 812.0, 812.9, 813.9, 814.9, 815.9, 816.9, 817.9,
      818.9, 819.9, 820.8, 821.8, 822.8, 823.8, 824.8, 825.8, 826.8, 827.8,
      828.8, 829.7, 830.7, 831.7, 832.7, 833.7, 834.7, 835.7, 836.7, 837.7,
      838.7, 839.6, 840.6, 841.6, 842.6, 843.6, 844.6, 845.6, 846.6, 847.6,
      848.6, 849.6, 850.6, 851.6, 852.6, 853.6, 854.6, 855.6, 856.6, 857.6,
      858.6, 859.6, 860.6, 861.6, 862.6, 863.6, 864.6, 865.6, 866.6, 867.5,
      868.5, 869.5, 870.5, 871.5, 872.5, 873.5, 874.5, 875.5, 876.5, 877.5,
      880.5, 881.5, 882.5, 883.5, 884.5, 885.5, 886.5, 887.5, 888.5, 889.5,
      890.5, 891.5, 892.5, 893.5, 894.5, 895.5, 896.4, 897.4, 898.4, 899.4,
      900.4, 901.4, 902.4, 903.4, 904.4, 905.4, 906.4, 907.4,
    ],
  },
  {
    temp: 28.0,
    values: [
      711.9, 712.9, 713.9, 714.9, 715.8, 716.8, 717.8, 718.8, 719.8, 720.8,
      721.8, 722.8, 723.8, 724.8, 725.7, 726.7, 727.7, 728.7, 729.7, 730.7,
      731.7, 732.7, 733.7, 734.7, 735.7, 736.7, 737.6, 738.6, 739.6, 740.6,
      741.6, 742.6, 743.6, 744.6, 745.6, 746.6, 747.6, 748.6, 749.5, 750.5,
      751.5, 752.5, 753.5, 754.5, 755.5, 756.5, 757.5, 758.5, 759.5, 760.4,
      761.4, 762.4, 763.4, 764.4, 765.4, 766.4, 767.4, 768.4, 769.4, 770.4,
      771.3, 772.2, 773.1, 774.0, 774.9, 775.8, 776.7, 777.6, 778.5, 779.4,
      780.3, 781.3, 782.2, 783.1, 784.0, 784.9, 785.8, 786.7, 787.6, 788.6,
      789.6, 790.6, 791.6, 792.5, 793.5, 794.5, 795.5, 796.5, 797.5, 798.5,
      799.5, 800.4, 801.4, 802.4, 803.4, 804.4, 805.4, 806.4, 807.4, 808.4,
      809.3, 810.3, 811.3, 812.3, 813.3, 814.3, 815.3, 816.3, 817.2, 818.2,
      819.2, 820.2, 821.2, 822.2, 823.2, 824.2, 825.1, 826.1, 827.1, 828.1,
      829.1, 830.1, 831.1, 832.1, 833.1, 834.0, 835.0, 836.0, 837.0, 838.0,
      839.0, 840.0, 841.0, 842.0, 843.0, 844.0, 845.0, 846.0, 847.0, 848.0,
      849.0, 850.0, 851.0, 852.0, 852.9, 853.9, 854.9, 855.9, 856.9, 857.9,
      858.9, 859.9, 860.9, 861.9, 862.9, 863.9, 864.9, 865.9, 866.9, 867.9,
      868.9, 869.9, 870.9, 871.9, 872.9, 873.9, 874.9, 875.9, 876.9, 877.9,
      880.8, 881.8, 882.8, 883.8, 884.8, 885.8, 886.8, 887.8, 888.8, 889.8,
      890.8, 891.8, 892.8, 893.8, 894.8, 895.8, 896.8, 897.8, 898.8, 899.8,
      900.8, 901.8, 902.8, 903.8, 904.8, 905.8, 906.8, 907.8,
    ],
  },
  {
    temp: 28.5,
    values: [
      712.3, 713.3, 714.3, 715.3, 716.3, 717.3, 718.3, 719.3, 720.3, 721.2,
      722.2, 723.2, 724.2, 725.2, 726.2, 727.2, 728.2, 729.2, 730.2, 731.2,
      732.1, 733.1, 734.1, 735.1, 736.1, 737.1, 738.1, 739.1, 740.1, 741.1,
      742.1, 743.0, 744.0, 745.0, 746.0, 747.0, 748.0, 749.0, 750.0, 751.0,
      752.0, 753.0, 754.0, 754.9, 755.9, 756.9, 757.9, 758.9, 759.9, 760.9,
      761.9, 762.9, 763.9, 764.9, 765.8, 766.8, 767.8, 768.8, 769.8, 770.8,
      771.7, 772.6, 773.5, 774.4, 775.3, 776.2, 777.1, 778.0, 778.9, 779.8,
      780.7, 781.6, 782.5, 783.4, 784.3, 785.2, 786.1, 787.0, 788.0, 789.0,
      790.0, 790.9, 791.9, 792.9, 793.9, 794.9, 795.9, 796.9, 797.8, 798.8,
      799.8, 800.8, 801.8, 802.8, 803.8, 804.8, 805.7, 806.7, 807.7, 808.7,
      809.7, 810.7, 811.7, 812.7, 813.6, 814.6, 815.6, 816.6, 817.6, 818.6,
      819.6, 820.6, 821.5, 822.5, 823.5, 824.5, 825.5, 826.5, 827.5, 828.5,
      829.5, 830.4, 831.4, 832.4, 833.4, 834.4, 835.4, 836.4, 837.4, 838.3,
      839.3, 840.3, 841.3, 842.3, 843.3, 844.3, 845.3, 846.3, 847.3, 848.3,
      849.3, 850.3, 851.3, 852.3, 853.3, 854.3, 855.3, 856.3, 857.3, 858.3,
      859.3, 860.3, 861.3, 862.3, 863.2, 864.2, 865.2, 866.2, 867.2, 868.2,
      869.2, 870.2, 871.2, 872.2, 873.2, 874.2, 875.2, 876.2, 877.2, 878.2,
      881.2, 882.2, 883.2, 884.2, 885.2, 886.2, 887.2, 888.2, 889.2, 890.1,
      891.1, 892.1, 893.1, 894.1, 895.1, 896.1, 897.1, 898.1, 899.1, 900.1,
      901.1, 902.1, 903.1, 904.1, 905.1, 906.1, 907.1, 908.1,
    ],
  },
  {
    temp: 29.0,
    values: [
      712.8, 713.8, 714.8, 715.8, 716.7, 717.7, 718.7, 719.7, 720.7, 721.7,
      722.7, 723.7, 724.7, 725.7, 726.7, 727.6, 728.6, 729.6, 730.6, 731.6,
      732.6, 733.6, 734.6, 735.6, 736.6, 737.5, 738.5, 739.5, 740.5, 741.5,
      742.5, 743.5, 744.5, 745.5, 746.5, 747.5, 748.4, 749.4, 750.4, 751.4,
      752.4, 753.4, 754.4, 755.4, 756.4, 757.4, 758.4, 759.4, 760.3, 761.3,
      762.3, 763.3, 764.3, 765.3, 766.3, 767.3, 768.3, 769.3, 770.2, 771.2,
      772.1, 773.0, 773.9, 774.8, 775.7, 776.6, 777.5, 778.4, 779.3, 780.2,
      781.1, 782.0, 782.9, 783.8, 784.7, 785.6, 786.5, 787.4, 788.4, 789.3,
      790.3, 791.3, 792.3, 793.3, 794.3, 795.2, 796.2, 797.2, 798.2, 799.2,
      800.2, 801.2, 802.2, 803.1, 804.1, 805.1, 806.1, 807.1, 808.1, 809.1,
      810.1, 811.0, 812.0, 813.0, 814.0, 815.0, 816.0, 817.0, 818.0, 818.9,
      819.9, 820.9, 821.9, 822.9, 823.9, 824.9, 825.8, 826.8, 827.8, 828.8,
      829.8, 830.8, 831.8, 832.8, 833.7, 834.7, 835.7, 836.7, 837.7, 838.7,
      839.7, 840.7, 841.7, 842.7, 843.7, 844.7, 845.7, 846.7, 847.7, 848.7,
      849.7, 850.6, 851.6, 852.6, 853.6, 854.6, 855.6, 856.6, 857.6, 858.6,
      859.6, 860.6, 861.6, 862.6, 863.6, 864.6, 865.6, 866.6, 867.6, 868.6,
      869.6, 870.6, 871.6, 872.6, 873.6, 874.6, 875.5, 876.5, 877.5, 878.5,
      881.5, 882.5, 883.5, 884.5, 885.5, 886.5, 887.5, 888.5, 889.5, 890.5,
      891.5, 892.5, 893.5, 894.5, 895.5, 896.5, 897.5, 898.5, 899.5, 900.5,
      901.5, 902.4, 903.4, 904.4, 905.4, 906.4, 907.4, 908.4,
    ],
  },
  {
    temp: 29.5,
    values: [
      713.2, 714.2, 715.2, 716.2, 717.2, 718.2, 719.2, 720.2, 721.2, 722.1,
      723.1, 724.1, 725.1, 726.1, 727.1, 728.1, 729.1, 730.1, 731.1, 732.1,
      733.0, 734.0, 735.0, 736.0, 737.0, 738.0, 739.0, 740.0, 741.0, 742.0,
      742.9, 743.9, 744.9, 745.9, 746.9, 747.9, 748.9, 749.9, 750.9, 751.9,
      752.8, 753.8, 754.8, 755.8, 756.8, 757.8, 758.8, 759.8, 760.8, 761.8,
      762.8, 763.8, 764.7, 765.7, 766.7, 767.7, 768.7, 769.7, 770.7, 771.5,
      772.4, 773.3, 774.2, 775.1, 776.0, 776.9, 777.8, 778.7, 779.6, 780.5,
      781.4, 782.3, 783.2, 784.1, 785.0, 785.9, 786.8, 787.7, 788.7, 789.7,
      790.7, 791.7, 792.7, 793.6, 794.6, 795.6, 796.6, 797.6, 798.6, 799.6,
      800.5, 801.5, 802.5, 803.5, 804.5, 805.5, 806.5, 807.4, 808.4, 809.4,
      810.4, 811.4, 812.4, 813.4, 814.4, 815.3, 816.3, 817.3, 818.3, 819.3,
      820.3, 821.3, 822.3, 823.2, 824.2, 825.2, 826.2, 827.2, 828.2, 829.2,
      830.1, 831.1, 832.1, 833.1, 834.1, 835.1, 836.1, 837.1, 838.0, 839.0,
      840.0, 841.0, 842.0, 843.0, 844.0, 845.0, 846.0, 847.0, 848.0, 849.0,
      850.0, 851.0, 852.0, 853.0, 854.0, 855.0, 856.0, 857.0, 858.0, 859.0,
      859.9, 860.9, 861.9, 862.9, 863.9, 864.9, 865.9, 866.9, 867.9, 868.9,
      869.9, 870.9, 871.9, 872.9, 873.9, 874.9, 875.9, 876.9, 877.9, 878.9,
      881.9, 882.9, 883.9, 884.8, 885.8, 886.8, 887.8, 888.8, 889.8, 890.8,
      891.8, 892.8, 893.8, 894.8, 895.8, 896.8, 897.8, 898.8, 899.8, 900.8,
      901.8, 902.8, 903.8, 904.8, 905.8, 906.8, 907.8, 908.8,
    ],
  },
  {
    temp: 30.0,
    values: [
      713.7, 714.7, 715.7, 716.7, 717.6, 718.6, 719.6, 720.6, 721.6, 722.6,
      723.6, 724.6, 725.6, 726.6, 727.5, 728.5, 729.5, 730.5, 731.5, 732.5,
      733.5, 734.5, 735.5, 736.5, 737.5, 738.4, 739.4, 740.4, 741.4, 742.4,
      743.4, 744.4, 745.4, 746.4, 747.3, 748.3, 749.3, 750.3, 751.3, 752.3,
      753.3, 754.3, 755.3, 756.3, 757.3, 758.2, 759.2, 760.2, 761.2, 762.2,
      763.2, 764.2, 765.2, 766.2, 767.2, 768.2, 769.1, 770.1, 771.1, 771.9,
      772.8, 773.7, 774.6, 775.5, 776.4, 777.3, 778.2, 779.1, 780.0, 780.9,
      781.8, 782.7, 783.6, 784.5, 785.4, 786.2, 787.1, 788.1, 789.1, 790.1,
      791.1, 792.0, 793.0, 794.0, 795.0, 796.0, 797.0, 797.9, 798.9, 799.9,
      800.9, 801.9, 802.9, 803.9, 804.8, 805.8, 806.8, 807.8, 808.8, 809.8,
      810.8, 811.8, 812.7, 813.7, 814.7, 815.7, 816.7, 817.7, 818.7, 819.6,
      820.6, 821.6, 822.6, 823.6, 824.6, 825.6, 826.5, 827.5, 828.5, 829.5,
      830.5, 831.5, 832.5, 833.5, 834.4, 835.4, 836.4, 837.4, 838.4, 839.4,
      840.4, 841.4, 842.4, 843.4, 844.4, 845.4, 846.4, 847.4, 848.3, 849.3,
      850.3, 851.3, 852.3, 853.3, 854.3, 855.3, 856.3, 857.3, 858.3, 859.3,
      860.3, 861.3, 862.3, 863.3, 864.3, 865.3, 866.3, 867.3, 868.3, 869.3,
      870.3, 871.2, 872.2, 873.2, 874.2, 875.2, 876.2, 877.2, 878.2, 879.2,
      882.2, 883.2, 884.2, 885.2, 886.2, 887.2, 888.2, 889.2, 890.2, 891.2,
      892.2, 893.2, 894.2, 895.2, 896.1, 897.1, 898.1, 899.1, 900.1, 901.1,
      902.1, 903.1, 904.1, 905.1, 906.1, 907.1, 908.1, 909.1,
    ],
  },
  {
    temp: 30.5,
    values: [
      714.2, 715.1, 716.1, 717.1, 718.1, 719.1, 720.1, 721.1, 722.1, 723.0,
      724.0, 725.0, 726.0, 727.0, 728.0, 729.0, 730.0, 731.0, 732.0, 732.9,
      733.9, 734.9, 735.9, 736.9, 737.9, 738.9, 739.9, 740.9, 741.9, 742.8,
      743.8, 744.8, 745.8, 746.8, 747.8, 748.8, 749.8, 750.8, 751.7, 752.7,
      753.7, 754.7, 755.7, 756.7, 757.7, 758.7, 759.7, 760.7, 761.7, 762.6,
      763.6, 764.6, 765.6, 766.6, 767.6, 768.6, 769.6, 770.5, 771.4, 772.3,
      773.2, 774.1, 775.0, 775.9, 776.8, 777.7, 778.6, 779.4, 780.3, 781.2,
      782.1, 783.0, 783.9, 784.8, 785.7, 786.6, 787.5, 788.5, 789.4, 790.4,
      791.4, 792.4, 793.4, 794.4, 795.4, 796.3, 797.3, 798.3, 799.3, 800.3,
      801.3, 802.2, 803.2, 804.2, 805.2, 806.2, 807.2, 808.2, 809.1, 810.1,
      811.1, 812.1, 813.1, 814.1, 815.1, 816.0, 817.0, 818.0, 819.0, 820.0,
      821.0, 822.0, 823.0, 823.9, 824.9, 825.9, 826.9, 827.9, 828.9, 829.8,
      830.8, 831.8, 832.8, 833.8, 834.8, 835.8, 836.8, 837.7, 838.7, 839.7,
      840.7, 841.7, 842.7, 843.7, 844.7, 845.7, 846.7, 847.7, 848.7, 849.7,
      850.7, 851.7, 852.7, 853.7, 854.7, 855.7, 856.7, 857.6, 858.6, 859.6,
      860.6, 861.6, 862.6, 863.6, 864.6, 865.6, 866.6, 867.6, 868.6, 869.6,
      870.6, 871.6, 872.6, 873.6, 874.6, 875.6, 876.6, 877.6, 878.6, 879.6,
      882.5, 883.5, 884.5, 885.5, 886.5, 887.5, 888.5, 889.5, 890.5, 891.5,
      892.5, 893.5, 894.5, 895.5, 896.5, 897.5, 898.5, 899.5, 900.5, 901.5,
      902.5, 903.5, 904.5, 905.5, 906.5, 907.4, 908.4, 909.4,
    ],
  },
  {
    temp: 31.0,
    values: [
      714.6, 715.6, 716.6, 717.6, 718.6, 719.6, 720.5, 721.5, 722.5, 723.5,
      724.5, 725.5, 726.5, 727.5, 728.4, 729.4, 730.4, 731.4, 732.4, 733.4,
      734.4, 735.4, 736.4, 737.4, 738.3, 739.3, 740.3, 741.3, 742.3, 743.3,
      744.3, 745.3, 746.3, 747.2, 748.2, 749.2, 750.2, 751.2, 752.2, 753.2,
      754.2, 755.2, 756.1, 757.1, 758.1, 759.1, 760.1, 761.1, 762.1, 763.1,
      764.1, 765.1, 766.1, 767.0, 768.0, 769.0, 770.0, 770.9, 771.8, 772.7,
      773.6, 774.5, 775.4, 776.3, 777.1, 778.0, 778.9, 779.8, 780.7, 781.6,
      782.5, 783.4, 784.3, 785.1, 786.0, 786.9, 787.8, 788.8, 789.8, 790.8,
      791.8, 792.8, 793.7, 794.7, 795.7, 796.7, 797.7, 798.7, 799.7, 800.6,
      801.6, 802.6, 803.6, 804.6, 805.6, 806.5, 807.5, 808.5, 809.5, 810.5,
      811.5, 812.5, 813.4, 814.4, 815.4, 816.4, 817.4, 818.4, 819.4, 820.3,
      821.3, 822.3, 823.3, 824.3, 825.3, 826.3, 827.2, 828.2, 829.2, 830.2,
      831.2, 832.2, 833.2, 834.1, 835.1, 836.1, 837.1, 838.1, 839.1, 840.1,
      841.1, 842.1, 843.1, 844.1, 845.1, 846.0, 847.0, 848.0, 849.0, 850.0,
      851.0, 852.0, 853.0, 854.0, 855.0, 856.0, 857.0, 858.0, 859.0, 860.0,
      861.0, 862.0, 863.0, 864.0, 865.0, 866.0, 867.0, 867.9, 868.9, 869.9,
      870.9, 871.9, 872.9, 873.9, 874.9, 875.9, 876.9, 877.9, 878.9, 879.9,
      882.9, 883.9, 884.9, 885.9, 886.9, 887.9, 888.9, 889.9, 890.8, 891.8,
      892.8, 893.8, 894.8, 895.8, 896.8, 897.8, 898.8, 899.8, 900.8, 901.8,
      902.8, 903.8, 904.8, 905.8, 906.8, 907.8, 908.8, 909.8,
    ],
  },
  {
    temp: 31.5,
    values: [
      715.1, 716.0, 717.0, 718.0, 719.0, 720.0, 721.0, 722.0, 723.0, 724.0,
      724.9, 725.9, 726.9, 727.9, 728.9, 729.9, 730.9, 731.9, 732.8, 733.8,
      734.8, 735.8, 736.8, 737.8, 738.8, 739.8, 740.8, 741.8, 742.7, 743.7,
      744.7, 745.7, 746.7, 747.7, 748.7, 749.7, 750.7, 751.6, 752.6, 753.6,
      754.6, 755.6, 756.6, 757.6, 758.6, 759.6, 760.5, 761.5, 762.5, 763.5,
      764.5, 765.5, 766.5, 767.5, 768.5, 769.5, 770.4, 771.3, 772.2, 773.1,
      774.0, 774.9, 775.7, 776.6, 777.5, 778.4, 779.3, 780.2, 781.0, 781.9,
      782.8, 783.7, 784.6, 785.5, 786.4, 787.2, 788.2, 789.2, 790.2, 791.2,
      792.1, 793.1, 794.1, 795.1, 796.1, 797.1, 798.0, 799.0, 800.0, 801.0,
      802.0, 803.0, 804.0, 804.9, 805.9, 806.9, 807.9, 808.9, 809.9, 810.8,
      811.8, 812.8, 813.8, 814.8, 815.8, 816.8, 817.7, 818.7, 819.7, 820.7,
      821.7, 822.7, 823.7, 824.6, 825.6, 826.6, 827.6, 828.6, 829.6, 830.5,
      831.5, 832.5, 833.5, 834.5, 835.5, 836.5, 837.4, 838.4, 839.4, 840.4,
      841.4, 842.4, 843.4, 844.4, 845.4, 846.4, 847.4, 848.4, 849.4, 850.4,
      851.4, 852.4, 853.4, 854.4, 855.4, 856.3, 857.3, 858.3, 859.3, 860.3,
      861.3, 862.3, 863.3, 864.3, 865.3, 866.3, 867.3, 868.3, 869.3, 870.3,
      871.3, 872.3, 873.3, 874.3, 875.3, 876.3, 877.3, 878.2, 879.2, 880.2,
      883.2, 884.2, 885.2, 886.2, 887.2, 888.2, 889.2, 890.2, 891.2, 892.2,
      893.2, 894.2, 895.2, 896.2, 897.2, 898.2, 899.2, 900.2, 901.1, 902.1,
      903.1, 904.1, 905.1, 906.1, 907.1, 908.1, 909.1, 910.1,
    ],
  },
  {
    temp: 32.0,
    values: [
      715.5, 716.5, 717.5, 718.5, 719.5, 720.5, 721.4, 722.4, 723.4, 724.4,
      725.4, 726.4, 727.4, 728.4, 729.3, 730.3, 731.3, 732.3, 733.3, 734.3,
      735.3, 736.3, 737.2, 738.2, 739.2, 740.2, 741.2, 742.2, 743.2, 744.2,
      745.2, 746.2, 747.1, 748.1, 749.1, 750.1, 751.1, 752.1, 753.1, 754.1,
      755.1, 756.0, 757.0, 758.0, 759.0, 760.0, 761.0, 762.0, 763.0, 764.0,
      764.9, 765.9, 766.9, 767.9, 768.9, 769.9, 770.8, 771.7, 772.6, 773.5,
      774.3, 775.2, 776.1, 777.0, 777.9, 778.8, 779.6, 780.5, 781.4, 782.3,
      783.2, 784.0, 784.9, 785.8, 786.7, 787.6, 788.6, 789.6, 790.5, 791.5,
      792.5, 793.5, 794.5, 795.5, 796.4, 797.4, 798.4, 799.4, 800.4, 801.4,
      802.3, 803.3, 804.3, 805.3, 806.3, 807.3, 808.2, 809.2, 810.2, 811.2,
      812.2, 813.2, 814.2, 815.1, 816.1, 817.1, 818.1, 819.1, 820.1, 821.0,
      822.0, 823.0, 824.0, 825.0, 826.0, 827.0, 827.9, 828.9, 829.9, 830.9,
      831.9, 832.9, 833.8, 834.8, 835.8, 836.8, 837.8, 838.8, 839.8, 840.8,
      841.8, 842.8, 843.8, 844.7, 845.7, 846.7, 847.7, 848.7, 849.7, 850.7,
      851.7, 852.7, 853.7, 854.7, 855.7, 856.7, 857.7, 858.7, 859.7, 860.7,
      861.7, 862.7, 863.7, 864.6, 865.6, 866.6, 867.6, 868.6, 869.6, 870.6,
      871.6, 872.6, 873.6, 874.6, 875.6, 876.6, 877.6, 878.6, 879.6, 880.6,
      883.6, 884.6, 885.6, 886.5, 887.5, 888.5, 889.5, 890.5, 891.5, 892.5,
      893.5, 894.5, 895.5, 896.5, 897.5, 898.5, 899.5, 900.5, 901.5, 902.5,
      903.5, 904.5, 905.5, 906.5, 907.5, 908.4, 909.4, 910.4,
    ],
  },
  {
    temp: 32.5,
    values: [
      716.0, 716.9, 717.9, 718.9, 719.9, 720.9, 721.9, 722.9, 723.9, 724.9,
      725.8, 726.8, 727.8, 728.8, 729.8, 730.8, 731.8, 732.8, 733.7, 734.7,
      735.7, 736.7, 737.7, 738.7, 739.7, 740.7, 741.6, 742.6, 743.6, 744.6,
      745.6, 746.6, 747.6, 748.6, 749.6, 750.6, 751.5, 752.5, 753.5, 754.5,
      755.5, 756.5, 757.5, 758.5, 759.5, 760.4, 761.4, 762.4, 763.4, 764.4,
      765.4, 766.4, 767.4, 768.3, 769.3, 770.3, 771.2, 772.1, 773.0, 773.8,
      774.7, 775.6, 776.5, 777.4, 778.2, 779.1, 780.0, 780.9, 781.8, 782.6,
      783.5, 784.4, 785.3, 786.1, 787.0, 787.9, 788.9, 789.9, 790.9, 791.9,
      792.9, 793.8, 794.8, 795.8, 796.8, 797.8, 798.8, 799.8, 800.7, 801.7,
      802.7, 803.7, 804.7, 805.7, 806.6, 807.6, 808.6, 809.6, 810.6, 811.5,
      812.5, 813.5, 814.5, 815.5, 816.5, 817.5, 818.4, 819.4, 820.4, 821.4,
      822.4, 823.4, 824.3, 825.3, 826.3, 827.3, 828.3, 829.3, 830.3, 831.2,
      832.2, 833.2, 834.2, 835.2, 836.2, 837.1, 838.1, 839.1, 840.1, 841.1,
      842.1, 843.1, 844.1, 845.1, 846.1, 847.1, 848.1, 849.1, 850.1, 851.1,
      852.1, 853.1, 854.1, 855.0, 856.0, 857.0, 858.0, 859.0, 860.0, 861.0,
      862.0, 863.0, 864.0, 865.0, 866.0, 867.0, 868.0, 869.0, 870.0, 871.0,
      872.0, 873.0, 874.0, 874.9, 875.9, 876.9, 877.9, 878.9, 879.9, 880.9,
      883.9, 884.9, 885.9, 886.9, 887.9, 888.9, 889.9, 890.9, 891.9, 892.9,
      893.9, 894.9, 895.8, 896.8, 897.8, 898.8, 899.8, 900.8, 901.8, 902.8,
      903.8, 904.8, 905.8, 906.8, 907.8, 908.8, 909.8, 910.8,
    ],
  },
  {
    temp: 33.0,
    values: [
      716.4, 717.4, 718.4, 719.4, 720.4, 721.4, 722.3, 723.3, 724.3, 725.3,
      726.3, 727.3, 728.3, 729.3, 730.2, 731.2, 732.2, 733.2, 734.2, 735.2,
      736.2, 737.2, 738.2, 739.1, 740.1, 741.1, 742.1, 743.1, 744.1, 745.1,
      746.0, 747.0, 748.0, 749.0, 750.0, 751.0, 752.0, 753.0, 754.0, 754.9,
      755.9, 756.9, 757.9, 758.9, 759.9, 760.9, 761.9, 762.9, 763.8, 764.8,
      765.8, 766.8, 767.8, 768.8, 769.8, 770.7, 771.6, 772.5, 773.3, 774.2,
      775.1, 776.0, 776.8, 777.7, 778.6, 779.5, 780.3, 781.2, 782.1, 783.0,
      783.9, 784.7, 785.6, 786.5, 787.4, 788.3, 789.3, 790.3, 791.3, 792.2,
      793.2, 794.2, 795.2, 796.2, 797.2, 798.1, 799.1, 800.1, 801.1, 802.1,
      803.1, 804.0, 805.0, 806.0, 807.0, 808.0, 809.0, 809.9, 810.9, 811.9,
      812.9, 813.9, 814.9, 815.8, 816.8, 817.8, 818.8, 819.8, 820.8, 821.7,
      822.7, 823.7, 824.7, 825.7, 826.7, 827.6, 828.6, 829.6, 830.6, 831.6,
      832.6, 833.5, 834.5, 835.5, 836.5, 837.5, 838.5, 839.5, 840.5, 841.5,
      842.5, 843.4, 844.4, 845.4, 846.4, 847.4, 848.4, 849.4, 850.4, 851.4,
      852.4, 853.4, 854.4, 855.4, 856.4, 857.4, 858.4, 859.4, 860.4, 861.4,
      862.3, 863.3, 864.3, 865.3, 866.3, 867.3, 868.3, 869.3, 870.3, 871.3,
      872.3, 873.3, 874.3, 875.3, 876.3, 877.3, 878.3, 879.3, 880.3, 881.3,
      884.2, 885.2, 886.2, 887.2, 888.2, 889.2, 890.2, 891.2, 892.2, 893.2,
      894.2, 895.2, 896.2, 897.2, 898.2, 899.2, 900.2, 901.2, 902.2, 903.2,
      904.1, 905.1, 906.1, 907.1, 908.1, 909.1, 910.1, 911.1,
    ],
  },
  {
    temp: 33.5,
    values: [
      716.9, 717.9, 718.8, 719.8, 720.8, 721.8, 722.8, 723.8, 724.8, 725.8,
      726.7, 727.7, 728.7, 729.7, 730.7, 731.7, 732.7, 733.7, 734.6, 735.6,
      736.6, 737.6, 738.6, 739.6, 740.6, 741.6, 742.5, 743.5, 744.5, 745.5,
      746.5, 747.5, 748.5, 749.5, 750.4, 751.4, 752.4, 753.4, 754.4, 755.4,
      756.4, 757.4, 758.4, 759.3, 760.3, 761.3, 762.3, 763.3, 764.3, 765.3,
      766.3, 767.3, 768.2, 769.2, 770.2, 771.1, 772.0, 772.8, 773.7, 774.6,
      775.5, 776.3, 777.2, 778.1, 778.9, 779.8, 780.7, 781.6, 782.4, 783.3,
      784.2, 785.1, 785.9, 786.8, 787.7, 788.7, 789.7, 790.6, 791.6, 792.6,
      793.6, 794.6, 795.6, 796.5, 797.5, 798.5, 799.5, 800.5, 801.5, 802.4,
      803.4, 804.4, 805.4, 806.4, 807.3, 808.3, 809.3, 810.3, 811.3, 812.3,
      813.2, 814.2, 815.2, 816.2, 817.2, 818.2, 819.1, 820.1, 821.1, 822.1,
      823.1, 824.1, 825.0, 826.0, 827.0, 828.0, 829.0, 830.0, 830.9, 831.9,
      832.9, 833.9, 834.9, 835.9, 836.8, 837.8, 838.8, 839.8, 840.8, 841.8,
      842.8, 843.8, 844.8, 845.8, 846.8, 847.8, 848.8, 849.8, 850.8, 851.8,
      852.7, 853.7, 854.7, 855.7, 856.7, 857.7, 858.7, 859.7, 860.7, 861.7,
      862.7, 863.7, 864.7, 865.7, 866.7, 867.7, 868.7, 869.7, 870.7, 871.6,
      872.6, 873.6, 874.6, 875.6, 876.6, 877.6, 878.6, 879.6, 880.6, 881.6,
      884.6, 885.6, 886.6, 887.6, 888.6, 889.6, 890.6, 891.5, 892.5, 893.5,
      894.5, 895.5, 896.5, 897.5, 898.5, 899.5, 900.5, 901.5, 902.5, 903.5,
      904.5, 905.5, 906.5, 907.5, 908.5, 909.5, 910.5, 911.5,
    ],
  },
  {
    temp: 34.0,
    values: [
      717.3, 718.3, 719.3, 720.3, 721.3, 722.3, 723.2, 724.2, 725.2, 726.2,
      727.2, 728.2, 729.2, 730.1, 731.1, 732.1, 733.1, 734.1, 735.1, 736.1,
      737.1, 738.1, 739.0, 740.0, 741.0, 742.0, 743.0, 744.0, 745.0, 746.0,
      746.9, 747.9, 748.9, 749.9, 750.9, 751.9, 752.9, 753.8, 754.8, 755.8,
      756.8, 757.8, 758.8, 759.8, 760.8, 761.8, 762.7, 763.7, 764.7, 765.7,
      766.7, 767.7, 768.7, 769.7, 770.6, 771.5, 772.3, 773.2, 774.1, 775.0,
      775.8, 776.7, 777.6, 778.4, 779.3, 780.2, 781.0, 781.9, 782.8, 783.7,
      784.5, 785.4, 786.3, 787.1, 788.1, 789.1, 790.0, 791.0, 792.0, 793.0,
      794.0, 794.9, 795.9, 796.9, 797.9, 798.9, 799.8, 800.8, 801.8, 802.8,
      803.8, 804.8, 805.7, 806.7, 807.7, 808.7, 809.7, 810.7, 811.6, 812.6,
      813.6, 814.6, 815.6, 816.5, 817.5, 818.5, 819.5, 820.5, 821.5, 822.4,
      823.4, 824.4, 825.4, 826.4, 827.4, 828.3, 829.3, 830.3, 831.3, 832.3,
      833.3, 834.2, 835.2, 836.2, 837.2, 838.2, 839.2, 840.2, 841.2, 842.1,
      843.1, 844.1, 845.1, 846.1, 847.1, 848.1, 849.1, 850.1, 851.1, 852.1,
      853.1, 854.1, 855.1, 856.1, 857.1, 858.1, 859.1, 860.0, 861.0, 862.0,
      863.0, 864.0, 865.0, 866.0, 867.0, 868.0, 869.0, 870.0, 871.0, 872.0,
      873.0, 874.0, 875.0, 876.0, 877.0, 878.0, 879.0, 879.9, 880.9, 881.9,
      884.9, 885.9, 886.9, 887.9, 888.9, 889.9, 890.9, 891.9, 892.9, 893.9,
      894.9, 895.9, 896.9, 897.9, 898.9, 899.9, 900.8, 901.8, 902.8, 903.8,
      904.8, 905.8, 906.8, 907.8, 908.8, 909.8, 910.8, 911.8,
    ],
  },
  {
    temp: 34.5,
    values: [
      717.8, 718.8, 719.7, 720.7, 721.7, 722.7, 723.7, 724.7, 725.7, 726.7,
      727.6, 728.6, 729.6, 730.6, 731.6, 732.6, 733.6, 734.5, 735.5, 736.5,
      737.5, 738.5, 739.5, 740.5, 741.5, 742.4, 743.4, 744.4, 745.4, 746.4,
      747.4, 748.4, 749.4, 750.3, 751.3, 752.3, 753.3, 754.3, 755.3, 756.3,
      757.3, 758.2, 759.2, 760.2, 761.2, 762.2, 763.2, 764.2, 765.2, 766.1,
      767.1, 768.1, 769.1, 770.1, 771.0, 771.9, 772.7, 773.6, 774.5, 775.3,
      776.2, 777.0, 777.9, 778.8, 779.6, 780.5, 781.4, 782.3, 783.1, 784.0,
      784.9, 785.7, 786.6, 787.5, 788.4, 789.4, 790.4, 791.4, 792.4, 793.3,
      794.3, 795.3, 796.3, 797.3, 798.2, 799.2, 800.2, 801.2, 802.2, 803.1,
      804.1, 805.1, 806.1, 807.1, 808.1, 809.0, 810.0, 811.0, 812.0, 813.0,
      814.0, 814.9, 815.9, 816.9, 817.9, 818.9, 819.8, 820.8, 821.8, 822.8,
      823.8, 824.8, 825.7, 826.7, 827.7, 828.7, 829.7, 830.7, 831.6, 832.6,
      833.6, 834.6, 835.6, 836.5, 837.5, 838.5, 839.5, 840.5, 841.5, 842.5,
      843.5, 844.5, 845.5, 846.5, 847.5, 848.5, 849.5, 850.5, 851.4, 852.4,
      853.4, 854.4, 855.4, 856.4, 857.4, 858.4, 859.4, 860.4, 861.4, 862.4,
      863.4, 864.4, 865.4, 866.4, 867.4, 868.4, 869.3, 870.3, 871.3, 872.3,
      873.3, 874.3, 875.3, 876.3, 877.3, 878.3, 879.3, 880.3, 881.3, 882.3,
      885.3, 886.3, 887.3, 888.2, 889.2, 890.2, 891.2, 892.2, 893.2, 894.2,
      895.2, 896.2, 897.2, 898.2, 899.2, 900.2, 901.2, 902.2, 903.2, 904.2,
      905.2, 906.2, 907.1, 908.1, 909.1, 910.1, 911.1, 912.1,
    ],
  },
  {
    temp: 35.0,
    values: [
      718.2, 719.2, 720.2, 721.2, 722.2, 723.2, 724.1, 725.1, 726.1, 727.1,
      728.1, 729.1, 730.1, 731.1, 732.0, 733.0, 734.0, 735.0, 736.0, 737.0,
      738.0, 738.9, 739.9, 740.9, 741.9, 742.9, 743.9, 744.9, 745.8, 746.8,
      747.8, 748.8, 749.8, 750.8, 751.8, 752.8, 753.8, 754.7, 755.7, 756.7,
      757.7, 758.7, 759.7, 760.7, 761.6, 762.6, 763.6, 764.6, 765.6, 766.6,
      767.6, 768.6, 769.5, 770.5, 771.4, 772.2, 773.1, 774.0, 774.8, 775.7,
      776.5, 777.4, 778.3, 779.1, 780.0, 780.9, 781.7, 782.6, 783.5, 784.3,
      785.2, 786.1, 786.9, 787.8, 788.8, 789.8, 790.8, 791.7, 792.7, 793.7,
      794.7, 795.7, 796.6, 797.6, 798.6, 799.6, 800.6, 801.5, 802.5, 803.5,
      804.5, 805.5, 806.5, 807.4, 808.4, 809.4, 810.4, 811.4, 812.3, 813.3,
      814.3, 815.3, 816.3, 817.2, 818.2, 819.2, 820.2, 821.2, 822.2, 823.1,
      824.1, 825.1, 826.1, 827.1, 828.1, 829.0, 830.0, 831.0, 832.0, 833.0,
      833.9, 834.9, 835.9, 836.9, 837.9, 838.9, 839.9, 840.9, 841.8, 842.8,
      843.8, 844.8, 845.8, 846.8, 847.8, 848.8, 849.8, 850.8, 851.8, 852.8,
      853.8, 854.8, 855.8, 856.8, 857.8, 858.7, 859.7, 860.7, 861.7, 862.7,
      863.7, 864.7, 865.7, 866.7, 867.7, 868.7, 869.7, 870.7, 871.7, 872.7,
      873.7, 874.7, 875.7, 876.7, 877.7, 878.6, 879.6, 880.6, 881.6, 882.6,
      885.6, 886.6, 887.6, 888.6, 889.6, 890.6, 891.6, 892.6, 893.6, 894.6,
      895.6, 896.5, 897.5, 898.5, 899.5, 900.5, 901.5, 902.5, 903.5, 904.5,
      905.5, 906.5, 907.5, 908.5, 909.5, 910.5, 911.5, 912.5,
    ],
  },
  {
    temp: 35.5,
    values: [
      718.7, 719.7, 720.6, 721.6, 722.6, 723.6, 724.6, 725.6, 726.6, 727.6,
      728.5, 729.5, 730.5, 731.5, 732.5, 733.5, 734.5, 735.4, 736.4, 737.4,
      738.4, 739.4, 740.4, 741.4, 742.3, 743.3, 744.3, 745.3, 746.3, 747.3,
      748.3, 749.3, 750.2, 751.2, 752.2, 753.2, 754.2, 755.2, 756.2, 757.2,
      758.1, 759.1, 760.1, 761.1, 762.1, 763.1, 764.1, 765.0, 766.0, 767.0,
      768.0, 769.0, 770.0, 770.9, 771.8, 772.6, 773.5, 774.3, 775.2, 776.0,
      776.9, 777.8, 778.6, 779.5, 780.3, 781.2, 782.1, 782.9, 783.8, 784.7,
      785.5, 786.4, 787.2, 788.2, 789.2, 790.1, 791.1, 792.1, 793.1, 794.1,
      795.0, 796.0, 797.0, 798.0, 799.0, 799.9, 800.9, 801.9, 802.9, 803.9,
      804.8, 805.8, 806.8, 807.8, 808.8, 809.8, 810.7, 811.7, 812.7, 813.7,
      814.7, 815.6, 816.6, 817.6, 818.6, 819.6, 820.5, 821.5, 822.5, 823.5,
      824.5, 825.5, 826.4, 827.4, 828.4, 829.4, 830.4, 831.3, 832.3, 833.3,
      834.3, 835.3, 836.3, 837.2, 838.2, 839.2, 840.2, 841.2, 842.2, 843.2,
      844.2, 845.2, 846.2, 847.2, 848.2, 849.2, 850.1, 851.1, 852.1, 853.1,
      854.1, 855.1, 856.1, 857.1, 858.1, 859.1, 860.1, 861.1, 862.1, 863.1,
      864.1, 865.1, 866.1, 867.0, 868.0, 869.0, 870.0, 871.0, 872.0, 873.0,
      874.0, 875.0, 876.0, 877.0, 878.0, 879.0, 880.0, 881.0, 882.0, 883.0,
      885.9, 886.9, 887.9, 888.9, 889.9, 890.9, 891.9, 892.9, 893.9, 894.9,
      895.9, 896.9, 897.9, 898.9, 899.9, 900.9, 901.9, 902.8, 903.8, 904.8,
      905.8, 906.8, 907.8, 908.8, 909.8, 910.8, 911.8, 912.8,
    ],
  },
  {
    temp: 36.0,
    values: [
      719.1, 720.1, 721.1, 722.1, 723.1, 724.1, 725.0, 726.0, 727.0, 728.0,
      729.0, 730.0, 731.0, 731.9, 732.9, 733.9, 734.9, 735.9, 736.9, 737.9,
      738.9, 739.8, 740.8, 741.8, 742.8, 743.8, 744.8, 745.7, 746.7, 747.7,
      748.7, 749.7, 750.7, 751.7, 752.7, 753.6, 754.6, 755.6, 756.6, 757.6,
      758.6, 759.6, 760.6, 761.5, 762.5, 763.5, 764.5, 765.5, 766.5, 767.5,
      768.4, 769.4, 770.4, 771.3, 772.1, 773.0, 773.8, 774.7, 775.5, 776.4,
      777.3, 778.1, 779.0, 779.8, 780.7, 781.5, 782.4, 783.3, 784.1, 785.0,
      785.8, 786.7, 787.6, 788.5, 789.5, 790.5, 791.5, 792.5, 793.4, 794.4,
      795.4, 796.4, 797.4, 798.3, 799.3, 800.3, 801.3, 802.3, 803.2, 804.2,
      805.2, 806.2, 807.2, 808.1, 809.1, 810.1, 811.1, 812.1, 813.0, 814.0,
      815.0, 816.0, 817.0, 818.0, 818.9, 819.9, 820.9, 821.9, 822.9, 823.8,
      824.8, 825.8, 826.8, 827.8, 828.7, 829.7, 830.7, 831.7, 832.7, 833.7,
      834.6, 835.6, 836.6, 837.6, 838.6, 839.6, 840.5, 841.5, 842.5, 843.5,
      844.5, 845.5, 846.5, 847.5, 848.5, 849.5, 850.5, 851.5, 852.5, 853.5,
      854.5, 855.5, 856.5, 857.5, 858.4, 859.4, 860.4, 861.4, 862.4, 863.4,
      864.4, 865.4, 866.4, 867.4, 868.4, 869.4, 870.4, 871.4, 872.4, 873.4,
      874.4, 875.3, 876.3, 877.3, 878.3, 879.3, 880.3, 881.3, 882.3, 883.3,
      886.3, 887.3, 888.3, 889.3, 890.3, 891.3, 892.3, 893.2, 894.2, 895.2,
      896.2, 897.2, 898.2, 899.2, 900.2, 901.2, 902.2, 903.2, 904.2, 905.2,
      906.2, 907.2, 908.2, 909.2, 910.2, 911.1, 912.1, 913.1,
    ],
  },
  {
    temp: 36.5,
    values: [
      719.6, 720.6, 721.6, 722.5, 723.5, 724.5, 725.5, 726.5, 727.5, 728.4,
      729.4, 730.4, 731.4, 732.4, 733.4, 734.4, 735.3, 736.3, 737.3, 738.3,
      739.3, 740.3, 741.3, 742.3, 743.2, 744.2, 745.2, 746.2, 747.2, 748.2,
      749.1, 750.1, 751.1, 752.1, 753.1, 754.1, 755.1, 756.1, 757.0, 758.0,
      759.0, 760.0, 761.0, 762.0, 763.0, 763.9, 764.9, 765.9, 766.9, 767.9,
      768.9, 769.9, 770.8, 771.6, 772.5, 773.3, 774.2, 775.1, 775.9, 776.8,
      777.6, 778.5, 779.3, 780.2, 781.0, 781.9, 782.7, 783.6, 784.5, 785.3,
      786.2, 787.0, 787.9, 788.9, 789.9, 790.9, 791.8, 792.8, 793.8, 794.8,
      795.8, 796.7, 797.7, 798.7, 799.7, 800.7, 801.6, 802.6, 803.6, 804.6,
      805.6, 806.5, 807.5, 808.5, 809.5, 810.5, 811.4, 812.4, 813.4, 814.4,
      815.4, 816.3, 817.3, 818.3, 819.3, 820.3, 821.2, 822.2, 823.2, 824.2,
      825.2, 826.1, 827.1, 828.1, 829.1, 830.1, 831.1, 832.0, 833.0, 834.0,
      835.0, 836.0, 836.9, 837.9, 838.9, 839.9, 840.9, 841.9, 842.9, 843.9,
      844.9, 845.9, 846.9, 847.9, 848.8, 849.8, 850.8, 851.8, 852.8, 853.8,
      854.8, 855.8, 856.8, 857.8, 858.8, 859.8, 860.8, 861.8, 862.8, 863.8,
      864.7, 865.7, 866.7, 867.7, 868.7, 869.7, 870.7, 871.7, 872.7, 873.7,
      874.7, 875.7, 876.7, 877.7, 878.7, 879.7, 880.7, 881.6, 882.6, 883.6,
      886.6, 887.6, 888.6, 889.6, 890.6, 891.6, 892.6, 893.6, 894.6, 895.6,
      896.6, 897.6, 898.5, 899.5, 900.5, 901.5, 902.5, 903.5, 904.5, 905.5,
      906.5, 907.5, 908.5, 909.5, 910.5, 911.5, 912.5, 913.5,
    ],
  },
  {
    temp: 37.0,
    values: [
      720.0, 721.0, 722.0, 723.0, 724.0, 725.0, 725.9, 726.9, 727.9, 728.9,
      729.9, 730.9, 731.9, 732.8, 733.8, 734.8, 735.8, 736.8, 737.8, 738.8,
      739.7, 740.7, 741.7, 742.7, 743.7, 744.7, 745.7, 746.6, 747.6, 748.6,
      749.6, 750.6, 751.6, 752.5, 753.5, 754.5, 755.5, 756.5, 757.5, 758.5,
      759.5, 760.4, 761.4, 762.4, 763.4, 764.4, 765.4, 766.3, 767.3, 768.3,
      769.3, 770.3, 771.2, 772.0, 772.9, 773.7, 774.6, 775.4, 776.3, 777.1,
      778.0, 778.8, 779.7, 780.5, 781.4, 782.2, 783.1, 783.9, 784.8, 785.6,
      786.5, 787.3, 788.3, 789.3, 790.3, 791.2, 792.2, 793.2, 794.2, 795.1,
      796.1, 797.1, 798.1, 799.1, 800.0, 801.0, 802.0, 803.0, 804.0, 804.9,
      805.9, 806.9, 807.9, 808.9, 809.8, 810.8, 811.8, 812.8, 813.8, 814.7,
      815.7, 816.7, 817.7, 818.7, 819.6, 820.6, 821.6, 822.6, 823.6, 824.5,
      825.5, 826.5, 827.5, 828.5, 829.4, 830.4, 831.4, 832.4, 833.4, 834.3,
      835.3, 836.3, 837.3, 838.3, 839.3, 840.2, 841.2, 842.2, 843.2, 844.2,
      845.2, 846.2, 847.2, 848.2, 849.2, 850.2, 851.2, 852.2, 853.2, 854.2,
      855.2, 856.2, 857.1, 858.1, 859.1, 860.1, 861.1, 862.1, 863.1, 864.1,
      865.1, 866.1, 867.1, 868.1, 869.1, 870.1, 871.1, 872.1, 873.0, 874.0,
      875.0, 876.0, 877.0, 878.0, 879.0, 880.0, 881.0, 882.0, 883.0, 884.0,
      887.0, 888.0, 889.0, 889.9, 890.9, 891.9, 892.9, 893.9, 894.9, 895.9,
      896.9, 897.9, 898.9, 899.9, 900.9, 901.9, 902.9, 903.9, 904.9, 905.9,
      906.8, 907.8, 908.8, 909.8, 910.8, 911.8, 912.8, 913.8,
    ],
  },
  {
    temp: 37.5,
    values: [
      720.5, 721.5, 722.5, 723.4, 724.4, 725.4, 726.4, 727.4, 728.4, 729.4,
      730.3, 731.3, 732.3, 733.3, 734.3, 735.3, 736.2, 737.2, 738.2, 739.2,
      740.2, 741.2, 742.2, 743.1, 744.1, 745.1, 746.1, 747.1, 748.1, 749.1,
      750.0, 751.0, 752.0, 753.0, 754.0, 755.0, 755.9, 756.9, 757.9, 758.9,
      759.9, 760.9, 761.9, 762.9, 763.8, 764.8, 765.8, 766.8, 767.8, 768.8,
      769.7, 770.7, 771.5, 772.4, 773.2, 774.1, 774.9, 775.8, 776.6, 777.5,
      778.3, 779.2, 780.0, 780.9, 781.7, 782.6, 783.4, 784.3, 785.1, 786.0,
      786.8, 787.7, 788.7, 789.6, 790.6, 791.6, 792.6, 793.6, 794.5, 795.5,
      796.5, 797.5, 798.4, 799.4, 800.4, 801.4, 802.4, 803.3, 804.3, 805.3,
      806.3, 807.2, 808.2, 809.2, 810.2, 811.2, 812.1, 813.1, 814.1, 815.1,
      816.1, 817.0, 818.0, 819.0, 820.0, 821.0, 821.9, 822.9, 823.9, 824.9,
      825.9, 826.8, 827.8, 828.8, 829.8, 830.8, 831.7, 832.7, 833.7, 834.7,
      835.7, 836.6, 837.6, 838.6, 839.6, 840.6, 841.6, 842.6, 843.6, 844.6,
      845.6, 846.6, 847.5, 848.5, 849.5, 850.5, 851.5, 852.5, 853.5, 854.5,
      855.5, 856.5, 857.5, 858.5, 859.5, 860.5, 861.5, 862.5, 863.4, 864.4,
      865.4, 866.4, 867.4, 868.4, 869.4, 870.4, 871.4, 872.4, 873.4, 874.4,
      875.4, 876.4, 877.4, 878.3, 879.3, 880.3, 881.3, 882.3, 883.3, 884.3,
      887.3, 888.3, 889.3, 890.3, 891.3, 892.3, 893.3, 894.3, 895.3, 896.3,
      897.2, 898.2, 899.2, 900.2, 901.2, 902.2, 903.2, 904.2, 905.2, 906.2,
      907.2, 908.2, 909.2, 910.2, 911.2, 912.2, 913.2, 914.1,
    ],
  },
  {
    temp: 38.0,
    values: [
      720.9, 721.9, 722.9, 723.9, 724.9, 725.9, 726.8, 727.8, 728.8, 729.8,
      730.8, 731.8, 732.8, 733.7, 734.7, 735.7, 736.7, 737.7, 738.7, 739.6,
      740.6, 741.6, 742.6, 743.6, 744.6, 745.6, 746.5, 747.5, 748.5, 749.5,
      750.5, 751.5, 752.5, 753.4, 754.4, 755.4, 756.4, 757.4, 758.4, 759.3,
      760.3, 761.3, 762.3, 763.3, 764.3, 765.3, 766.2, 767.2, 768.2, 769.2,
      770.2, 771.1, 771.9, 772.7, 773.6, 774.4, 775.3, 776.1, 777.0, 777.8,
      778.7, 779.5, 780.4, 781.2, 782.0, 782.9, 783.7, 784.6, 785.4, 786.3,
      787.1, 788.0, 789.0, 790.0, 791.0, 792.0, 792.9, 793.9, 794.9, 795.9,
      796.8, 797.8, 798.8, 799.8, 800.8, 801.7, 802.7, 803.7, 804.7, 805.6,
      806.6, 807.6, 808.6, 809.6, 810.5, 811.5, 812.5, 813.5, 814.5, 815.4,
      816.4, 817.4, 818.4, 819.4, 820.3, 821.3, 822.3, 823.3, 824.2, 825.2,
      826.2, 827.2, 828.2, 829.1, 830.1, 831.1, 832.1, 833.1, 834.0, 835.0,
      836.0, 837.0, 838.0, 839.0, 840.0, 840.9, 841.9, 842.9, 843.9, 844.9,
      845.9, 846.9, 847.9, 848.9, 849.9, 850.9, 851.9, 852.9, 853.9, 854.9,
      855.8, 856.8, 857.8, 858.8, 859.8, 860.8, 861.8, 862.8, 863.8, 864.8,
      865.8, 866.8, 867.8, 868.8, 869.8, 870.7, 871.7, 872.7, 873.7, 874.7,
      875.7, 876.7, 877.7, 878.7, 879.7, 880.7, 881.7, 882.7, 883.7, 884.7,
      887.6, 888.6, 889.6, 890.6, 891.6, 892.6, 893.6, 894.6, 895.6, 896.6,
      897.6, 898.6, 899.6, 900.6, 901.6, 902.5, 903.5, 904.5, 905.5, 906.5,
      907.5, 908.5, 909.5, 910.5, 911.5, 912.5, 913.5, 914.5,
    ],
  },
  {
    temp: 38.5,
    values: [
      721.4, 722.4, 723.4, 724.3, 725.3, 726.3, 727.3, 728.3, 729.3, 730.2,
      731.2, 732.2, 733.2, 734.2, 735.2, 736.2, 737.1, 738.1, 739.1, 740.1,
      741.1, 742.1, 743.0, 744.0, 745.0, 746.0, 747.0, 748.0, 749.0, 749.9,
      750.9, 751.9, 752.9, 753.9, 754.9, 755.8, 756.8, 757.8, 758.8, 759.8,
      760.8, 761.8, 762.7, 763.7, 764.7, 765.7, 766.7, 767.7, 768.7, 769.6,
      770.6, 771.4, 772.3, 773.1, 773.9, 774.8, 775.6, 776.5, 777.3, 778.2,
      779.0, 779.8, 780.7, 781.5, 782.4, 783.2, 784.1, 784.9, 785.8, 786.6,
      787.4, 788.4, 789.4, 790.4, 791.3, 792.3, 793.3, 794.3, 795.2, 796.2,
      797.2, 798.2, 799.2, 800.1, 801.1, 802.1, 803.1, 804.1, 805.0, 806.0,
      807.0, 808.0, 808.9, 809.9, 810.9, 811.9, 812.9, 813.8, 814.8, 815.8,
      816.8, 817.7, 818.7, 819.7, 820.7, 821.7, 822.6, 823.6, 824.6, 825.6,
      826.6, 827.5, 828.5, 829.5, 830.5, 831.5, 832.4, 833.4, 834.4, 835.4,
      836.4, 837.3, 838.3, 839.3, 840.3, 841.3, 842.3, 843.3, 844.3, 845.3,
      846.3, 847.2, 848.2, 849.2, 850.2, 851.2, 852.2, 853.2, 854.2, 855.2,
      856.2, 857.2, 858.2, 859.2, 860.2, 861.2, 862.1, 863.1, 864.1, 865.1,
      866.1, 867.1, 868.1, 869.1, 870.1, 871.1, 872.1, 873.1, 874.1, 875.1,
      876.1, 877.1, 878.0, 879.0, 880.0, 881.0, 882.0, 883.0, 884.0, 885.0,
      888.0, 889.0, 890.0, 891.0, 892.0, 893.0, 893.9, 894.9, 895.9, 896.9,
      897.9, 898.9, 899.9, 900.9, 901.9, 902.9, 903.9, 904.9, 905.9, 906.9,
      907.9, 908.9, 909.8, 910.8, 911.8, 912.8, 913.8, 914.8,
    ],
  },
  {
    temp: 39.0,
    values: [
      721.8, 722.8, 723.8, 724.8, 725.8, 726.8, 727.7, 728.7, 729.7, 730.7,
      731.7, 732.7, 733.6, 734.6, 735.6, 736.6, 737.6, 738.6, 739.6, 740.5,
      741.5, 742.5, 743.5, 744.5, 745.5, 746.4, 747.4, 748.4, 749.4, 750.4,
      751.4, 752.3, 753.3, 754.3, 755.3, 756.3, 757.3, 758.2, 759.2, 760.2,
      761.2, 762.2, 763.2, 764.2, 765.1, 766.1, 767.1, 768.1, 769.1, 770.1,
      771.0, 771.8, 772.6, 773.5, 774.3, 775.1, 776.0, 776.8, 777.7, 778.5,
      779.3, 780.2, 781.0, 781.9, 782.7, 783.5, 784.4, 785.2, 786.1, 786.9,
      787.8, 788.8, 789.8, 790.7, 791.7, 792.7, 793.7, 794.6, 795.6, 796.6,
      797.6, 798.5, 799.5, 800.5, 801.5, 802.5, 803.4, 804.4, 805.4, 806.4,
      807.3, 808.3, 809.3, 810.3, 811.2, 812.2, 813.2, 814.2, 815.2, 816.1,
      817.1, 818.1, 819.1, 820.1, 821.0, 822.0, 823.0, 824.0, 824.9, 825.9,
      826.9, 827.9, 828.9, 829.8, 830.8, 831.8, 832.8, 833.8, 834.7, 835.7,
      836.7, 837.7, 838.7, 839.7, 840.6, 841.6, 842.6, 843.6, 844.6, 845.6,
      846.6, 847.6, 848.6, 849.6, 850.6, 851.6, 852.6, 853.6, 854.5, 855.5,
      856.5, 857.5, 858.5, 859.5, 860.5, 861.5, 862.5, 863.5, 864.5, 865.5,
      866.5, 867.5, 868.5, 869.4, 870.4, 871.4, 872.4, 873.4, 874.4, 875.4,
      876.4, 877.4, 878.4, 879.4, 880.4, 881.4, 882.4, 883.4, 884.3, 885.3,
      888.3, 889.3, 890.3, 891.3, 892.3, 893.3, 894.3, 895.3, 896.3, 897.3,
      898.3, 899.2, 900.2, 901.2, 902.2, 903.2, 904.2, 905.2, 906.2, 907.2,
      908.2, 909.2, 910.2, 911.2, 912.2, 913.2, 914.2, 915.1,
    ],
  },
  {
    temp: 39.5,
    values: [
      722.3, 723.3, 724.3, 725.2, 726.2, 727.2, 728.2, 729.2, 730.2, 731.1,
      732.1, 733.1, 734.1, 735.1, 736.1, 737.0, 738.0, 739.0, 740.0, 741.0,
      742.0, 742.9, 743.9, 744.9, 745.9, 746.9, 747.9, 748.8, 749.8, 750.8,
      751.8, 752.8, 753.8, 754.8, 755.7, 756.7, 757.7, 758.7, 759.7, 760.7,
      761.6, 762.6, 763.6, 764.6, 765.6, 766.6, 767.6, 768.5, 769.5, 770.5,
      771.3, 772.2, 773.0, 773.8, 774.7, 775.5, 776.3, 777.2, 778.0, 778.8,
      779.7, 780.5, 781.4, 782.2, 783.0, 783.9, 784.7, 785.6, 786.4, 787.2,
      788.2, 789.1, 790.1, 791.1, 792.1, 793.0, 794.0, 795.0, 796.0, 796.9,
      797.9, 798.9, 799.9, 800.9, 801.8, 802.8, 803.8, 804.8, 805.7, 806.7,
      807.7, 808.7, 809.6, 810.6, 811.6, 812.6, 813.6, 814.5, 815.5, 816.5,
      817.5, 818.4, 819.4, 820.4, 821.4, 822.4, 823.3, 824.3, 825.3, 826.3,
      827.2, 828.2, 829.2, 830.2, 831.2, 832.1, 833.1, 834.1, 835.1, 836.1,
      837.0, 838.0, 839.0, 840.0, 841.0, 842.0, 843.0, 844.0, 845.0, 845.9,
      846.9, 847.9, 848.9, 849.9, 850.9, 851.9, 852.9, 853.9, 854.9, 855.9,
      856.9, 857.9, 858.9, 859.8, 860.8, 861.8, 862.8, 863.8, 864.8, 865.8,
      866.8, 867.8, 868.8, 869.8, 870.8, 871.8, 872.8, 873.8, 874.8, 875.8,
      876.7, 877.7, 878.7, 879.7, 880.7, 881.7, 882.7, 883.7, 884.7, 885.7,
      888.7, 889.7, 890.6, 891.6, 892.6, 893.6, 894.6, 895.6, 896.6, 897.6,
      898.6, 899.6, 900.6, 901.6, 902.6, 903.6, 904.6, 905.6, 906.5, 907.5,
      908.5, 909.5, 910.5, 911.5, 912.5, 913.5, 914.5, 915.5,
    ],
  },
  {
    temp: 40.0,
    values: [
      722.7, 723.7, 724.7, 725.7, 726.7, 727.7, 728.6, 729.6, 730.6, 731.6,
      732.6, 733.6, 734.5, 735.5, 736.5, 737.5, 738.5, 739.5, 740.4, 741.4,
      742.4, 743.4, 744.4, 745.4, 746.3, 747.3, 748.3, 749.3, 750.3, 751.3,
      752.2, 753.2, 754.2, 755.2, 756.2, 757.2, 758.1, 759.1, 760.1, 761.1,
      762.1, 763.1, 764.0, 765.0, 766.0, 767.0, 768.0, 769.0, 770.0, 770.9,
      771.7, 772.5, 773.4, 774.2, 775.0, 775.9, 776.7, 777.5, 778.4, 779.2,
      780.0, 780.9, 781.7, 782.5, 783.4, 784.2, 785.0, 785.9, 786.7, 787.6,
      788.5, 789.5, 790.5, 791.5, 792.4, 793.4, 794.4, 795.4, 796.3, 797.3,
      798.3, 799.3, 800.2, 801.2, 802.2, 803.2, 804.1, 805.1, 806.1, 807.1,
      808.1, 809.0, 810.0, 811.0, 812.0, 812.9, 813.9, 814.9, 815.9, 816.8,
      817.8, 818.8, 819.8, 820.8, 821.7, 822.7, 823.7, 824.7, 825.6, 826.6,
      827.6, 828.6, 829.5, 830.5, 831.5, 832.5, 833.5, 834.4, 835.4, 836.4,
      837.4, 838.4, 839.4, 840.3, 841.3, 842.3, 843.3, 844.3, 845.3, 846.3,
      847.3, 848.3, 849.3, 850.3, 851.3, 852.3, 853.2, 854.2, 855.2, 856.2,
      857.2, 858.2, 859.2, 860.2, 861.2, 862.2, 863.2, 864.2, 865.2, 866.2,
      867.1, 868.1, 869.1, 870.1, 871.1, 872.1, 873.1, 874.1, 875.1, 876.1,
      877.1, 878.1, 879.1, 880.1, 881.0, 882.0, 883.0, 884.0, 885.0, 886.0,
      889.0, 890.0, 891.0, 892.0, 893.0, 894.0, 895.0, 895.9, 896.9, 897.9,
      898.9, 899.9, 900.9, 901.9, 902.9, 903.9, 904.9, 905.9, 906.9, 907.9,
      908.9, 909.9, 910.8, 911.8, 912.8, 913.8, 914.8, 915.8,
    ],
  },
  {
    temp: 40.5,
    values: [
      723.2, 724.2, 725.2, 726.1, 727.1, 728.1, 729.1, 730.1, 731.1, 732.0,
      733.0, 734.0, 735.0, 736.0, 737.0, 737.9, 738.9, 739.9, 740.9, 741.9,
      742.9, 743.8, 744.8, 745.8, 746.8, 747.8, 748.7, 749.7, 750.7, 751.7,
      752.7, 753.7, 754.7, 755.6, 756.6, 757.6, 758.6, 759.6, 760.6, 761.5,
      762.5, 763.5, 764.5, 765.5, 766.5, 767.4, 768.4, 769.4, 770.4, 771.2,
      772.0, 772.9, 773.7, 774.5, 775.4, 776.2, 777.0, 777.9, 778.7, 779.5,
      780.4, 781.2, 782.0, 782.9, 783.7, 784.5, 785.4, 786.2, 787.0, 787.9,
      788.9, 789.9, 790.8, 791.8, 792.8, 793.8, 794.7, 795.7, 796.7, 797.7,
      798.6, 799.6, 800.6, 801.6, 802.5, 803.5, 804.5, 805.5, 806.5, 807.4,
      808.4, 809.4, 810.4, 811.3, 812.3, 813.3, 814.3, 815.2, 816.2, 817.2,
      818.2, 819.1, 820.1, 821.1, 822.1, 823.1, 824.0, 825.0, 826.0, 827.0,
      827.9, 828.9, 829.9, 830.9, 831.8, 832.8, 833.8, 834.8, 835.8, 836.7,
      837.7, 838.7, 839.7, 840.7, 841.7, 842.7, 843.7, 844.7, 845.6, 846.6,
      847.6, 848.6, 849.6, 850.6, 851.6, 852.6, 853.6, 854.6, 855.6, 856.6,
      857.6, 858.6, 859.6, 860.5, 861.5, 862.5, 863.5, 864.5, 865.5, 866.5,
      867.5, 868.5, 869.5, 870.5, 871.5, 872.5, 873.5, 874.4, 875.4, 876.4,
      877.4, 878.4, 879.4, 880.4, 881.4, 882.4, 883.4, 884.4, 885.4, 886.4,
      889.3, 890.3, 891.3, 892.3, 893.3, 894.3, 895.3, 896.3, 897.3, 898.3,
      899.3, 900.3, 901.3, 902.3, 903.2, 904.2, 905.2, 906.2, 907.2, 908.2,
      909.2, 910.2, 911.2, 912.2, 913.2, 914.2, 915.2, 916.2,
    ],
  },
  {
    temp: 41.0,
    values: [
      723.6, 724.6, 725.6, 726.6, 727.6, 728.6, 729.5, 730.5, 731.5, 732.5,
      733.5, 734.5, 735.4, 736.4, 737.4, 738.4, 739.4, 740.3, 741.3, 742.3,
      743.3, 744.3, 745.3, 746.2, 747.2, 748.2, 749.2, 750.2, 751.2, 752.1,
      753.1, 754.1, 755.1, 756.1, 757.1, 758.0, 759.0, 760.0, 761.0, 762.0,
      763.0, 763.9, 764.9, 765.9, 766.9, 767.9, 768.9, 769.8, 770.8, 771.6,
      772.4, 773.2, 774.1, 774.9, 775.7, 776.6, 777.4, 778.2, 779.0, 779.9,
      780.7, 781.5, 782.3, 783.2, 784.0, 784.8, 785.7, 786.5, 787.3, 788.3,
      789.3, 790.2, 791.2, 792.2, 793.2, 794.1, 795.1, 796.1, 797.0, 798.0,
      799.0, 800.0, 801.0, 801.9, 802.9, 803.9, 804.9, 805.8, 806.8, 807.8,
      808.8, 809.7, 810.7, 811.7, 812.7, 813.6, 814.6, 815.6, 816.6, 817.5,
      818.5, 819.5, 820.5, 821.5, 822.4, 823.4, 824.4, 825.4, 826.3, 827.3,
      828.3, 829.3, 830.2, 831.2, 832.2, 833.2, 834.1, 835.1, 836.1, 837.1,
      838.1, 839.0, 840.0, 841.0, 842.0, 843.0, 844.0, 845.0, 846.0, 847.0,
      848.0, 849.0, 850.0, 851.0, 852.0, 852.9, 853.9, 854.9, 855.9, 856.9,
      857.9, 858.9, 859.9, 860.9, 861.9, 862.9, 863.9, 864.9, 865.8, 866.8,
      867.8, 868.8, 869.8, 870.8, 871.8, 872.8, 873.8, 874.8, 875.8, 876.8,
      877.8, 878.8, 879.7, 880.7, 881.7, 882.7, 883.7, 884.7, 885.7, 886.7,
      889.7, 890.7, 891.7, 892.7, 893.6, 894.6, 895.6, 896.6, 897.6, 898.6,
      899.6, 900.6, 901.6, 902.6, 903.6, 904.6, 905.6, 906.6, 907.6, 908.6,
      909.5, 910.5, 911.5, 912.5, 913.5, 914.5, 915.5, 916.5,
    ],
  },
  {
    temp: 41.5,
    values: [
      724.1, 725.1, 726.1, 727.0, 728.0, 729.0, 730.0, 731.0, 732.0, 732.9,
      733.9, 734.9, 735.9, 736.9, 737.8, 738.8, 739.8, 740.8, 741.8, 742.8,
      743.7, 744.7, 745.7, 746.7, 747.7, 748.7, 749.6, 750.6, 751.6, 752.6,
      753.6, 754.5, 755.5, 756.5, 757.5, 758.5, 759.5, 760.4, 761.4, 762.4,
      763.4, 764.4, 765.4, 766.4, 767.3, 768.3, 769.3, 770.3, 771.1, 771.9,
      772.8, 773.6, 774.4, 775.2, 776.1, 776.9, 777.7, 778.5, 779.4, 780.2,
      781.0, 781.9, 782.7, 783.5, 784.3, 785.2, 786.0, 786.8, 787.7, 788.6,
      789.6, 790.6, 791.6, 792.5, 793.5, 794.5, 795.5, 796.4, 797.4, 798.4,
      799.4, 800.3, 801.3, 802.3, 803.3, 804.2, 805.2, 806.2, 807.2, 808.1,
      809.1, 810.1, 811.1, 812.0, 813.0, 814.0, 815.0, 815.9, 816.9, 817.9,
      818.9, 819.8, 820.8, 821.8, 822.8, 823.8, 824.7, 825.7, 826.7, 827.7,
      828.6, 829.6, 830.6, 831.6, 832.5, 833.5, 834.5, 835.5, 836.4, 837.4,
      838.4, 839.4, 840.4, 841.4, 842.4, 843.4, 844.4, 845.3, 846.3, 847.3,
      848.3, 849.3, 850.3, 851.3, 852.3, 853.3, 854.3, 855.3, 856.3, 857.3,
      858.3, 859.2, 860.2, 861.2, 862.2, 863.2, 864.2, 865.2, 866.2, 867.2,
      868.2, 869.2, 870.2, 871.2, 872.1, 873.1, 874.1, 875.1, 876.1, 877.1,
      878.1, 879.1, 880.1, 881.1, 882.1, 883.1, 884.1, 885.1, 886.0, 887.0,
      890.0, 891.0, 892.0, 893.0, 894.0, 895.0, 896.0, 897.0, 898.0, 899.0,
      899.9, 900.9, 901.9, 902.9, 903.9, 904.9, 905.9, 906.9, 907.9, 908.9,
      909.9, 910.9, 911.9, 912.9, 913.9, 914.8, 915.8, 916.8,
    ],
  },
  {
    temp: 42.0,
    values: [
      724.6, 725.5, 726.5, 727.5, 728.5, 729.5, 730.4, 731.4, 732.4, 733.4,
      734.4, 735.3, 736.3, 737.3, 738.3, 739.3, 740.2, 741.2, 742.2, 743.2,
      744.2, 745.2, 746.1, 747.1, 748.1, 749.1, 750.1, 751.1, 752.0, 753.0,
      754.0, 755.0, 756.0, 756.9, 757.9, 758.9, 759.9, 760.9, 761.9, 762.9,
      763.8, 764.8, 765.8, 766.8, 767.8, 768.8, 769.7, 770.7, 771.5, 772.3,
      773.1, 773.9, 774.8, 775.6, 776.4, 777.2, 778.1, 778.9, 779.7, 780.5,
      781.4, 782.2, 783.0, 783.8, 784.6, 785.5, 786.3, 787.1, 788.0, 789.0,
      790.0, 791.0, 791.9, 792.9, 793.9, 794.9, 795.8, 796.8, 797.8, 798.7,
      799.7, 800.7, 801.7, 802.6, 803.6, 804.6, 805.6, 806.5, 807.5, 808.5,
      809.5, 810.4, 811.4, 812.4, 813.4, 814.3, 815.3, 816.3, 817.3, 818.2,
      819.2, 820.2, 821.2, 822.1, 823.1, 824.1, 825.1, 826.1, 827.0, 828.0,
      829.0, 829.9, 830.9, 831.9, 832.9, 833.9, 834.8, 835.8, 836.8, 837.8,
      838.8, 839.7, 840.7, 841.7, 842.7, 843.7, 844.7, 845.7, 846.7, 847.7,
      848.7, 849.7, 850.7, 851.6, 852.6, 853.6, 854.6, 855.6, 856.6, 857.6,
      858.6, 859.6, 860.6, 861.6, 862.6, 863.6, 864.5, 865.5, 866.5, 867.5,
      868.5, 869.5, 870.5, 871.5, 872.5, 873.5, 874.5, 875.5, 876.5, 877.4,
      878.4, 879.4, 880.4, 881.4, 882.4, 883.4, 884.4, 885.4, 886.4, 887.4,
      890.4, 891.4, 892.3, 893.3, 894.3, 895.3, 896.3, 897.3, 898.3, 899.3,
      900.3, 901.3, 902.3, 903.3, 904.3, 905.3, 906.3, 907.2, 908.2, 909.2,
      910.2, 911.2, 912.2, 913.2, 914.2, 915.2, 916.2, 917.2,
    ],
  },
  {
    temp: 42.5,
    values: [
      725.0, 726.0, 727.0, 727.9, 728.9, 729.9, 730.9, 731.9, 732.8, 733.8,
      734.8, 735.8, 736.8, 737.8, 738.7, 739.7, 740.7, 741.7, 742.7, 743.6,
      744.6, 745.6, 746.6, 747.6, 748.6, 749.5, 750.5, 751.5, 752.5, 753.5,
      754.5, 755.4, 756.4, 757.4, 758.4, 759.4, 760.3, 761.3, 762.3, 763.3,
      764.3, 765.3, 766.2, 767.2, 768.2, 769.2, 770.2, 771.0, 771.8, 772.7,
      773.5, 774.3, 775.1, 775.9, 776.8, 777.6, 778.4, 779.2, 780.0, 780.9,
      781.7, 782.5, 783.3, 784.2, 785.0, 785.8, 786.6, 787.4, 788.4, 789.4,
      790.3, 791.3, 792.3, 793.3, 794.2, 795.2, 796.2, 797.2, 798.1, 799.1,
      800.1, 801.0, 802.0, 803.0, 804.0, 804.9, 805.9, 806.9, 807.9, 808.8,
      809.8, 810.8, 811.8, 812.7, 813.7, 814.7, 815.7, 816.6, 817.6, 818.6,
      819.6, 820.5, 821.5, 822.5, 823.5, 824.4, 825.4, 826.4, 827.4, 828.4,
      829.3, 830.3, 831.3, 832.2, 833.2, 834.2, 835.2, 836.2, 837.1, 838.1,
      839.1, 840.1, 841.1, 842.1, 843.1, 844.1, 845.1, 846.0, 847.0, 848.0,
      849.0, 850.0, 851.0, 852.0, 853.0, 854.0, 855.0, 856.0, 857.0, 857.9,
      858.9, 859.9, 860.9, 861.9, 862.9, 863.9, 864.9, 865.9, 866.9, 867.9,
      868.9, 869.9, 870.8, 871.8, 872.8, 873.8, 874.8, 875.8, 876.8, 877.8,
      878.8, 879.8, 880.8, 881.8, 882.8, 883.7, 884.7, 885.7, 886.7, 887.7,
      890.7, 891.7, 892.7, 893.7, 894.7, 895.7, 896.7, 897.6, 898.6, 899.6,
      900.6, 901.6, 902.6, 903.6, 904.6, 905.6, 906.6, 907.6, 908.6, 909.6,
      910.6, 911.5, 912.5, 913.5, 914.5, 915.5, 916.5, 917.5,
    ],
  },
  {
    temp: 43.0,
    values: [
      725.4, 726.4, 727.4, 728.4, 729.4, 730.3, 731.3, 732.3, 733.3, 734.3,
      735.3, 736.2, 737.2, 738.2, 739.2, 740.2, 741.1, 742.1, 743.1, 744.1,
      745.1, 746.0, 747.0, 748.0, 749.0, 750.0, 751.0, 751.9, 752.9, 753.9,
      754.9, 755.9, 756.9, 757.8, 758.8, 759.8, 760.8, 761.8, 762.7, 763.7,
      764.7, 765.7, 766.7, 767.7, 768.6, 769.6, 770.6, 771.4, 772.2, 773.0,
      773.8, 774.6, 775.5, 776.3, 777.1, 777.9, 778.7, 779.6, 780.4, 781.2,
      782.0, 782.8, 783.6, 784.5, 785.3, 786.1, 786.9, 787.8, 788.8, 789.7,
      790.7, 791.7, 792.7, 793.6, 794.6, 795.6, 796.5, 797.5, 798.5, 799.5,
      800.4, 801.4, 802.4, 803.4, 804.3, 805.3, 806.3, 807.3, 808.2, 809.2,
      810.2, 811.2, 812.1, 813.1, 814.1, 815.1, 816.0, 817.0, 818.0, 818.9,
      819.9, 820.9, 821.9, 822.8, 823.8, 824.8, 825.8, 826.7, 827.7, 828.7,
      829.7, 830.6, 831.6, 832.6, 833.6, 834.5, 835.5, 836.5, 837.5, 838.5,
      839.4, 840.4, 841.4, 842.4, 843.4, 844.4, 845.4, 846.4, 847.4, 848.4,
      849.4, 850.4, 851.3, 852.3, 853.3, 854.3, 855.3, 856.3, 857.3, 858.3,
      859.3, 860.3, 861.3, 862.3, 863.2, 864.2, 865.2, 866.2, 867.2, 868.2,
      869.2, 870.2, 871.2, 872.2, 873.2, 874.2, 875.2, 876.1, 877.1, 878.1,
      879.1, 880.1, 881.1, 882.1, 883.1, 884.1, 885.1, 886.1, 887.1, 888.1,
      891.0, 892.0, 893.0, 894.0, 895.0, 896.0, 897.0, 898.0, 899.0, 900.0,
      901.0, 902.0, 903.0, 903.9, 904.9, 905.9, 906.9, 907.9, 908.9, 909.9,
      910.9, 911.9, 912.9, 913.9, 914.9, 915.9, 916.8, 917.8,
    ],
  },
  {
    temp: 43.5,
    values: [
      725.9, 726.9, 727.9, 728.8, 729.8, 730.8, 731.8, 732.8, 733.7, 734.7,
      735.7, 736.7, 737.7, 738.6, 739.6, 740.6, 741.6, 742.6, 743.6, 744.5,
      745.5, 746.5, 747.5, 748.5, 749.4, 750.4, 751.4, 752.4, 753.4, 754.3,
      755.3, 756.3, 757.3, 758.3, 759.3, 760.2, 761.2, 762.2, 763.2, 764.2,
      765.1, 766.1, 767.1, 768.1, 769.1, 770.1, 770.9, 771.7, 772.6, 773.4,
      774.2, 775.0, 775.8, 776.6, 777.4, 778.2, 779.1, 779.9, 780.7, 781.5,
      782.3, 783.1, 784.0, 784.8, 785.6, 786.4, 787.2, 788.1, 789.1, 790.1,
      791.1, 792.0, 793.0, 794.0, 795.0, 795.9, 796.9, 797.9, 798.9, 799.8,
      800.8, 801.8, 802.7, 803.7, 804.7, 805.7, 806.6, 807.6, 808.6, 809.6,
      810.5, 811.5, 812.5, 813.5, 814.4, 815.4, 816.4, 817.3, 818.3, 819.3,
      820.3, 821.2, 822.2, 823.2, 824.2, 825.1, 826.1, 827.1, 828.1, 829.0,
      830.0, 831.0, 832.0, 832.9, 833.9, 834.9, 835.9, 836.8, 837.8, 838.8,
      839.8, 840.8, 841.8, 842.8, 843.8, 844.8, 845.7, 846.7, 847.7, 848.7,
      849.7, 850.7, 851.7, 852.7, 853.7, 854.7, 855.7, 856.7, 857.6, 858.6,
      859.6, 860.6, 861.6, 862.6, 863.6, 864.6, 865.6, 866.6, 867.6, 868.6,
      869.5, 870.5, 871.5, 872.5, 873.5, 874.5, 875.5, 876.5, 877.5, 878.5,
      879.5, 880.5, 881.4, 882.4, 883.4, 884.4, 885.4, 886.4, 887.4, 888.4,
      891.4, 892.4, 893.4, 894.4, 895.3, 896.3, 897.3, 898.3, 899.3, 900.3,
      901.3, 902.3, 903.3, 904.3, 905.3, 906.3, 907.3, 908.2, 909.2, 910.2,
      911.2, 912.2, 913.2, 914.2, 915.2, 916.2, 917.2, 918.2,
    ],
  },
  {
    temp: 44.0,
    values: [
      726.3, 727.3, 728.3, 729.3, 730.3, 731.2, 732.2, 733.2, 734.2, 735.2,
      736.1, 737.1, 738.1, 739.1, 740.1, 741.1, 742.0, 743.0, 744.0, 745.0,
      746.0, 746.9, 747.9, 748.9, 749.9, 750.9, 751.8, 752.8, 753.8, 754.8,
      755.8, 756.7, 757.7, 758.7, 759.7, 760.7, 761.7, 762.6, 763.6, 764.6,
      765.6, 766.6, 767.5, 768.5, 769.5, 770.5, 771.3, 772.1, 772.9, 773.7,
      774.5, 775.3, 776.2, 777.0, 777.8, 778.6, 779.4, 780.2, 781.0, 781.8,
      782.7, 783.5, 784.3, 785.1, 785.9, 786.7, 787.5, 788.5, 789.5, 790.5,
      791.4, 792.4, 793.4, 794.3, 795.3, 796.3, 797.3, 798.2, 799.2, 800.2,
      801.2, 802.1, 803.1, 804.1, 805.0, 806.0, 807.0, 808.0, 808.9, 809.9,
      810.9, 811.9, 812.8, 813.8, 814.8, 815.8, 816.7, 817.7, 818.7, 819.6,
      820.6, 821.6, 822.6, 823.5, 824.5, 825.5, 826.5, 827.4, 828.4, 829.4,
      830.4, 831.3, 832.3, 833.3, 834.3, 835.2, 836.2, 837.2, 838.2, 839.1,
      840.1, 841.1, 842.1, 843.1, 844.1, 845.1, 846.1, 847.1, 848.1, 849.1,
      850.0, 851.0, 852.0, 853.0, 854.0, 855.0, 856.0, 857.0, 858.0, 859.0,
      860.0, 861.0, 861.9, 862.9, 863.9, 864.9, 865.9, 866.9, 867.9, 868.9,
      869.9, 870.9, 871.9, 872.9, 873.9, 874.9, 875.8, 876.8, 877.8, 878.8,
      879.8, 880.8, 881.8, 882.8, 883.8, 884.8, 885.8, 886.8, 887.7, 888.7,
      891.7, 892.7, 893.7, 894.7, 895.7, 896.7, 897.7, 898.7, 899.7, 900.6,
      901.6, 902.6, 903.6, 904.6, 905.6, 906.6, 907.6, 908.6, 909.6, 910.6,
      911.6, 912.6, 913.5, 914.5, 915.5, 916.5, 917.5, 918.5,
    ],
  },
  {
    temp: 44.5,
    values: [
      726.8, 727.8, 728.8, 729.7, 730.7, 731.7, 732.7, 733.7, 734.6, 735.6,
      736.6, 737.6, 738.6, 739.5, 740.5, 741.5, 742.5, 743.5, 744.4, 745.4,
      746.4, 747.4, 748.4, 749.3, 750.3, 751.3, 752.3, 753.3, 754.2, 755.2,
      756.2, 757.2, 758.2, 759.2, 760.1, 761.1, 762.1, 763.1, 764.1, 765.0,
      766.0, 767.0, 768.0, 769.0, 769.9, 770.8, 771.6, 772.4, 773.3, 774.1,
      774.9, 775.7, 776.5, 777.3, 778.1, 778.9, 779.7, 780.5, 781.3, 782.2,
      783.0, 783.8, 784.6, 785.4, 786.2, 787.0, 787.9, 788.9, 789.9, 790.8,
      791.8, 792.8, 793.7, 794.7, 795.7, 796.6, 797.6, 798.6, 799.6, 800.5,
      801.5, 802.5, 803.5, 804.4, 805.4, 806.4, 807.3, 808.3, 809.3, 810.3,
      811.2, 812.2, 813.2, 814.2, 815.1, 816.1, 817.1, 818.0, 819.0, 820.0,
      821.0, 821.9, 822.9, 823.9, 824.9, 825.8, 826.8, 827.8, 828.8, 829.7,
      830.7, 831.7, 832.6, 833.6, 834.6, 835.6, 836.5, 837.5, 838.5, 839.5,
      840.5, 841.5, 842.5, 843.5, 844.5, 845.4, 846.4, 847.4, 848.4, 849.4,
      850.4, 851.4, 852.4, 853.4, 854.4, 855.4, 856.3, 857.3, 858.3, 859.3,
      860.3, 861.3, 862.3, 863.3, 864.3, 865.3, 866.3, 867.3, 868.2, 869.2,
      870.2, 871.2, 872.2, 873.2, 874.2, 875.2, 876.2, 877.2, 878.2, 879.2,
      880.1, 881.1, 882.1, 883.1, 884.1, 885.1, 886.1, 887.1, 888.1, 889.1,
      892.1, 893.0, 894.0, 895.0, 896.0, 897.0, 898.0, 899.0, 900.0, 901.0,
      902.0, 903.0, 904.0, 905.0, 905.9, 906.9, 907.9, 908.9, 909.9, 910.9,
      911.9, 912.9, 913.9, 914.9, 915.9, 916.9, 917.9, 918.8,
    ],
  },
  {
    temp: 45.0,
    values: [
      727.2, 728.2, 729.2, 730.2, 731.2, 732.1, 733.1, 734.1, 735.1, 736.1,
      737.0, 738.0, 739.0, 740.0, 741.0, 741.9, 742.9, 743.9, 744.9, 745.9,
      746.8, 747.8, 748.8, 749.8, 750.8, 751.7, 752.7, 753.7, 754.7, 755.7,
      756.6, 757.6, 758.6, 759.6, 760.6, 761.6, 762.5, 763.5, 764.5, 765.5,
      766.5, 767.4, 768.4, 769.4, 770.4, 771.2, 772.0, 772.8, 773.6, 774.4,
      775.2, 776.0, 776.8, 777.6, 778.4, 779.3, 780.1, 780.9, 781.7, 782.5,
      783.3, 784.1, 784.9, 785.7, 786.5, 787.3, 788.3, 789.2, 790.2, 791.2,
      792.1, 793.1, 794.1, 795.1, 796.0, 797.0, 798.0, 799.0, 799.9, 800.9,
      801.9, 802.8, 803.8, 804.8, 805.7, 806.7, 807.7, 808.7, 809.6, 810.6,
      811.6, 812.6, 813.5, 814.5, 815.5, 816.5, 817.4, 818.4, 819.4, 820.3,
      821.3, 822.3, 823.3, 824.2, 825.2, 826.2, 827.2, 828.1, 829.1, 830.1,
      831.1, 832.0, 833.0, 834.0, 834.9, 835.9, 836.9, 837.9, 838.8, 839.8,
      840.8, 841.8, 842.8, 843.8, 844.8, 845.8, 846.8, 847.8, 848.8, 849.7,
      850.7, 851.7, 852.7, 853.7, 854.7, 855.7, 856.7, 857.7, 858.7, 859.7,
      860.7, 861.6, 862.6, 863.6, 864.6, 865.6, 866.6, 867.6, 868.6, 869.6,
      870.6, 871.6, 872.6, 873.6, 874.5, 875.5, 876.5, 877.5, 878.5, 879.5,
      880.5, 881.5, 882.5, 883.5, 884.5, 885.5, 886.4, 887.4, 888.4, 889.4,
      892.4, 893.4, 894.4, 895.4, 896.4, 897.4, 898.3, 899.3, 900.3, 901.3,
      902.3, 903.3, 904.3, 905.3, 906.3, 907.3, 908.3, 909.3, 910.3, 911.2,
      912.2, 913.2, 914.2, 915.2, 916.2, 917.2, 918.2, 919.2,
    ],
  },
  {
    temp: 45.5,
    values: [
      727.7, 728.7, 729.6, 730.6, 731.6, 732.6, 733.6, 734.5, 735.5, 736.5,
      737.5, 738.5, 739.4, 740.4, 741.4, 742.4, 743.4, 744.3, 745.3, 746.3,
      747.3, 748.3, 749.2, 750.2, 751.2, 752.2, 753.2, 754.1, 755.1, 756.1,
      757.1, 758.1, 759.0, 760.0, 761.0, 762.0, 763.0, 764.0, 764.9, 765.9,
      766.9, 767.9, 768.9, 769.8, 770.7, 771.5, 772.3, 773.1, 773.9, 774.8,
      775.6, 776.4, 777.2, 778.0, 778.8, 779.6, 780.4, 781.2, 782.0, 782.8,
      783.6, 784.4, 785.2, 786.0, 786.8, 787.7, 788.6, 789.6, 790.6, 791.5,
      792.5, 793.5, 794.5, 795.4, 796.4, 797.4, 798.3, 799.3, 800.3, 801.3,
      802.2, 803.2, 804.2, 805.1, 806.1, 807.1, 808.0, 809.0, 810.0, 811.0,
      811.9, 812.9, 813.9, 814.9, 815.8, 816.8, 817.8, 818.7, 819.7, 820.7,
      821.7, 822.6, 823.6, 824.6, 825.6, 826.5, 827.5, 828.5, 829.4, 830.4,
      831.4, 832.4, 833.3, 834.3, 835.3, 836.3, 837.2, 838.2, 839.2, 840.2,
      841.2, 842.2, 843.2, 844.2, 845.1, 846.1, 847.1, 848.1, 849.1, 850.1,
      851.1, 852.1, 853.1, 854.1, 855.1, 856.0, 857.0, 858.0, 859.0, 860.0,
      861.0, 862.0, 863.0, 864.0, 865.0, 866.0, 866.9, 867.9, 868.9, 869.9,
      870.9, 871.9, 872.9, 873.9, 874.9, 875.9, 876.9, 877.9, 878.8, 879.8,
      880.8, 881.8, 882.8, 883.8, 884.8, 885.8, 886.8, 887.8, 888.8, 889.8,
      892.7, 893.7, 894.7, 895.7, 896.7, 897.7, 898.7, 899.7, 900.7, 901.7,
      902.6, 903.6, 904.6, 905.6, 906.6, 907.6, 908.6, 909.6, 910.6, 911.6,
      912.6, 913.6, 914.6, 915.6, 916.5, 917.5, 918.5, 919.5,
    ],
  },
  {
    temp: 46.0,
    values: [
      728.1, 729.1, 730.1, 731.1, 732.1, 733.0, 734.0, 735.0, 736.0, 737.0,
      737.9, 738.9, 739.9, 740.9, 741.9, 742.8, 743.8, 744.8, 745.8, 746.7,
      747.7, 748.7, 749.7, 750.7, 751.6, 752.6, 753.6, 754.6, 755.6, 756.5,
      757.5, 758.5, 759.5, 760.5, 761.4, 762.4, 763.4, 764.4, 765.4, 766.4,
      767.3, 768.3, 769.3, 770.3, 771.1, 771.9, 772.7, 773.5, 774.3, 775.1,
      775.9, 776.7, 777.5, 778.3, 779.1, 779.9, 780.7, 781.5, 782.3, 783.1,
      783.9, 784.7, 785.5, 786.3, 787.1, 788.0, 789.0, 790.0, 790.9, 791.9,
      792.9, 793.8, 794.8, 795.8, 796.8, 797.7, 798.7, 799.7, 800.6, 801.6,
      802.6, 803.6, 804.5, 805.5, 806.5, 807.4, 808.4, 809.4, 810.3, 811.3,
      812.3, 813.3, 814.2, 815.2, 816.2, 817.2, 818.1, 819.1, 820.1, 821.0,
      822.0, 823.0, 824.0, 824.9, 825.9, 826.9, 827.8, 828.8, 829.8, 830.8,
      831.7, 832.7, 833.7, 834.6, 835.6, 836.6, 837.6, 838.5, 839.5, 840.5,
      841.5, 842.5, 843.5, 844.5, 845.5, 846.5, 847.5, 848.5, 849.4, 850.4,
      851.4, 852.4, 853.4, 854.4, 855.4, 856.4, 857.4, 858.4, 859.4, 860.4,
      861.3, 862.3, 863.3, 864.3, 865.3, 866.3, 867.3, 868.3, 869.3, 870.3,
      871.3, 872.3, 873.2, 874.2, 875.2, 876.2, 877.2, 878.2, 879.2, 880.2,
      881.2, 882.2, 883.2, 884.1, 885.1, 886.1, 887.1, 888.1, 889.1, 890.1,
      893.1, 894.1, 895.1, 896.0, 897.0, 898.0, 899.0, 900.0, 901.0, 902.0,
      903.0, 904.0, 905.0, 906.0, 907.0, 908.0, 908.9, 909.9, 910.9, 911.9,
      912.9, 913.9, 914.9, 915.9, 916.9, 917.9, 918.9, 919.9,
    ],
  },
  {
    temp: 46.5,
    values: [
      728.6, 729.6, 730.6, 731.5, 732.5, 733.5, 734.5, 735.4, 736.4, 737.4,
      738.4, 739.4, 740.3, 741.3, 742.3, 743.3, 744.3, 745.2, 746.2, 747.2,
      748.2, 749.1, 750.1, 751.1, 752.1, 753.1, 754.0, 755.0, 756.0, 757.0,
      758.0, 758.9, 759.9, 760.9, 761.9, 762.9, 763.8, 764.8, 765.8, 766.8,
      767.8, 768.7, 769.7, 770.6, 771.4, 772.2, 773.0, 773.8, 774.6, 775.4,
      776.2, 777.0, 777.8, 778.6, 779.4, 780.2, 781.0, 781.8, 782.6, 783.4,
      784.2, 785.0, 785.8, 786.6, 787.4, 788.4, 789.4, 790.3, 791.3, 792.3,
      793.2, 794.2, 795.2, 796.1, 797.1, 798.1, 799.1, 800.0, 801.0, 802.0,
      802.9, 803.9, 804.9, 805.8, 806.8, 807.8, 808.8, 809.7, 810.7, 811.7,
      812.6, 813.6, 814.6, 815.6, 816.5, 817.5, 818.5, 819.4, 820.4, 821.4,
      822.4, 823.3, 824.3, 825.3, 826.2, 827.2, 828.2, 829.2, 830.1, 831.1,
      832.1, 833.1, 834.0, 835.0, 836.0, 836.9, 837.9, 838.9, 839.9, 840.9,
      841.9, 842.9, 843.9, 844.8, 845.8, 846.8, 847.8, 848.8, 849.8, 850.8,
      851.8, 852.8, 853.8, 854.7, 855.7, 856.7, 857.7, 858.7, 859.7, 860.7,
      861.7, 862.7, 863.7, 864.7, 865.7, 866.6, 867.6, 868.6, 869.6, 870.6,
      871.6, 872.6, 873.6, 874.6, 875.6, 876.6, 877.5, 878.5, 879.5, 880.5,
      881.5, 882.5, 883.5, 884.5, 885.5, 886.5, 887.5, 888.4, 889.4, 890.4,
      893.4, 894.4, 895.4, 896.4, 897.4, 898.4, 899.4, 900.4, 901.3, 902.3,
      903.3, 904.3, 905.3, 906.3, 907.3, 908.3, 909.3, 910.3, 911.3, 912.3,
      913.3, 914.2, 915.2, 916.2, 917.2, 918.2, 919.2, 920.2,
    ],
  },
  {
    temp: 47.0,
    values: [
      729.0, 730.0, 731.0, 732.0, 733.0, 733.9, 734.9, 735.9, 736.9, 737.8,
      738.8, 739.8, 740.8, 741.8, 742.7, 743.7, 744.7, 745.7, 746.7, 747.6,
      748.6, 749.6, 750.6, 751.5, 752.5, 753.5, 754.5, 755.5, 756.4, 757.4,
      758.4, 759.4, 760.4, 761.3, 762.3, 763.3, 764.3, 765.3, 766.2, 767.2,
      768.2, 769.2, 770.2, 771.0, 771.8, 772.6, 773.4, 774.2, 775.0, 775.8,
      776.6, 777.4, 778.2, 779.0, 779.8, 780.6, 781.3, 782.1, 782.9, 783.7,
      784.5, 785.3, 786.1, 786.9, 787.8, 788.7, 789.7, 790.7, 791.7, 792.6,
      793.6, 794.6, 795.5, 796.5, 797.5, 798.4, 799.4, 800.4, 801.4, 802.3,
      803.3, 804.3, 805.2, 806.2, 807.2, 808.1, 809.1, 810.1, 811.1, 812.0,
      813.0, 814.0, 814.9, 815.9, 816.9, 817.9, 818.8, 819.8, 820.8, 821.7,
      822.7, 823.7, 824.6, 825.6, 826.6, 827.6, 828.5, 829.5, 830.5, 831.4,
      832.4, 833.4, 834.4, 835.3, 836.3, 837.3, 838.2, 839.2, 840.2, 841.2,
      842.2, 843.2, 844.2, 845.2, 846.2, 847.2, 848.2, 849.1, 850.1, 851.1,
      852.1, 853.1, 854.1, 855.1, 856.1, 857.1, 858.1, 859.1, 860.1, 861.0,
      862.0, 863.0, 864.0, 865.0, 866.0, 867.0, 868.0, 869.0, 870.0, 871.0,
      871.9, 872.9, 873.9, 874.9, 875.9, 876.9, 877.9, 878.9, 879.9, 880.9,
      881.9, 882.8, 883.8, 884.8, 885.8, 886.8, 887.8, 888.8, 889.8, 890.8,
      893.8, 894.7, 895.7, 896.7, 897.7, 898.7, 899.7, 900.7, 901.7, 902.7,
      903.7, 904.7, 905.6, 906.6, 907.6, 908.6, 909.6, 910.6, 911.6, 912.6,
      913.6, 914.6, 915.6, 916.6, 917.6, 918.5, 919.5, 920.5,
    ],
  },
  {
    temp: 47.5,
    values: [
      729.5, 730.5, 731.4, 732.4, 733.4, 734.4, 735.4, 736.3, 737.3, 738.3,
      739.3, 740.2, 741.2, 742.2, 743.2, 744.2, 745.1, 746.1, 747.1, 748.1,
      749.1, 750.0, 751.0, 752.0, 753.0, 753.9, 754.9, 755.9, 756.9, 757.9,
      758.8, 759.8, 760.8, 761.8, 762.8, 763.7, 764.7, 765.7, 766.7, 767.7,
      768.6, 769.6, 770.6, 771.3, 772.1, 772.9, 773.7, 774.5, 775.3, 776.1,
      776.9, 777.7, 778.5, 779.3, 780.1, 780.9, 781.7, 782.5, 783.3, 784.1,
      784.9, 785.6, 786.4, 787.2, 788.1, 789.1, 790.1, 791.0, 792.0, 793.0,
      794.0, 794.9, 795.9, 796.9, 797.8, 798.8, 799.8, 800.7, 801.7, 802.7,
      803.6, 804.6, 805.6, 806.6, 807.5, 808.5, 809.5, 810.4, 811.4, 812.4,
      813.3, 814.3, 815.3, 816.3, 817.2, 818.2, 819.2, 820.1, 821.1, 822.1,
      823.1, 824.0, 825.0, 826.0, 826.9, 827.9, 828.9, 829.9, 830.8, 831.8,
      832.8, 833.7, 834.7, 835.7, 836.6, 837.6, 838.6, 839.6, 840.6, 841.6,
      842.6, 843.6, 844.5, 845.5, 846.5, 847.5, 848.5, 849.5, 850.5, 851.5,
      852.5, 853.5, 854.4, 855.4, 856.4, 857.4, 858.4, 859.4, 860.4, 861.4,
      862.4, 863.4, 864.4, 865.3, 866.3, 867.3, 868.3, 869.3, 870.3, 871.3,
      872.3, 873.3, 874.3, 875.3, 876.2, 877.2, 878.2, 879.2, 880.2, 881.2,
      882.2, 883.2, 884.2, 885.2, 886.2, 887.2, 888.1, 889.1, 890.1, 891.1,
      894.1, 895.1, 896.1, 897.1, 898.1, 899.1, 900.0, 901.0, 902.0, 903.0,
      904.0, 905.0, 906.0, 907.0, 908.0, 909.0, 910.0, 910.9, 911.9, 912.9,
      913.9, 914.9, 915.9, 916.9, 917.9, 918.9, 919.9, 920.9,
    ],
  },
  {
    temp: 48.0,
    values: [
      729.9, 730.9, 731.9, 732.9, 733.8, 734.8, 735.8, 736.8, 737.8, 738.7,
      739.7, 740.7, 741.7, 742.6, 743.6, 744.6, 745.6, 746.6, 747.5, 748.5,
      749.5, 750.5, 751.5, 752.4, 753.4, 754.4, 755.4, 756.3, 757.3, 758.3,
      759.3, 760.3, 761.2, 762.2, 763.2, 764.2, 765.2, 766.1, 767.1, 768.1,
      769.1, 770.1, 770.9, 771.7, 772.5, 773.3, 774.1, 774.9, 775.7, 776.4,
      777.2, 778.0, 778.8, 779.6, 780.4, 781.2, 782.0, 782.8, 783.6, 784.4,
      785.2, 786.0, 786.7, 787.5, 788.5, 789.5, 790.4, 791.4, 792.4, 793.3,
      794.3, 795.3, 796.3, 797.2, 798.2, 799.2, 800.1, 801.1, 802.1, 803.0,
      804.0, 805.0, 805.9, 806.9, 807.9, 808.9, 809.8, 810.8, 811.8, 812.7,
      813.7, 814.7, 815.6, 816.6, 817.6, 818.5, 819.5, 820.5, 821.5, 822.4,
      823.4, 824.4, 825.3, 826.3, 827.3, 828.3, 829.2, 830.2, 831.2, 832.1,
      833.1, 834.1, 835.1, 836.0, 837.0, 838.0, 838.9, 839.9, 840.9, 841.9,
      842.9, 843.9, 844.9, 845.9, 846.9, 847.9, 848.9, 849.8, 850.8, 851.8,
      852.8, 853.8, 854.8, 855.8, 856.8, 857.8, 858.8, 859.7, 860.7, 861.7,
      862.7, 863.7, 864.7, 865.7, 866.7, 867.7, 868.7, 869.7, 870.6, 871.6,
      872.6, 873.6, 874.6, 875.6, 876.6, 877.6, 878.6, 879.6, 880.6, 881.5,
      882.5, 883.5, 884.5, 885.5, 886.5, 887.5, 888.5, 889.5, 890.5, 891.5,
      894.4, 895.4, 896.4, 897.4, 898.4, 899.4, 900.4, 901.4, 902.4, 903.3,
      904.3, 905.3, 906.3, 907.3, 908.3, 909.3, 910.3, 911.3, 912.3, 913.3,
      914.3, 915.2, 916.2, 917.2, 918.2, 919.2, 920.2, 921.2,
    ],
  },
  {
    temp: 48.5,
    values: [
      730.4, 731.4, 732.3, 733.3, 734.3, 735.3, 736.2, 737.2, 738.2, 739.2,
      740.2, 741.1, 742.1, 743.1, 744.1, 745.0, 746.0, 747.0, 748.0, 749.0,
      749.9, 750.9, 751.9, 752.9, 753.9, 754.8, 755.8, 756.8, 757.8, 758.7,
      759.7, 760.7, 761.7, 762.7, 763.6, 764.6, 765.6, 766.6, 767.5, 768.5,
      769.5, 770.5, 771.3, 772.0, 772.8, 773.6, 774.4, 775.2, 776.0, 776.8,
      777.6, 778.3, 779.1, 779.9, 780.7, 781.5, 782.3, 783.1, 783.9, 784.7,
      785.5, 786.3, 787.0, 787.9, 788.9, 789.8, 790.8, 791.8, 792.7, 793.7,
      794.7, 795.6, 796.6, 797.6, 798.5, 799.5, 800.5, 801.4, 802.4, 803.4,
      804.4, 805.3, 806.3, 807.3, 808.2, 809.2, 810.2, 811.1, 812.1, 813.1,
      814.0, 815.0, 816.0, 817.0, 817.9, 818.9, 819.9, 820.8, 821.8, 822.8,
      823.7, 824.7, 825.7, 826.7, 827.6, 828.6, 829.6, 830.5, 831.5, 832.5,
      833.5, 834.4, 835.4, 836.4, 837.3, 838.3, 839.3, 840.3, 841.3, 842.3,
      843.3, 844.2, 845.2, 846.2, 847.2, 848.2, 849.2, 850.2, 851.2, 852.2,
      853.2, 854.1, 855.1, 856.1, 857.1, 858.1, 859.1, 860.1, 861.1, 862.1,
      863.1, 864.0, 865.0, 866.0, 867.0, 868.0, 869.0, 870.0, 871.0, 872.0,
      873.0, 874.0, 874.9, 875.9, 876.9, 877.9, 878.9, 879.9, 880.9, 881.9,
      882.9, 883.9, 884.9, 885.9, 886.8, 887.8, 888.8, 889.8, 890.8, 891.8,
      894.8, 895.8, 896.8, 897.7, 898.7, 899.7, 900.7, 901.7, 902.7, 903.7,
      904.7, 905.7, 906.7, 907.7, 908.6, 909.6, 910.6, 911.6, 912.6, 913.6,
      914.6, 915.6, 916.6, 917.6, 918.6, 919.6, 920.5, 921.5,
    ],
  },
  {
    temp: 49.0,
    values: [
      730.8, 731.8, 732.8, 733.8, 734.7, 735.7, 736.7, 737.7, 738.6, 739.6,
      740.6, 741.6, 742.6, 743.5, 744.5, 745.5, 746.5, 747.4, 748.4, 749.4,
      750.4, 751.4, 752.3, 753.3, 754.3, 755.3, 756.3, 757.2, 758.2, 759.2,
      760.2, 761.1, 762.1, 763.1, 764.1, 765.1, 766.0, 767.0, 768.0, 769.0,
      769.9, 770.8, 771.6, 772.4, 773.2, 774.0, 774.7, 775.5, 776.3, 777.1,
      777.9, 778.7, 779.5, 780.2, 781.0, 781.8, 782.6, 783.4, 784.2, 785.0,
      785.8, 786.6, 787.3, 788.3, 789.2, 790.2, 791.2, 792.1, 793.1, 794.1,
      795.0, 796.0, 797.0, 797.9, 798.9, 799.9, 800.8, 801.8, 802.8, 803.7,
      804.7, 805.7, 806.6, 807.6, 808.6, 809.6, 810.5, 811.5, 812.5, 813.4,
      814.4, 815.4, 816.3, 817.3, 818.3, 819.2, 820.2, 821.2, 822.2, 823.1,
      824.1, 825.1, 826.0, 827.0, 828.0, 828.9, 829.9, 830.9, 831.9, 832.8,
      833.8, 834.8, 835.7, 836.7, 837.7, 838.6, 839.6, 840.6, 841.6, 842.6,
      843.6, 844.6, 845.6, 846.6, 847.6, 848.6, 849.5, 850.5, 851.5, 852.5,
      853.5, 854.5, 855.5, 856.5, 857.5, 858.5, 859.4, 860.4, 861.4, 862.4,
      863.4, 864.4, 865.4, 866.4, 867.4, 868.4, 869.3, 870.3, 871.3, 872.3,
      873.3, 874.3, 875.3, 876.3, 877.3, 878.3, 879.3, 880.2, 881.2, 882.2,
      883.2, 884.2, 885.2, 886.2, 887.2, 888.2, 889.2, 890.2, 891.1, 892.1,
      895.1, 896.1, 897.1, 898.1, 899.1, 900.1, 901.1, 902.0, 903.0, 904.0,
      905.0, 906.0, 907.0, 908.0, 909.0, 910.0, 911.0, 912.0, 912.9, 913.9,
      914.9, 915.9, 916.9, 917.9, 918.9, 919.9, 920.9, 921.9,
    ],
  },
  {
    temp: 49.5,
    values: [
      731.3, 732.3, 733.2, 734.2, 735.2, 736.2, 737.1, 738.1, 739.1, 740.1,
      741.0, 742.0, 743.0, 744.0, 745.0, 745.9, 746.9, 747.9, 748.9, 749.8,
      750.8, 751.8, 752.8, 753.8, 754.7, 755.7, 756.7, 757.7, 758.6, 759.6,
      760.6, 761.6, 762.6, 763.5, 764.5, 765.5, 766.5, 767.5, 768.4, 769.4,
      770.4, 771.2, 771.9, 772.7, 773.5, 774.3, 775.1, 775.9, 776.6, 777.4,
      778.2, 779.0, 779.8, 780.6, 781.3, 782.1, 782.9, 783.7, 784.5, 785.3,
      786.1, 786.9, 787.7, 788.6, 789.6, 790.6, 791.5, 792.5, 793.5, 794.4,
      795.4, 796.4, 797.3, 798.3, 799.3, 800.2, 801.2, 802.2, 803.1, 804.1,
      805.1, 806.0, 807.0, 808.0, 808.9, 809.9, 810.9, 811.8, 812.8, 813.8,
      814.7, 815.7, 816.7, 817.7, 818.6, 819.6, 820.6, 821.5, 822.5, 823.5,
      824.4, 825.4, 826.4, 827.3, 828.3, 829.3, 830.3, 831.2, 832.2, 833.2,
      834.1, 835.1, 836.1, 837.0, 838.0, 839.0, 840.0, 841.0, 842.0, 843.0,
      843.9, 844.9, 845.9, 846.9, 847.9, 848.9, 849.9, 850.9, 851.9, 852.9,
      853.8, 854.8, 855.8, 856.8, 857.8, 858.8, 859.8, 860.8, 861.8, 862.8,
      863.7, 864.7, 865.7, 866.7, 867.7, 868.7, 869.7, 870.7, 871.7, 872.7,
      873.7, 874.6, 875.6, 876.6, 877.6, 878.6, 879.6, 880.6, 881.6, 882.6,
      883.6, 884.6, 885.5, 886.5, 887.5, 888.5, 889.5, 890.5, 891.5, 892.5,
      895.4, 896.4, 897.4, 898.4, 899.4, 900.4, 901.4, 902.4, 903.4, 904.4,
      905.4, 906.3, 907.3, 908.3, 909.3, 910.3, 911.3, 912.3, 913.3, 914.3,
      915.3, 916.3, 917.3, 918.2, 919.2, 920.2, 921.2, 922.2,
    ],
  },
  {
    temp: 50.0,
    values: [
      731.7, 732.7, 733.7, 734.7, 735.6, 736.6, 737.6, 738.6, 739.5, 740.5,
      741.5, 742.5, 743.4, 744.4, 745.4, 746.4, 747.4, 748.3, 749.3, 750.3,
      751.3, 752.2, 753.2, 754.2, 755.2, 756.1, 757.1, 758.1, 759.1, 760.1,
      761.0, 762.0, 763.0, 764.0, 764.9, 765.9, 766.9, 767.9, 768.9, 769.8,
      770.7, 771.5, 772.3, 773.1, 773.8, 774.6, 775.4, 776.2, 777.0, 777.8,
      778.5, 779.3, 780.1, 780.9, 781.7, 782.4, 783.2, 784.0, 784.8, 785.6,
      786.4, 787.1, 788.0, 789.0, 790.0, 790.9, 791.9, 792.8, 793.8, 794.8,
      795.7, 796.7, 797.7, 798.7, 799.6, 800.6, 801.6, 802.5, 803.5, 804.5,
      805.4, 806.4, 807.4, 808.3, 809.3, 810.3, 811.2, 812.2, 813.2, 814.1,
      815.1, 816.1, 817.0, 818.0, 819.0, 819.9, 820.9, 821.9, 822.8, 823.8,
      824.8, 825.8, 826.7, 827.7, 828.7, 829.6, 830.6, 831.6, 832.5, 833.5,
      834.5, 835.4, 836.4, 837.4, 838.4, 839.3, 840.3, 841.3, 842.3, 843.3,
      844.3, 845.3, 846.3, 847.3, 848.3, 849.2, 850.2, 851.2, 852.2, 853.2,
      854.2, 855.2, 856.2, 857.2, 858.2, 859.1, 860.1, 861.1, 862.1, 863.1,
      864.1, 865.1, 866.1, 867.1, 868.1, 869.0, 870.0, 871.0, 872.0, 873.0,
      874.0, 875.0, 876.0, 877.0, 878.0, 878.9, 879.9, 880.9, 881.9, 882.9,
      883.9, 884.9, 885.9, 886.9, 887.9, 888.8, 889.8, 890.8, 891.8, 892.8,
      895.8, 896.8, 897.8, 898.8, 899.7, 900.7, 901.7, 902.7, 903.7, 904.7,
      905.7, 906.7, 907.7, 908.7, 909.7, 910.6, 911.6, 912.6, 913.6, 914.6,
      915.6, 916.6, 917.6, 918.6, 919.6, 920.6, 921.6, 922.5,
    ],
  },
];

const AddDensityForm = ({ fetchDensitys, density, setDensity, isLoggedIn }) => {
  const navigate = useNavigate(); // Call useNavigate to get navigate function
  const { logout } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [density_Calculate, setDensity_density_Calculate] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDensity({ ...density, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    const pressure = parseInt(density.density, 0);
    const temp = parseInt(density.temperature, 0);
    let density_result = 0;

    const tempData = densityData.find((data) => data.temp === parseFloat(temp));
    if (tempData) {
      const pressureIndex = Math.floor((parseFloat(pressure) - 700) / 1.0); // Assuming pressure steps are 1 unit
      if (pressureIndex >= 0 && pressureIndex < tempData.values.length) {
        density_result = tempData.values[pressureIndex];
        setDensity_density_Calculate(density_result);
      } else {
        alert("Pressure out of range");
        setLoading(false);
        return false;
      }
    } else {
      alert("Temperature not found");
      setLoading(false);
      return false;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/densitysave`,
        { ...density, density_result },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      //console.log('Density added:', response.data);
      setSuccess("Item added successfully!");
      //fetchDensitys();
      window.location.reload();
      setLoading(false);

      // Clear form after successful submission
      setDensity({ density: "", temperature: "" });
    } catch (error) {
      //setError('Failed to add Density: ' + (error.response?.data?.message || error.message));
      //setLoading(false);
      logout();
      navigate("/"); // Use navigate to redirect
    }
  };

  const handleCalculate = (e) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const pressure = parseInt(density.density, 0);
    const temp = parseInt(density.temperature, 0);
    let density_result = 0;

    const tempData = densityData.find((data) => data.temp === parseFloat(temp));
    if (tempData) {
      const pressureIndex = Math.floor((parseFloat(pressure) - 700) / 1.0); // Assuming pressure steps are 1 unit
      if (pressureIndex >= 0 && pressureIndex < tempData.values.length) {
        density_result = tempData.values[pressureIndex];
        setDensity_density_Calculate(density_result);
        setLoading(false);
      } else {
        alert("Pressure out of range");
        setLoading(false);
        return false;
      }
    } else {
      alert("Temperature not found");
      setLoading(false);
      return false;
    }
  };

  return (
    <div className="form">
      <form onSubmit={isLoggedIn ? handleSubmit : undefined}>
        <span>Density Calculator</span>
        <div className="row">
          <label class="col-12 dip-calculator-label"> DENSITY</label>
          <div className="col-12">
            <input
              type="number"
              name="density"
              className="form-control"
              placeholder="Density"
              value={density.density}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="row">
          <label class="col-12 dip-calculator-label"> TEMPERATURE</label>
          <div className="col-12">
            <input
              type="number"
              name="temperature"
              className="form-control"
              placeholder="Temperature"
              value={density.temperature}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <button
          className="btn btn-primary bt-form"
          type="button"
          disabled={loading}
          onClick={handleCalculate}
        >
          {loading ? "Calculating..." : "Calculate"}
        </button>

        {isLoggedIn ? (
          <button
            className="btn btn-primary bt-form"
            type="submit"
            disabled={loading}
          >
            {loading ? "Calculating & Save..." : "Calculate & Save"}
          </button>
        ) : (
          ""
        )}

        {density_Calculate && <p>Density: {density_Calculate}</p>}

        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && <p style={{ color: "green" }}>{success}</p>}
      </form>
    </div>
  );
};

export default AddDensityForm;
